import React from 'react';
import { Button, Modal, Row, Typography } from 'antd';
import { TitleText } from './NewAppModal';
import { TitleTextPassword } from './TitleTextPassword';

export function InfosumCredentialsModal({
    display,
    onOk,
    s3URL,
    s3AccessKey,
    secretKey,
    region
}) {
    return (
        <Modal
            visible={display}
            style={{ top: 20, paddingBottom: 20, }}
            centered
            width={776}
            footer={null}
            closable={false}
            destroyOnClose
            bodyStyle={{ minHeight: 508, width: 776, backgroundColor: '#353738', paddingLeft: 60, paddingTop: 40, paddingRight: 0, overflowX: 'hidden' }}
        >
            <img className='delete-x-button-connection' alt={'Close'} onClick={onOk} src={require('../assets/modal-close.png')} />
            <div>
                <Typography.Title style={{ display: 'inline-block', fontFamily: 'Montserrat', color: '#fff', fontWeight: 700, fontSize: 20, paddingBottom: 40, paddingTop: 8 }}>Credentials</Typography.Title>
            </div>
            <div>
                <TitleText
                    placeholder={" "}
                    label={"S3 bucket URL"}
                    marginLeft={173}
                    disabled
                    supportCopy
                    value={s3URL}
                />
                <div style={{ marginTop: 24 }} />
                <TitleText
                    placeholder={" "}
                    label={"Access Key"}
                    marginLeft={203}
                    disabled
                    supportCopy
                    value={s3AccessKey}
                />
                <div style={{ marginTop: 24 }} />
                <TitleTextPassword
                    placeholder={" "}
                    label={"Secret Key"}                    
                    marginLeft={206}
                    disabled
                    supportCopy
                    value={secretKey}
                />
                <div style={{ marginTop: 24 }} />
                <TitleText
                    placeholder={" "}
                    label={"Region"}
                    marginLeft={233}
                    disabled
                    supportCopy
                    value={region}
                />
                <div style = {{marginTop: 24}}/>
                <TitleText 
                    placeholder={""}
                    label = {"Session Name"}
                    marginLeft = {176}
                    disabled
                    supportCopy
                    value = {'infosumlockr'}
                />
            </div>
            <Row style={{ marginTop: 50 }}>
                <Button
                    type={"primary"}
                    style={{ width: 200 }}
                    loading={false}
                    onClick={onOk}
                    className='sign-in'
                >{"Confirm"}</Button>
            </Row>
        </Modal>
    )
}