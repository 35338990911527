/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import SideDrawer from "../../Components/SideDrawer";
import {
    Col,
    Row,
    Typography,
    Switch,
    ConfigProvider,
    DatePicker,
    Tooltip,
    Checkbox,
    Button,
} from "antd";
import locale from "antd/es/locale/en_GB";
import moment from "moment";
import { AiOutlineDown } from "react-icons/ai";
import {
    CartesianGrid,
    Label,
    Line,
    LineChart,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from "recharts";
import {
    checkFirstIDEnabledForPublisher,
    getDeletedUIDCredentialsAPI,
    getIdentityProviderSetting,
    getUIDAnalytics,
    getUIDConnectedEmailCount,
    getUIDConsentedUsers,
    getUIDCredentialsAPI,
    getUIDUserCount,
    getUIDUserGraphData,
    getUserAuthenticationDashboardData,
    removeUIDIntegration,
    updateFirstIdForPublisher,
    upsertIdentityProviderSetting,
    upsertUIDCredentialsAPI,
} from "../../Utils/ApiActions";
import { identitProvidersType } from "../../Utils/Helper";
import { UIDNoCredentialModal } from "../../Components/UIDNoCredentialModal";
import { UIDCredentialsModal } from "../../Components/UIDCredentialsModal";
import { IdentityProviderlockrNMailStrip } from "./components/IdentityProviderlockrMailStrip";
import { IdentityProviderStatsAuthStrip } from "./components/IdentityProviderStatsAuthStrip";
import { IdentityProviderGraphStrip } from "./components/IdentityProviderlockrGraphStrip";

export function FirstIdToken() {

    const [displayLoading, setDisplayLoading] = useState(false);
    const [rampIDCredentials, setRampIDCredentials] = useState(undefined);

    const [displayCredentialsModal, setDisplayCredentialsModal] = useState(false);
    const [firstIdEnabled, setFirstIdEnabled] = useState(false)


    useEffect(() => {
        window.scrollTo({
            left: 0,
            top: 0,
        });
        checkFirstIDEnabled()
    }, []);

    const checkFirstIDEnabled = async () => {
        setDisplayLoading(true);
        const response = await checkFirstIDEnabledForPublisher();
        if (response.success) {
            setFirstIdEnabled(response.data)
        }
        setDisplayLoading(false)
    }

    const updateFirstId = async (value = false) => {
        updateFirstIdForPublisher(value)
        setFirstIdEnabled(value)
    }

    function displaySwitch() {
        return (
            <div>
                <Switch
                    checked={firstIdEnabled}
                    onChange={(checked) => {
                        !checked ? updateFirstId(false) : updateFirstId(true)
                    }}
                />
                {/* <Typography.Text
                    onClick={() => {
                        setDisplayCredentialsModal(true);
                    }}
                    className="infosum-view-credentials-text"
                >
                    View Credentials
                </Typography.Text> */}
            </div>
        );
    }



    function displayConnectModal() {
        if (
            rampIDCredentials === undefined &&
            !displayCredentialsModal &&
            !displayLoading
        ) {
            return (
                <UIDNoCredentialModal
                    integrationType={identitProvidersType.FIRSTID}
                    onOk={() => {
                        setDisplayCredentialsModal(true);
                    }}
                    display={true}
                />
            );
        }
        return <></>;
    }
    return (
        <SideDrawer>
            <Row
                justify="space-between"
                align="middle"
                className="apiandservices-newapp-btn"
            >
                <Col sm={12} xs={24}>
                    <Typography.Title className="apiandservices-heading">
                        First Id
                    </Typography.Title>
                    <div style={{ marginTop: 8 }} />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                        }}
                    >
                        <label
                            style={{
                                fontSize: 14,
                                fontFamily: "Montserrat",
                                color: "#8B8B8B",
                            }}
                            className="dashboard-as-of-today"
                        >
                            Enable to start a connection
                        </label>
                        <div
                            style={{
                                flex: 1,
                                marginLeft: 20,
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                            }}
                        >
                            <div style={{ flex: 1 }}>{displaySwitch()}</div>
                        </div>
                    </div>
                </Col>
            </Row>

            {displayConnectModal()}
        </SideDrawer>
    );
}
