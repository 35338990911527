import React, { useEffect, useState } from "react";
import SideDrawer from "../../Components/SideDrawer";
import { Button, Col, Row, Switch, Table, Typography } from "antd";
import { CreateInfosumConnectionModal } from "../../Components/CreateInfosumConnectionModal";
import { createNewGenericConnection, deleteAWSInfosumBridge, deleteGenericConnection, getAllConnections, getAllGenericConnections, getS3DetailsForPublisher, stopGenericConnection, stopS3SyncForPublisher, syncS3Toggle, updateGenericConnection } from "../../Utils/ApiActions";
import { mapSnowflakeStatusToColor } from "../../Utils/Helper";
import { mapSnowflakStatusToUI } from "../../Utils/Helper";
import DeleteTypeModal from "../../Components/LockrDeleteModal";
import { ReportModal } from "../SelfServe/SelfServe";
import moment from "moment";
import { InfosumCredentialsModal } from "../../Components/InfosumCredentialsModal";
import { useQuery } from "../../Components/customHook/useQuery";

export function InfosumConnections() {
    const query = useQuery();
    const batch = query.get('batch');
    const [displayLoading, setDisplayLoading] = useState(false);
    const [infosumConnections, setInfosumConnections] = useState([]);
    const [showNewConnectionModal, setNewConnectionModal] = useState(false);
    const [publisherBucketDetails, setPublisherBucketDetails] = useState(undefined);
    const [formMode, setFormMode] = useState('NEW'); //NEW , DUPLICATE, EDIT
    const [selectedConnectionDetails, setSelectedConnectionDetails] = useState(undefined);
    const [selectedConnectionToDelete, setSelectedConnectionToDelete] = useState(undefined);
    const [displayAwsCredentials, setDisplayAwsCredentials] = useState(false);
    const [selectedConnectionIDForReport, setSelectedConnectionIDForReport] = useState(undefined);
    const [connectionError, setConnectionError] = useState(undefined);
    const [showDeleteConnectionModal, setShowDeleteConnectionModal] = useState(false);

    function getJSONConfigurationForColumn(record) {
        return JSON.parse(record.configuration);
    }

    function renderStatus(status) {
        return (
            <Row style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
                <div style={{ height: 10, width: 10, borderRadius: 20, background: mapSnowflakeStatusToColor(status) }} />
                <label className='connection-tile-font' style={{ marginLeft: 8 }}>{mapSnowflakStatusToUI(status)}</label>
            </Row>
        )
    }

    function checkIfEditDisabled(record) {
        return record.status === 'PROCESSING' || record.status === 'SENT_TO_EMAILCHECKER' || record.status === 'IN_PROGRESS' || record.status === 'FAILED' || record.status === 'COMPLETED'
    }

    function renderActionButtons(record) {
        return (
            <Row style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                <Button onClick={() => {
                    setFormMode('DUPLICATE');
                    setSelectedConnectionDetails(record);
                    setNewConnectionModal(true);
                }} style={{ height: 35, color: '#8B8B8B', background: 'transparent', border: '1px solid #8B8B8B' }} type='default'>
                    <img style={{ marginRight: 8, cursor: 'pointer' }} alt="duplicate" src={require('../../assets/duplicate_icon.png')} />
                    <label className="connection-tile-font" style={{
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        cursor: 'pointer',
                    }}>Duplicate</label>
                </Button>
                <Button className='infosum-edit-button' disabled={checkIfEditDisabled(record)} onClick={() => {
                    if (record.status !== 'PROCESSING' && record.status !== 'SENT_TO_EMAILCHECKER') {
                        setFormMode('EDIT');
                        setSelectedConnectionDetails(record);
                        setNewConnectionModal(true);
                    }
                }} style={{ marginLeft: 8, height: 35, color: '#8B8B8B', background: 'transparent', border: '1px solid #8B8B8B' }} type='default'>
                    <img
                        alt="duplicate"
                        src={checkIfEditDisabled(record) ? require('../../assets/edit_disabled.png') : require('../../assets/edit_icon.png')}
                        style={{ marginRight: 8, cursor: 'pointer', height: 16, width: 16 }}
                    />
                    <label className="connection-tile-font" style={{
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        cursor: 'pointer',
                    }}>Edit</label>
                </Button>
               {!batch && <Button onClick={() => {
                    setSelectedConnectionIDForReport(record.id)
                }} style={{ marginLeft: 8, height: 35, color: '#8B8B8B', background: 'transparent', border: '1px solid #8B8B8B' }} type='default'>
                    <img
                        alt="duplicate"
                        src={require('../../assets/report_icon.png')}
                        style={{ marginRight: 8, cursor: 'pointer' }}
                    />
                    <label className="connection-tile-font" style={{
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        cursor: 'pointer',
                    }}>View Report</label>
                </Button>}
                {displayStopOrDeleteButton(record)}
            </Row>
        )
    }

    function displayStopOrDeleteButton(record) {
        if (record.status === 'PROCESSING' || record.status === 'SENT_TO_EMAILCHECKER') {
            return (
                <img alt='stop' onClick={() => {
                    stopConnectionAPICall(record.id);
                }} src={require('../../assets/stop_icon.png')} style={{
                    marginLeft: 16,
                    marginRight: 16,
                    cursor: 'pointer',
                    width: 16,
                }} />
            )
        } else {
            return (
                <img alt='delete' onClick={() => {
                    setSelectedConnectionToDelete(record.id);
                }} src={require('../../assets/remove_icon.png')} style={{
                    marginLeft: 16,
                    marginRight: 16,
                    cursor: 'pointer',
                    width: 16,
                }} />
            )
        }
    }

    let connectionListColumns = [{
        title: <span className="app-table-header">Address</span>,
        width: '35%',
        render: (_, record) => <label className="connection-tile-font">{getJSONConfigurationForColumn(record)?.destinationFileName}</label>
    }, {
        title: <span className="app-table-header">Date/Time</span>,
        render: (_, record) => <label className="connection-tile-font">{record.createdAt ? moment(record.createdAt).format('MM/DD/YYYY') : ''}</label>,
        align: 'right'
    }, {
        title: <span className="app-table-header">Status</span>,
        render: (_, record) => renderStatus(record.status),
        align: 'right'
    }, {
        title: <span className="app-table-header">Role ARN</span>,
        render: (_, record) => <div>
            <label className="connection-tile-font">{getJSONConfigurationForColumn(record)?.roleArn?.substring?.(0, 25) + '...'}</label>
            <img onClick={() => {
                navigator.clipboard.writeText(getJSONConfigurationForColumn(record)?.roleArn);
            }} alt='copy' style={{
                height: 16, width: 16, cursor: 'pointer', marginLeft: 8
            }} src={require('../../assets/copy_icon.png')} />
        </div>,
        align: 'right'
    }, {
        title: <span className="app-table-header">Actions</span>,
        align: 'right',
        render: (_, record) => renderActionButtons(record)
    }]


    if (batch) {
        connectionListColumns = connectionListColumns.filter(column => column.title?.props.children !== 'Status');
        connectionListColumns.unshift({
            title: <span className="app-table-header">Name</span>,
            render: (_, record) => (
                <label className="connection-tile-font">{getJSONConfigurationForColumn(record).name || 'N/A'}</label>
            ),
        });
    }

    useEffect(() => {
        fetchPublisherS3Details();
        getConnections();
    }, [])

    async function getConnections() {
        setDisplayLoading(true);
        try {
            const connectionsResponse = await getAllGenericConnections('INFOSUM', batch ? 'batch' : undefined);
            if (connectionsResponse.success && connectionsResponse.data.length) {
                setInfosumConnections(connectionsResponse.data);
            }
        } catch (error) {
            console.log(`Unable to get all connections at the moment`);
            console.log(error);
        }
        setDisplayLoading(false);
    }

    async function deleteConnectionAPICall() {
        try {
            await deleteGenericConnection(selectedConnectionToDelete);
            setSelectedConnectionToDelete(undefined);
            getConnections();
        } catch (error) {
            console.log(`Unable to delete the connection at the moment`);
            console.log(error);
        }
    }

    async function stopConnectionAPICall(id) {
        try {
            await stopGenericConnection(id);
            getConnections();
        } catch (error) {
            console.log(`Unable to stop the connection at the moment`);
            console.log(error);
        }
    }

    async function deleteInfosumBridge() {
        try {
            const deleteResponse = await deleteAWSInfosumBridge();
            if (deleteResponse.success) {
                setPublisherBucketDetails(undefined);
                setShowDeleteConnectionModal(false);
            }
        } catch (error) {
            console.log(`Unable to delete the infosum bridge at the moment.`);
            console.log(error);
        }
    }

    async function newConnectionApiCall(configuration) {
        try {
            let response = undefined;
            if (formMode === 'EDIT') {
                response = await updateGenericConnection(selectedConnectionDetails.id, 'INFOSUM', JSON.stringify(configuration), batch ? 'batch' : undefined);
            } else {
                response = await createNewGenericConnection(JSON.stringify(configuration), 'INFOSUM', batch ? 'batch' : undefined);
            }
            if (response.success) {
                setSelectedConnectionDetails(undefined);
                setNewConnectionModal(false);
                getConnections();
                setConnectionError(undefined);
                setFormMode('NEW');
                return;
            }
            setConnectionError(response?.error?.message ?? '');
        } catch (error) {
            console.log(`Unable to create new connection at the moment`);
            console.log(error);
        }
    }

    async function fetchPublisherS3Details() {
        try {
            const response = await getS3DetailsForPublisher();
            if (response.success && response.data) {
                setPublisherBucketDetails(response.data);
            }
        } catch (error) {
            console.log('Unable to fetch the details of s3 for the publisher');
            console.log(error);
        }
    }

    async function toggleS3Selection(checked) {
        try {
            if (checked) {
                const response = await syncS3Toggle();
                if (response.success) {
                    setPublisherBucketDetails(response.data);
                }
                return;
            }
            const response = await stopS3SyncForPublisher();
            if (response.success && response.data) {
                setPublisherBucketDetails(undefined);
            }
            return;
        } catch (error) {
            console.log(`Unable to toggle the S3 status at the moment`);
            console.log(error);
        }
    }

    function renderEmptySection() {
        if (!displayLoading && infosumConnections.length <= 0) {
            return <div style={{
                marginLeft: 90,
                marginRight: 90,
                minHeight: '50vh',
                background: 'black',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <img
                    alt='logo_grey'
                    src={require('../../assets/logo_grey.png')}
                />
                <div style={{ marginTop: 4, }} />
                <Typography.Text style={{
                    fontFamily: 'Montserrat',
                    fontStyle: 'normal',
                    fontWeight: '300',
                    fontSize: '20px',
                    color: '#8B8B8B'
                }}>+</Typography.Text>
                <div style={{ marginTop: 4 }} />
                <img style={{
                    height: 36,
                    width: 170
                }} alt='infosum_grey' src={require('../../assets/infosum_grey_logo.png')} />
                <div style={{ marginTop: 42, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography.Text style={{
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        fontSize: '24px',
                        color: '#fff'
                    }}>Connect lockr with Infosum</Typography.Text>
                    <Button
                        style={{ marginTop: 24 }}
                        type='primary'
                        disabled={!publisherBucketDetails || !publisherBucketDetails?.status}
                        className='btn-first-connection'
                        onClick={() => {
                            setNewConnectionModal(true);
                        }}>Create connection</Button>
                </div>
            </div>
        }
    }

    function displaySyncSwitch() {
        return (
            <div>
                <Switch
                    checked={!!publisherBucketDetails && publisherBucketDetails.status}
                    onChange={(checked) => {
                        toggleS3Selection(checked);
                    }}
                />
                {!!publisherBucketDetails && publisherBucketDetails.status && <Typography.Text onClick={() => {
                    setDisplayAwsCredentials(true);
                }} className="infosum-view-credentials-text" >View Credentials</Typography.Text>}
            </div>
        )
    }

    function renderTable() {
        if (infosumConnections.length > 0) {
            return (
                <div style={{
                    background: 'black', marginLeft: 90, marginRight: 90, padding: 20
                }}>
                    <Row>
                        <Col xs={18}>
                            <Typography.Text
                                style={{
                                    fontFamily: 'Montserrat',
                                    fontWeight: '700',
                                    fontSize: 20
                                }}>Connection Log</Typography.Text>
                        </Col>
                        <Col xs={6} style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                            <Button disabled={!publisherBucketDetails || !publisherBucketDetails.status} onClick={() => {
                                setShowDeleteConnectionModal(true);
                            }} className='infosum-delete-bridge-btn'>
                                <label className='infosum-delete-bridge-btn-text'>Delete Connection</label>
                            </Button>
                        </Col>
                    </Row>
                    <div style={{ marginTop: 20 }} />
                    <div className="connection-list-table">
                        <Table
                            style={{ background: 'black' }}
                            loading={displayLoading}
                            rowKey={"id"}
                            dataSource={infosumConnections}
                            columns={connectionListColumns}
                            pagination={false}
                        />
                    </div>
                </div>
            )
        }
    }

    return (
        <SideDrawer>
            <Row
                justify='space-between'
                align='middle'
                className='apiandservices-newapp-btn'
            >
                <Col sm={17} xs={24}>
                    <Typography.Title className="apiandservices-heading">InfoSum</Typography.Title>
                    <div style={{ marginTop: 8 }} />
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <label style={{ fontSize: 14, fontFamily: "Montserrat", color: '#8B8B8B' }} className='dashboard-as-of-today'>
                            Enable S3 sync to start connection
                        </label>
                        <div style={{ marginLeft: 20 }}>
                            {displaySyncSwitch()}
                        </div>
                    </div>
                </Col>
                <Col sm={7} xs={24} className="createAppButton">
                    <Button
                        style={{ width: '217px' }}
                        type='primary'
                        disabled={!publisherBucketDetails || !publisherBucketDetails?.status}
                        className='sign-in'
                        onClick={() => {
                            setNewConnectionModal(true);
                        }}
                    >New Analysis</Button>
                </Col>
            </Row>
            {renderEmptySection()}
            {renderTable()}
            {showNewConnectionModal && <CreateInfosumConnectionModal
                display={showNewConnectionModal}
                region={publisherBucketDetails.region}
                s3AccessKey={publisherBucketDetails.accessKey}
                secretKey={publisherBucketDetails.secretKey}
                s3URL={publisherBucketDetails.s3URL}
                error={connectionError}
                batch={batch}
                removeError={() => {
                    setConnectionError(undefined);
                }}
                defaultConnectionDetails={selectedConnectionDetails?.configuration ? JSON.parse(selectedConnectionDetails.configuration) : undefined}
                onSave={(configuration) => {
                    newConnectionApiCall(configuration);
                }}
                isEdit={formMode === 'EDIT'}
                isDuplicate={formMode === 'DUPLICATE'}
                onCancel={() => {
                    setSelectedConnectionDetails(undefined);
                    setFormMode('NEW');
                    setNewConnectionModal(false);
                }}
            />}
            {!!selectedConnectionToDelete && <DeleteTypeModal
                visible={!!selectedConnectionToDelete}
                handleOk={() => {
                    deleteConnectionAPICall();
                }}
                handleCancel={() => {
                    setSelectedConnectionToDelete(undefined);
                }}
                title={'Delete?'}
            />}
            {!!selectedConnectionIDForReport && <ReportModal
                visible={!!selectedConnectionIDForReport}
                onCancel={() => {
                    setSelectedConnectionIDForReport(undefined);
                }}
                connectionID={selectedConnectionIDForReport}
                isFromConnection
            />}
            {displayAwsCredentials && <InfosumCredentialsModal
                display={displayAwsCredentials}
                s3URL={publisherBucketDetails.s3URL}
                region={publisherBucketDetails.region}
                s3AccessKey={publisherBucketDetails.accessKey}
                secretKey={publisherBucketDetails.secretKey}
                onOk={() => {
                    setDisplayAwsCredentials(false);
                }}
            />}
            {
                showDeleteConnectionModal && <DeleteTypeModal
                    visible={showDeleteConnectionModal}
                    title="Delete Connection"
                    message={`Are you sure you want to delete your Infosum connection?
                    This will disable your S3 bucket and all data will be deleted.`}
                    confirmText={'Please type the word “DELETE” below to confirm'}
                    handleCancel={() => {
                        setShowDeleteConnectionModal(false);
                    }}
                    handleOk={() => {
                        deleteInfosumBridge();
                    }}
                />
            }
        </SideDrawer>
    )
}