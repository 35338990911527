import { Button, Dropdown } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineDown } from "react-icons/ai";

export const UNIQUE_USER_CACHE_DURATION = {
  LAST_HOUR: 'LAST_HOUR',
  LAST_DAY: 'LAST_DAY',
  LAST_7_DAYS: 'LAST_7_DAYS',
  LAST_WEEK: 'LAST_WEEK',
  LAST_30_DAYS: 'LAST_30_DAYS',
  LAST_MONTH: 'LAST_MONTH',
  THIS_MONTH: 'THIS_MONTH',
  LAST_90_DAYS: 'LAST_90_DAYS',
  LAST_QUARTER: 'LAST_QUARTER',
  THIS_YEAR: 'THIS_YEAR',
}

export const DATE_OPTIONS = {
  LAST_24_HOURS: "LAST_24_HOURS",
  LAST_7_DAYS: "LAST_7_DAYS",
  LAST_WEEK: "LAST_WEEK",
  LAST_30_DAYS: "LAST_30_DAYS",
  THIS_MONTH: "THIS_MONTH",
  LAST_90_DAYS: "LAST_90_DAYS",
  LAST_QUARTER: "LAST_QUARTER",
  LAST_12_MONTHS: "LAST_12_MONTHS",
  LAST_MONTH: "LAST_MONTH",
  CUSTOM: "CUSTOM",
};

export function IdentityProviderNonDatePicker({ onOptionSelected, dateOption, setCustomPickerDateOption }) {
  const [displayPicker, setDisplayPicker] = useState(false);
  const [displayOption, setDisplayOption] = useState(DATE_OPTIONS.LAST_7_DAYS);

  useEffect(() => {
    setCustomPickerDateOption?.(displayOption);
  }, [displayOption, setCustomPickerDateOption]);

  useEffect(() => {
    !!dateOption && setDisplayOption(dateOption);
  }, [dateOption]);

  function getNameFromDateOption() {
    switch (displayOption) {
      case DATE_OPTIONS.THIS_MONTH:
        return "This Month";
      case DATE_OPTIONS.LAST_MONTH:
        return "Last Month";
      case DATE_OPTIONS.LAST_QUARTER:
        return "Last Quarter";
      case DATE_OPTIONS.LAST_WEEK:
        return "Last Week";
      case DATE_OPTIONS.LAST_12_MONTHS:
        return "This Year";
      case DATE_OPTIONS.LAST_24_HOURS:
        return "Daily";
      case DATE_OPTIONS.LAST_30_DAYS:
        return "Last 30 days";
      case DATE_OPTIONS.LAST_7_DAYS:
        return "Weekly";
      case DATE_OPTIONS.LAST_90_DAYS:
        return "Last 90 Days";
      case DATE_OPTIONS.CUSTOM:
        return "Custom";
      default:
        return "Weekly";
    }
  }


  const menu = (
    <div className="identity-provider-options-dropdown">
      <div
        style={{
          marginTop: 10,
        }}
        onClick={() => {
          setDisplayPicker(false);
          setDisplayOption(DATE_OPTIONS.LAST_24_HOURS);
          onOptionSelected(DATE_OPTIONS.LAST_24_HOURS);
        }}
      >
        <span
          style={{
            color: "white",
            cursor: "pointer",
            fontFamily: "Montserrat",
            fontSize: 14,
          }}
        >
          Daily
        </span>
      </div>
      <div
        style={{
          marginTop: 10,
        }}
        onClick={() => {
          setDisplayPicker(false);
          setDisplayOption(DATE_OPTIONS.LAST_7_DAYS);
          onOptionSelected(DATE_OPTIONS.LAST_7_DAYS);
        }}
      >
        <span
          style={{
            color: "white",
            cursor: "pointer",
            fontFamily: "Montserrat",
            fontSize: 14,
          }}
        >
          Weekly
        </span>
      </div>
      <div
        style={{
          marginTop: 10,
        }}
        onClick={() => {
          setDisplayPicker(false);
          setDisplayOption(DATE_OPTIONS.LAST_30_DAYS);
          onOptionSelected(DATE_OPTIONS.LAST_30_DAYS);
        }}
      >
        <span
          style={{
            color: "white",
            cursor: "pointer",
            fontFamily: "Montserrat",
            fontSize: 14,
          }}
        >
          Last 30 days
        </span>
      </div>
      <div
        style={{
          marginTop: 10,
        }}
        onClick={() => {
          setDisplayPicker(false);
          setDisplayOption(DATE_OPTIONS.THIS_MONTH);
          onOptionSelected(DATE_OPTIONS.THIS_MONTH);
        }}
      >
        <span
          style={{
            color: "white",
            cursor: "pointer",
            fontFamily: "Montserrat",
            fontSize: 14,
          }}
        >
          This Month
        </span>
      </div>
      <div
        style={{
          marginTop: 10,
        }}
        onClick={() => {
          setDisplayPicker(false);
          setDisplayOption(DATE_OPTIONS.LAST_MONTH);
          onOptionSelected(DATE_OPTIONS.LAST_MONTH);
        }}
      >
        <span
          style={{
            color: "white",
            cursor: "pointer",
            fontFamily: "Montserrat",
            fontSize: 14,
          }}
        >
          Last Month
        </span>
      </div>
      <div
        style={{
          marginTop: 10,
        }}
        onClick={() => {
          setDisplayPicker(false);
          setDisplayOption(DATE_OPTIONS.LAST_90_DAYS);
          onOptionSelected(DATE_OPTIONS.LAST_90_DAYS);
        }}
      >
        <span
          style={{
            color: "white",
            cursor: "pointer",
            fontFamily: "Montserrat",
            fontSize: 14,
          }}
        >
          Last 90 Days
        </span>
      </div>
      <div
        style={{
          marginTop: 10,
        }}
        onClick={() => {
          setDisplayPicker(false);
          setDisplayOption(DATE_OPTIONS.LAST_QUARTER);
          onOptionSelected(DATE_OPTIONS.LAST_QUARTER);
        }}
      >
        <span
          style={{
            color: "white",
            cursor: "pointer",
            fontFamily: "Montserrat",
            fontSize: 14,
          }}
        >
          Last Quarter
        </span>
      </div>
      <div
        style={{
          marginTop: 10,
        }}
        onClick={() => {
          setDisplayPicker(false);
          setDisplayOption(DATE_OPTIONS.LAST_12_MONTHS);
          onOptionSelected(DATE_OPTIONS.LAST_12_MONTHS);
        }}
      >
        <span
          style={{
            color: "white",
            cursor: "pointer",
            fontFamily: "Montserrat",
            fontSize: 14,
          }}
        >
          This Year
        </span>
      </div>
    </div>
  );

  return (
    <Dropdown
      visible={displayPicker}
      destroyPopupOnHide={false}
      placement="bottomRight"
      onVisibleChange={event => {
        setDisplayPicker(event)
      }}
      onClick={() => {
        setDisplayPicker(true);
      }}
      trigger={["click"]}
      overlay={menu}
    >
      <Button
        className="custom-view-more-options"
        style={{
          height: "56px",
          marginRight: 8,
          display: "flex",
          flexDirection: "row",
        }}
        type="primary"
        shape="default"
      >
        <div>{getNameFromDateOption()}</div>
        <div>
          <AiOutlineDown
            style={{ marginLeft: 12, paddingTop: 4 }}
          ></AiOutlineDown>
        </div>
      </Button>
    </Dropdown>
  );
}
