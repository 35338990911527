import { Card, Checkbox, Col, message, Modal, Progress, Row, Spin, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import RegCommonButton from '../../Components/RegCommonButton'
import RegCommonTitle from '../../Components/RegCommonTitle'
import { CloseOutlined } from '@ant-design/icons'
import { acceptPublisherAgreement, baseUrl, BASE_URL, getApiLicense, register } from '../../Utils/ApiActions'
import RegCheckBox from '../../Components/RegCheckBox'
import { UserReviewModal } from '../../Components/UserReviewModal'

const { Title, Text, Paragraph, Link } = Typography
const Step4 = (props) => {
    const [agreement, setAgreement] = useState(false)
    const [registered, setRegistered] = useState(false)
    const [displayLoading, setDisplayLoading] = useState(false)
    const [licenseText, setLicenseText] = useState('');
    const [dpaText, setDpaText] = useState('');
    const [showUserReviewModal, setShowUserReviewModal] = useState(false);
    const _signUpDetails = props?.location?.state?.signUpDetails

    useEffect(() => {
        var parser = new DOMParser();
        // var doc = parser.parseFromString(str, 'text/html');

        getApiLicense().then(response => {
            setLicenseText(response.data.apiLicenseText, 'text/html');
            setDpaText(response.data.dpaLicenseText, 'text/html');
        }).catch(error => {
            console.log(`Error occurred, when trying to get the consent details`)
        })
        if (props?.location?.state === undefined || _signUpDetails === undefined) {
            props.history.replace('/console/signup')
        }
    }, [])

    const handleRegister = (reviewDetails) => {
        setDisplayLoading(true)
        _signUpDetails['isLicenseAgreed'] = agreement
        _signUpDetails['address'] = reviewDetails.address;
        register(_signUpDetails).then(response => {
            console.log("🚀 ~ register ~ response:", response)
            setDisplayLoading(false)
            if (response.success) {
                acceptAgreement({...reviewDetails, publisherID: response.publisherID});
                setRegistered(true)
            } else {
                // message.error("Unable to register account due to: " + response?.error?.message);
            }
        }).catch(err => {
            setDisplayLoading(false)
            // message.error("Unable to register account due to: " + err.message);
        })
    }

    const closeModal = () => {
        setRegistered(false)
        props.history.replace('/console/')
    }

    document.body.classList.add('lockr-bg-img');


    async function acceptAgreement(reviewDetails) {
        try {
            await acceptPublisherAgreement({ ..._signUpDetails, agreementCopy: licenseText, reviewDetails, publisherID: reviewDetails.publisherID });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Spin spinning={displayLoading}>
            <Row>
                <Progress className='reg-steps' percent={100} showInfo={false} />
            </Row>
            <Row justify='start' style = {{
                display: 'flex',
                flexDirection: 'column'
            }} className='reg-container'>
                <Row style={{ width: '100%' }}>
                    <Col>
                        <img className="lockr-logo" src={require('../../assets/onboard_lock.42d74f50.png')} alt='lock-img' />
                    </Col>
                </Row>
                <RegCommonTitle title={'lockr terms of service'} />
                <Row style={{ width: '100%', marginTop: 10 }}>
                    <Col xs={16}>
                        <Typography.Text style={{ fontWeight: '400', fontFamily: 'Montserrat, sans-serif', fontSize: 16 }}>Please read and agree to our terms outlined below.</Typography.Text>
                    </Col>
                </Row>
                <Row style={{ width: 620, marginTop: 46, display: "flex", alignItems: 'center' }}>
                    <Typography.Text style={{ fontFamily: 'Montserrat, sans-serif', fontSize: 16, fontWeight: '700' }}>API License Agreement</Typography.Text>
                    <div onClick = {()=>{
                        window.open(BASE_URL + '/portal/ssoStorage/apiLicenseFile.pdf', '_blank');
                    }} style={{
                        display: "flex",
                        flex: 1,
                        cursor: 'pointer',
                        justifyContent: "flex-end"
                    }}>
                        <div className='view-pdf-button'>
                            <img style={{ height: 16, width: 16 }} src={require('../../assets/pdf_icon.png')} />
                            <div style = {{width: 8}}/>
                            View As PDF
                        </div>
                    </div>
                </Row>
                <Row style={{ marginTop: 24, width: '100%' }}>
                    <Col xs={24}>
                        <Card style={{ background: "black" }} className='licenseCard' bordered={false} bodyStyle={{ backgroundColor: '#000' }}>
                            <Typography className='licenseAgreement licenseTextPrompter'>
                                <div style={{ overflowY: 'scroll', fontSize: 12 }} dangerouslySetInnerHTML={{ __html: licenseText }}></div>
                            </Typography>
                        </Card>
                    </Col>
                </Row>
                <Row style={{ width: 620, marginTop: 32, display: "flex", alignItems: 'center' }}>
                    <Typography.Text style={{  fontFamily: 'Montserrat, sans-serif', fontSize: 16, fontWeight: '700' }}>Data Processing Addendum</Typography.Text>
                    <div onClick = {()=>{
                        window.open(BASE_URL + '/portal/ssoStorage/dpaFile.pdf', '_blank');
                    }} style={{
                        display: "flex",
                        flex: 1,
                        cursor: 'pointer',
                        justifyContent: "flex-end"
                    }}>
                        <div className='view-pdf-button'>
                            <img style={{ height: 16, width: 16 }} src={require('../../assets/pdf_icon.png')} />
                            <div style = {{width: 8}}/>
                            View As PDF
                        </div>
                    </div>
                </Row>
                <Row>
                    <Col xs={24} style={{ marginTop: 24, }}>
                        <Card style={{ background: "black" }} className='licenseCard' bordered={false} bodyStyle={{ backgroundColor: '#000' }}>
                            <Typography className='licenseAgreement licenseTextPrompter'>
                                <div style={{ overflowY: 'scroll', fontSize: 12 }} dangerouslySetInnerHTML={{ __html: dpaText }}></div>
                            </Typography>
                        </Card>
                    </Col>
                    <Col style={{ marginTop: 25 }}>
                        {/* <Checkbox className='agreement-checkbox' checked={agreement} onChange={(event) => setAgreement(event.target.checked)} style={{ color: '#fff' }}>
                            <Text style={{ fontFamily: 'Montserrat, sans-serif', fontSize: 16 }}>
                                Yes, I agree to lockr's API License Agreement
                            </Text>
                        </Checkbox> */}
                        <RegCheckBox label={"Yes, I agree to lockr’s API License Agreement & Data Processing Addendum"} checked={agreement} onChange={(checked) => {
                            setAgreement(checked);
                        }}></RegCheckBox>
                    </Col>
                </Row>
                <RegCommonButton disabled={!agreement} btntext={'Review & sign'} onClick={() => {
                    setShowUserReviewModal(true);
                }} />
            </Row>
            {showUserReviewModal && <UserReviewModal
                fullName={`${_signUpDetails.firstName} ${_signUpDetails.lastName}`}
                onSave={(reviewDetails) => {
                    handleRegister(reviewDetails);
                    setShowUserReviewModal(false);
                }}
                onCancel={() => {
                    setShowUserReviewModal(false);
                }}
                visible={true}
            />}
            {registered && <Modal width={716} style={{ height: 331 }} className='verificationModal'
                bodyStyle={{ paddingTop: 0, backgroundColor: '#1d1d1d', paddingRight: 60, paddingLeft: 60 }}
                title={<Title style={{ fontSize: 44, fontFamily: 'Montserrat, sans-serif', fontWeight: 700, marginBottom: 0 }}>Verification</Title>}
                footer={null}
                visible={registered}
                maskClosable={false}
                maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.85)' }}            
                closable={false}
            >
                <Row style={{ width: '100%' }}>
                    <Paragraph>
                        We have sent an email with a verification link - please click the link to continue setting up your Identity lockr account.
                    </Paragraph>
                </Row>
                <Row style={{ width: '100%' }}>
                    <Text italic>
                        Note: The link will be valid for 24 Hrs
                    </Text>
                </Row>
                <Row style={{ width: '100%', marginTop: 48 }}>
                    <Col span={12}>
                        <Link style={{ fontFamily: 'Montserrat, sans-serif', fontSize: 12, fontWeight: 'bold', color: '#2684f8' }} href={'https://loc.kr/contact/'} target='_blank'>
                            Need help?
                        </Link>
                    </Col>
                    {/* <Col span={12} style={{ textAlign: 'right' }}>
                            <Link onClick={() => {
                                props.history.replace('/console/')
                            }} style={{ fontFamily: 'Montserrat, sans-serif', fontSize: 12, fontWeight: 'bold', color: '#2684f8' }}>
                                Goto login page
                            </Link>
                        </Col> */}
                </Row>
            </Modal>}
        </Spin>
    )
}

export default withRouter(Step4)