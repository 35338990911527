import React, { createContext, useState } from 'react';

export const userStore = createContext();

export function UserStore(props) {
    const [userRole, setUserRole] = useState('');

    return (
        <userStore.Provider value={{
            state: { userRole },
            actions: { setUserRole }
        }}>
            {props.children}
        </userStore.Provider>
    )
}