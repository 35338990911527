/* eslint-disable react-hooks/exhaustive-deps */
import {
    Card,
    Col,
    ConfigProvider,
    DatePicker,
    Modal,
    Row,
    Spin,
    Table,
    Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import WorldMap from "react-svg-worldmap";
import {
    Area,
    AreaChart,
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Label,
    Legend,
    Line,
    LineChart,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import SideDrawer from "../../Components/SideDrawer";
import { AiOutlineCheck, AiOutlineDown } from "react-icons/ai";
import {
    getAcceptRejectCounts,
    getRegionWiseConsentMapReport,
    getReturningAndNewUsers,
    getTopRegions,
    getRejectedEmailList,
    getRejectedConsentList,
    getLoginTrend,
    getAcceptRejectEmailCounts,
    getUserAuthenticationDashboardData,
    listAllApplications,
    getUIDUserGraphData,
} from "../../Utils/ApiActions";
import { getCountryNameFromCode } from "../../Utils/Helper";

import locale from "antd/es/locale/en_GB";
import {
    IdentityProviderStatsAuthStrip,
    LockrFromToDatePicker,
} from "../Connections/components/IdentityProviderStatsAuthStrip";
import { IdentityLockrPercentageComparison } from "./components/IdentityLockrPercentageComparison";
import { LockrMailUserPreferences } from "./components/lockrMailUserPreferences";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { IdentityProviderGraphStrip } from "../Connections/components/IdentityProviderlockrGraphStrip";
import { RejectedEmailsModal } from "./components/RejectedEmailsModal";
import { AIMAnalyticsMaps } from "./components/AIMAnalyticsMaps";
import { AppSelectionDropdown } from "../Connections/components/AppSelectionDropdown";
const { Text } = Typography;

function PiChartCardData(props) {
    return (
        <div style={{ height: "100%", background: "black" }}>
            <Row
                style={{
                    justifyContent: "center",
                    alignContent: "center",
                    paddingTop: 32,
                }}
            >
                <Typography.Title
                    className="dashboard-actual-counts"
                    style={{ color: "#2E70D2" }}
                >
                    {props.totalCount}
                </Typography.Title>
                <Row>
                    {props.trend > 0 ? (
                        <Col style={{ marginLeft: 12, height: 0, marginTop: 24 }}>
                            <img
                                style={{ width: 10 }}
                                src={require("../../assets/up-trend.png")}
                            ></img>
                        </Col>
                    ) : props?.trend < 0 ? (
                        <Col
                            style={{
                                display: "inline",
                                marginLeft: 12,
                                height: 0,
                                marginTop: 24,
                            }}
                        >
                            <img
                                style={{ width: 10 }}
                                src={require("../../assets/down-trend.png")}
                            ></img>
                        </Col>
                    ) : (
                        <Col
                            style={{
                                display: "inline-block",
                                border: "0.5px solid #8B8B8B",
                                height: 0,
                                width: 10,
                                marginTop: 36,
                                marginLeft: 12,
                            }}
                        ></Col>
                    )}
                    <Col>
                        <Typography.Title
                            className={
                                props?.trend > 0
                                    ? "dashboard-analytics-counts-change-up"
                                    : props?.trend < 0
                                        ? "dashboard-analytics-counts-change-down"
                                        : "dashboard-analytics-counts-change"
                            }
                        >
                            {" "}
                            {props.trend ? props.trend : 0}{" "}
                        </Typography.Title>
                    </Col>
                    {/* <Col style={{ display: 'inline-block', border: '0.5px solid #8B8B8B', height: 0, width: 10, marginTop: 36, marginLeft: 12 }}></Col>
                <Col><Typography.Title className={"dashboard-analytics-counts-change"}> {props.trend}</Typography.Title></Col> */}
                </Row>
            </Row>
            <Row style={{ justifyContent: "center", alignContent: "center" }}>
                <Typography.Text className="pie-chart-title">
                    {props.title}
                </Typography.Text>
            </Row>
            <Row
                style={{
                    justifyContent: "center",
                    alignContent: "center",
                    marginTop: 32,
                }}
            >
                <PieChart width={240} height={240}>
                    <Pie
                        data={props.data}
                        stroke=""
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius={80}
                        outerRadius={120}
                        paddingAngle={0}
                        labelLine={false}
                    // label={renderCustomizedPieLabel}
                    >
                        {props.data?.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={props.colors[index]} />
                        ))}
                    </Pie>
                    <Tooltip />
                    {/* <Legend /> */}
                </PieChart>
            </Row>
            <Row
                style={{
                    justifyContent: "center",
                    alignContent: "center",
                    marginTop: 24,
                }}
            >
                <div
                    style={{
                        display: "inline",
                        width: 12,
                        height: 12,
                        background: props.colors[0],
                        borderRadius: 50,
                        marginTop: 6,
                        marginRight: 8,
                    }}
                ></div>
                <Typography.Text className="legend-text">
                    {props.data[0].name}
                </Typography.Text>
                <div style={{ display: "inline", width: 12 }}></div>
                <div
                    style={{
                        display: "inline",
                        width: 12,
                        height: 12,
                        background: props.colors[1],
                        borderRadius: 50,
                        marginTop: 6,
                        marginRight: 8,
                    }}
                ></div>
                <Typography.Text className="legend-text">
                    {props.data[1].name}
                </Typography.Text>
            </Row>
        </div>
    );
}

function Analytics(props) {
    moment.locale("en-gb", {
        week: { dow: 1 },
        weekdaysMin: ["S", "M", "T", "W", "T", "F", "S"],
        monthsShort: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ],
    });
    const [topRegions, setTopRegions] = useState([]);
    const [topRegionsModal, setTopRegionsModal] = useState(false);
    const [rejectEmailModal, setRejectEmailModal] = useState(false);
    const [rejectConsentModal, setRejectConsentModal] = useState(false);
    const [rejectedEmailList, setRejectedEmailList] = useState([]);
    const [acceptEmailCount, setAcceptEmailCount] = useState(0);
    const [acceptEmailCountTrend, setAcceptEmailCountTrend] = useState(0);
    const [rejectEmailCount, setRejectEmailCount] = useState(0);
    const [acceptCountTrend, setAcceptCountTrend] = useState(0);
    const [acceptCount, setAcceptCount] = useState(0);
    const [rejectCount, setRejectCount] = useState(0);
    const [newUserTrend, setNewUserTrend] = useState(0);
    const [newUserCount, setNewUserCount] = useState(0);
    const [returningUserCount, setReturningUserCount] = useState(0);
    const [loginTrend, setLoginTrend] = useState([]);
    const [rejectedConsentUsers, setRejectedConsentUsers] = useState([]);
    const dateFormat = "dddd MM/DD";
    const [fromDate, setFromDate] = useState(moment().add(-7, "days").valueOf());
    const [toDate, setToDate] = useState(moment().valueOf());
    const [loginTrendsStartDate, setLoginTrendsStartDate] = useState(
        moment().subtract(7, "day")
    );
    const [loginTrendsEndDate, setLoginTrendsEndDate] = useState(moment());

    const [aimMapStartDate, setAIMMapStartDate] = useState(moment().subtract(7, 'days'));
    const [aimMapEndDate, setAIMMapEndDate] = useState(moment());

    const [userAuthDataStartDate, setUserAuthDataStartDate] = useState(
        moment().subtract(7, "days")
    );
    const [userAuthDataEndDate, setUserAuthDataEndDate] = useState(moment());
    const [userAuthenticationData, setUserAuthenticationData] = useState([]);
    const [appIDs, setAppIDs] = useState([]);
    const [loginTrendsAppIDs, setLoginTrendsAppIDs] = useState([]);
    const [authDataAppIDs, setAuthDataAppIDs] = useState([]);
    const [currentRefreshTime, setCurrentRefreshTime] = useState(
        moment().format("hh:mm A")
    );

    const [userAuthenticationGraph, setUserAuthenticationGraph] = useState([]);
    const [userAuthenticationHistoryGraph, setUserAuthenticationHistoryGraph] = useState([]);

    const [appsList, setAppsList] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        getTopRegions(fromDate, toDate, appIDs)
            .then((response) => {
                let region = [];
                response.data?.map((country) =>
                    region.push({ country: getCountryNameFromCode(country.country) })
                );
                setTopRegions(region);
            })
            .catch((error) => {
                // console.log(error);
            });

        getRejectedConsentList(fromDate, toDate, appIDs)
            .then((response) => {
                if (JSON.stringify(response.data) == JSON.stringify({})) {
                    setRejectedConsentUsers([]);
                } else {
                    setRejectedConsentUsers(response?.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        getTopRegionAcceptance();
    }, [fromDate, toDate, appIDs]);


    const isEmailVerificationEnabled = useMemo(() => {
        let enabled = false;
        appsList.forEach(item => {
            if (item.emailVerificationIntegrated) {
                enabled = true;
            }
        })
        return enabled;
    }, [appsList]);

    const [regionConsent, setRegionConsent] = useState([]);
    const COLORS = ["#BD94BC", "#61CEF7"];

    useEffect(() => {
        getAppList();
    }, [])

    async function getGraphData() {
        try {
            const response = await getUIDUserGraphData(loginTrendsStartDate, loginTrendsEndDate, 'all', loginTrendsAppIDs);
            if (response.success) {
                setUserAuthenticationGraph(response.data.data);
                setUserAuthenticationHistoryGraph(response.data.historyData);
            }
        } catch (error) {
            console.log('Unable to get the graph data at the moment');
            console.log(error);
        }
    }

    async function getAppList() {
        try {
            const response = await listAllApplications();
            if (response.success) {
                setAppsList(response.data);
            }
        } catch (error) {
            console.log('Unable to get the list of the applications');
            console.log(error);
        }
    }

    const ssoUserCount = useMemo(() => {
        if (userAuthenticationData?.length) {
            let result = 0;
            userAuthenticationData.forEach((item) => {
                if (item.eventType === "lockrMail") {
                    result = parseInt(item.count);
                }
            });
            return result;
        }
        return 0;
    }, [userAuthenticationData]);

    const utmUserCount = useMemo(() => {
        if (userAuthenticationData?.length) {
            let result = 0;
            userAuthenticationData.forEach((item) => {
                if (item.eventType === "UTM") {
                    result = parseInt(item.count);
                }
            });
            return result;
        }
        return 0;
    }, [userAuthenticationData]);

    const emailCount = useMemo(() => {
        if (userAuthenticationData?.length) {
            let result = 0;
            userAuthenticationData.forEach((item) => {
                if (item.eventType !== "UTM" && item.eventType !== "lockrMail") {
                    result += parseInt(item.count);
                }
            });
            return result;
        }
        return 0;
    }, [userAuthenticationData]);

    useEffect(() => {
        getUserAuthenticationData();
        fetchNewReturningUsers();
        fetchAcceptedRejectedEmails();
        getAcceptRejectEmailCount();
        fetchRejectedEmails();
    }, [userAuthDataStartDate, userAuthDataEndDate, authDataAppIDs]);

    useEffect(() => {
        // getLoginTrendsData();
        getGraphData();
    }, [loginTrendsStartDate, loginTrendsEndDate, loginTrendsAppIDs]);

    useEffect(() => {
        getTopRegions(fromDate, toDate, appIDs)
            .then((response) => {
                let region = [];
                response.data?.map((country) =>
                    region.push({ country: getCountryNameFromCode(country.country) })
                );
                setTopRegions(region);
            })
            .catch((error) => {
                // console.log(error);
            });

        getRejectedConsentList(fromDate, toDate, appIDs)
            .then((response) => {
                if (JSON.stringify(response.data) == JSON.stringify({})) {
                    setRejectedConsentUsers([]);
                } else {
                    setRejectedConsentUsers(response?.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        getTopRegionAcceptance();
    }, [fromDate, toDate, appIDs]);

    function fetchRejectedEmails() {
        getRejectedEmailList(
            moment(userAuthDataStartDate).valueOf(),
            moment(userAuthDataEndDate).valueOf(),
            authDataAppIDs
        )
            .then((response) => {
                if (JSON.stringify(response.data) == JSON.stringify({})) {
                    setRejectedEmailList([]);
                } else {
                    setRejectedEmailList(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function fetchAcceptedRejectedEmails() {
        getAcceptRejectCounts(
            moment(userAuthDataStartDate).valueOf(),
            moment(userAuthDataEndDate).valueOf(),
            authDataAppIDs
        )
            .then((response) => {
                setAcceptCountTrend(response.data?.trendCount);
                setAcceptCount(response.data?.acceptCount);
                setRejectCount(response.data?.rejectCount);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function fetchNewReturningUsers() {
        getReturningAndNewUsers(
            moment(userAuthDataStartDate).valueOf(),
            moment(userAuthDataEndDate).valueOf(),
            authDataAppIDs
        )
            .then((response) => {
                setNewUserTrend(response.data?.trendCount);
                setNewUserCount(response.data?.newUserCount);
                setReturningUserCount(response.data?.returningUserCount);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getTopRegionAcceptance = () => {
        getRegionWiseConsentMapReport(fromDate, toDate, appIDs)
            .then((response) => {
                setRegionConsent(response?.data || []);
            })
            .catch((error) => {
                // console.log("Unable to get the region wise consent");
                console.log(error);
            });
    };

    const getAcceptRejectEmailCount = () => {
        getAcceptRejectEmailCounts(
            moment(userAuthDataStartDate).valueOf(),
            moment(userAuthDataEndDate).valueOf(),
            authDataAppIDs
        )
            .then((response) => {
                // console.log(response.data?.trendCount)
                setAcceptEmailCountTrend(response.data?.trendCount);
                setAcceptEmailCount(response.data?.acceptCount);
                setRejectEmailCount(response.data?.rejectCount);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getLoginTrendsData = () => {
        getLoginTrend(
            moment(loginTrendsStartDate).valueOf(),
            moment(loginTrendsEndDate).valueOf()
        )
            .then((response) => {
                let trend = [];
                response?.data?.pastDateArray?.map((current, index) =>
                    trend.push({
                        currentDate: response?.data?.currentDateArray[index]?.date,
                        pastDate: response?.data?.pastDateArray[index]?.date,
                        currentValue: response?.data?.currentDateArray[index]?.value,
                        pastValue: response?.data?.pastDateArray[index]?.value,
                    })
                );
                setLoginTrend(trend);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const customTooltipOnYourLine = (e) => {
        if (e.active && e.payload != null && e.payload[0] != null) {
            return (
                <div
                    style={{ height: 100, width: 100, background: "white" }}
                    className="custom-tooltip"
                >
                    <p color="white">{e.payload[0].payload.currentDate}</p>
                </div>
            );
        } else {
            return "";
        }
    };

    const renderLineChart = () => {
        return (
            <Row
                justify="space-between"
                align="middle"
                style={{ marginTop: 100 }}
                className="analytics-heading"
            >
                <Col sm={12} xs={24}>
                    <Typography.Title className="dashboard-title">
                        Login trends
                    </Typography.Title>
                    <Typography.Text
                        style={{ fontSize: 14, fontFamily: "Montserrat", color: "#8B8B8B" }}
                    ></Typography.Text>
                </Col>
                <Col sm={12} xs={24} style={{ textAlign: "end" }}>
                    <LockrFromToDatePicker
                        endDate={loginTrendsEndDate}
                        startDate={loginTrendsStartDate}
                        onChangeEndDate={setLoginTrendsEndDate}
                        onChangeStartDate={setLoginTrendsStartDate}
                        addCustomDatePicker
                    />
                </Col>
                <Col sm={24} xs={24} style={{ paddingTop: 6 }}>
                    <div
                        style={{
                            color: "white",
                            display: "inline-block",
                            marginRight: 24,
                            fontFamily: "Montserrat",
                            fontSize: 14,
                        }}
                    >
                        <div
                            style={{
                                display: "inline-block",
                                height: 12,
                                width: 12,
                                background: "#FAC748",
                                marginRight: 8,
                                borderRadius: 50,
                            }}
                        ></div>{" "}
                        Past
                    </div>
                    <div
                        style={{
                            color: "white",
                            display: "inline-block",
                            marginRight: 24,
                            fontFamily: "Montserrat",
                            fontSize: 14,
                        }}
                    >
                        <div
                            style={{
                                display: "inline-block",
                                height: 12,
                                width: 12,
                                background: "#50E8B1",
                                marginRight: 8,
                                borderRadius: 50,
                            }}
                        ></div>{" "}
                        Current
                    </div>
                </Col>

                <Row
                    style={{
                        width: "100%",
                        height: 320,
                        background: "black",
                        marginTop: 24,
                        paddingTop: 30,
                        paddingBottom: 4,
                    }}
                >
                    <ResponsiveContainer width={"100%"}>
                        <LineChart
                            width={"100%"}
                            height={320}
                            data={loginTrend}
                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                            <CartesianGrid
                                stroke="#353738"
                                strokeDasharray="3 3"
                                color="#353738"
                            />
                            <XAxis
                                style={{ color: "#EDEDED" }}
                                stroke="#353738"
                                tickLine={{ display: "none" }}
                                fontSize={10}
                                dataKey="currentDate"
                            />
                            {/* <XAxis xAxisId={"pastDate"} tickLine={{ display: "none" }} fontSize={0} dataKey="pastDate" padding={{ left: 30, right: 30 }} /> */}
                            <YAxis
                                tick={{ color: "#EDEDED" }}
                                tickLine={{ display: "none" }}
                                tickCount={7}
                                fontSize={10}
                                axisLine={false}
                            >
                                <Label
                                    dx={-18}
                                    style={{ fontFamily: "Montserrat", fontSize: 14 }}
                                    angle={270}
                                    fill="#8B8B8B"
                                    value={"Login Count"}
                                ></Label>
                            </YAxis>
                            <Tooltip
                                contentStyle={{ background: "#171717", border: "0" }}
                                labelStyle={{ display: "none" }}
                                wrapperStyle={{ background: "black" }}
                                formatter={(value1, value2, value3) => {
                                    // console.log(value1, value2, value3)
                                    let v = "";
                                    if (value2 === "currentValue") {
                                        v = value3.payload.currentDate;
                                    } else {
                                        v = value3.payload.pastDate;
                                    }
                                    return [value1, v];
                                }}
                            ></Tooltip>

                            <Line
                                activeDot={{ display: "none" }}
                                type="monotone"
                                dataKey="currentValue"
                                stroke="#50E8B1"
                                dot={false}
                            ></Line>
                            <Line
                                activeDot={{ display: "none" }}
                                type="monotone"
                                dataKey="pastValue"
                                stroke="#FAC748"
                                dot={false}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </Row>
                <Row
                    justify="center"
                    style={{
                        width: "100%",
                        marginTop: 12,
                        color: "#8B8B8B",
                        fontFamily: "Montserrat",
                        fontSize: 14,
                        textAlign: "center",
                    }}
                >
                    Date
                </Row>
            </Row>
        );
    };

    const topRegionsColumns = [
        {
            title: (
                <div>
                    <label className="analytics-table-head">Top regions</label>
                    <label
                        className="analytics-table-head-copy"
                        style={{ display: "block" }}
                    ></label>
                </div>
            ),
            key: "country",
            dataIndex: "country",
        },
    ];

    const rejectedEmailsColumn = [
        {
            title: (
                <div>
                    <label className="analytics-table-head">Rejected emails</label>
                    <label
                        className="analytics-table-head-copy"
                        style={{ display: "block" }}
                    ></label>
                </div>
            ),
            key: "lockrEmail",
            dataIndex: "lockrEmail",
        },
    ];

    const rejectConstentColumn = [
        {
            title: (
                <div>
                    <label className="analytics-table-head">Top domains</label>
                    <label
                        className="analytics-table-head-copy"
                        style={{ display: "block" }}
                    ></label>
                </div>
            ),
            key: "lockrEmail",
            dataIndex: "lockrEmail",
        },
    ];

    const topEightSplice = (data) => {
        let _data = Object.assign([], data);
        _data.splice(8);
        return _data;
    };

    const handleOk = () => {
        setTopRegionsModal(false);
    };

    const handleCancel = () => {
        setTopRegionsModal(false);
    };

    const customFormat = (value) => `${value.format(dateFormat)}`;

    async function getUserAuthenticationData() {
        try {
            const response = await getUserAuthenticationDashboardData(
                userAuthDataStartDate,
                userAuthDataEndDate,
                "all",
                authDataAppIDs
            );
            if (response.success) {
                setUserAuthenticationData(response.data);
                return;
            }
            setUserAuthenticationData([]);
        } catch (error) {
            console.log("Unable to get the user authentication data at the moment");
            console.log(error);
        }
    }

    const renderTables = () => {
        return (
            <Row justify="space-between" className="analytics-tables">
                <Col sm={24} xs={24} lg={8}>
                    <div style={{ marginRight: 20, height: "100%" }}>
                        <Table
                            className="analytics-tables-cell"
                            pagination={false}
                            dataSource={topEightSplice(topRegions)}
                            columns={topRegionsColumns}
                        ></Table>
                        {topRegions.length > 8 && (
                            <div
                                onClick={() => setTopRegionsModal(true)}
                                style={{
                                    color: "#61CEF7",
                                    fontFamily: "Montserrat",
                                    fontWeight: 700,
                                    fontSize: 14,
                                    marginTop: 24,
                                    cursor: "pointer",
                                }}
                            >
                                View all
                            </div>
                        )}
                        <Modal
                            transitionName=""
                            maskTransitionName=""
                            centered
                            width={716}
                            bodyStyle={{
                                height: 666,
                                width: 716,
                                backgroundColor: "#353738",
                                paddingLeft: 60,
                                paddingTop: 40,
                                overflowX: "hidden",
                                paddingRight: 0,
                            }}
                            maskStyle={{ backdropFilter: "blur(8px)" }}
                            visible={topRegionsModal}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            footer={null}
                            closable={false}
                        // closeIcon={<img src={require('../assets/modal-close.png')}></img>}
                        >
                            <div>
                                {" "}
                                <Typography.Title
                                    style={{
                                        display: "inline-block",
                                        fontFamily: "Montserrat",
                                        color: "#fff",
                                        fontWeight: 700,
                                        fontSize: 44,
                                    }}
                                >
                                    Top regions
                                </Typography.Title>
                                <img
                                    onClick={handleCancel}
                                    src={require("../../assets/modal-close.png")}
                                    className="view-all-modal-x-button"
                                ></img>
                            </div>
                            <div style={{ marginTop: 32, paddingRight: 69 }}>
                                {topRegions?.map((region) => (
                                    <div
                                        style={{
                                            borderBottom: "1px solid #8B8B8B",
                                            paddingBottom: 16,
                                            marginTop: 16,
                                            color: "white",
                                            fontSize: 16,
                                            fontFamily: "Montserrat",
                                        }}
                                    >
                                        {region.country}
                                    </div>
                                ))}
                            </div>
                        </Modal>
                    </div>
                </Col>
                <Col sm={24} xs={24} lg={8}>
                    <div style={{ marginLeft: 20, marginRight: 20, height: "100%" }}>
                        <Table
                            className="analytics-tables-cell"
                            pagination={false}
                            dataSource={topEightSplice(rejectedEmailList)}
                            columns={rejectedEmailsColumn}
                        ></Table>
                        {rejectedEmailList.length > 8 && (
                            <div
                                onClick={() => setRejectEmailModal(true)}
                                style={{
                                    color: "#61CEF7",
                                    fontFamily: "Montserrat",
                                    fontWeight: 700,
                                    fontSize: 14,
                                    marginTop: 24,
                                    cursor: "pointer",
                                }}
                            >
                                View all
                            </div>
                        )}
                    </div>
                </Col>
                <Col sm={24} xs={24} lg={8}>
                    <div style={{ marginLeft: 20, height: "100%" }}>
                        <Table
                            className="analytics-tables-cell"
                            pagination={false}
                            dataSource={topEightSplice(rejectedConsentUsers)}
                            columns={rejectConstentColumn}
                        ></Table>
                        {rejectedConsentUsers?.length > 8 && (
                            <div
                                onClick={() => setRejectConsentModal(true)}
                                style={{
                                    color: "#61CEF7",
                                    fontFamily: "Montserrat",
                                    fontWeight: 700,
                                    fontSize: 14,
                                    marginTop: 24,
                                    cursor: "pointer",
                                }}
                            >
                                View all
                            </div>
                        )}
                        <Modal
                            transitionName=""
                            maskTransitionName=""
                            centered
                            width={716}
                            bodyStyle={{
                                height: 666,
                                width: 716,
                                backgroundColor: "#353738",
                                paddingLeft: 60,
                                paddingTop: 40,
                                overflowX: "hidden",
                                paddingRight: 0,
                            }}
                            maskStyle={{ backdropFilter: "blur(8px)" }}
                            visible={rejectConsentModal}
                            onOk={() => setRejectConsentModal(false)}
                            onCancel={() => setRejectConsentModal(false)}
                            footer={null}
                            closable={false}
                        // closeIcon={<img src={require('../assets/modal-close.png')}></img>}
                        >
                            <div>
                                {" "}
                                <Typography.Title
                                    style={{
                                        display: "inline-block",
                                        fontFamily: "Montserrat",
                                        color: "#fff",
                                        fontWeight: 700,
                                        fontSize: 44,
                                    }}
                                >
                                    Rejected consent users
                                </Typography.Title>
                                <img
                                    onClick={() => setRejectConsentModal(false)}
                                    style={{ marginLeft: 57 }}
                                    src={require("../../assets/modal-close.png")}
                                    className="view-all-modal-x-button"
                                ></img>
                            </div>
                            <div style={{ marginTop: 32, marginRight: 69 }}>
                                {rejectedConsentUsers &&
                                    rejectedConsentUsers?.map((user) => (
                                        <div
                                            style={{
                                                borderBottom: "1px solid #8B8B8B",
                                                paddingBottom: 16,
                                                marginTop: 16,
                                                color: "white",
                                                fontSize: 16,
                                                fontFamily: "Montserrat",
                                            }}
                                        >
                                            {user.lockrEmail}
                                        </div>
                                    ))}
                            </div>
                        </Modal>
                    </div>
                </Col>
            </Row>
        );
    };

    const renderHeading = () => {
        return (
            <Row justify="space-between" align="middle" className="analytics-heading">
                <Col sm={9} xs={24}>
                    <Typography.Title className="dashboard-title">
                        Consent per Region
                    </Typography.Title>
                </Col>
                <Col sm={15} xs={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: "end" }}>
                    <AppSelectionDropdown disabled={false} value={appIDs} onChange={appIDs => { setAppIDs(appIDs) }} />
                    <LockrFromToDatePicker
                        endDate={moment(toDate)}
                        startDate={moment(fromDate)}
                        onChangeEndDate={(e) => {
                            setToDate(moment(e).valueOf())
                        }}
                        onChangeStartDate={e => {
                            setFromDate(moment(e).valueOf())
                        }}
                        addCustomDatePicker
                    />
                </Col>
            </Row>
        );
    };

    const renderPieChartCards = () => {
        return (
            <Row justify="space-between" style={{ marginLeft: 91, marginRight: 91 }}>
                <Col xs={24} sm={8} style={{ height: 466 }}>
                    <div style={{ marginRight: 20, height: "100%" }}>
                        <PiChartCardData
                            data={[
                                { name: "Accepted", value: acceptCount },
                                { name: "Rejected", value: rejectCount },
                            ]}
                            colors={COLORS}
                            title="Consent Acceptance"
                            totalCount={acceptCount}
                            trend={acceptCountTrend}
                        ></PiChartCardData>
                    </div>
                </Col>
                <Col sm={24} xs={24} lg={8}>
                    <div style={{ marginRight: 20, height: "100%", marginLeft: 20 }}>
                        <PiChartCardData
                            data={[
                                { name: "Verified", value: acceptEmailCount },
                                { name: "Rejected", value: rejectEmailCount },
                            ]}
                            colors={["#E88D67", "#2E70D2"]}
                            title="Emails Verified vs Rejected"
                            totalCount={acceptEmailCount}
                            trend={acceptEmailCountTrend}
                        ></PiChartCardData>
                    </div>
                </Col>

                <Col sm={24} xs={24} lg={8}>
                    <div style={{ marginLeft: 20, height: "100%" }}>
                        <PiChartCardData
                            data={[
                                { name: "Returning Users", value: returningUserCount },
                                { name: "New Users", value: newUserCount },
                            ]}
                            colors={["#50E8B1", "#FAC748"]}
                            title="Returning vs New Users"
                            totalCount={returningUserCount}
                            trend={newUserTrend}
                        ></PiChartCardData>
                    </div>
                </Col>
            </Row>
        );
    };

    const stylingFunction = ({
        countryValue,
        minValue,
        maxValue,
        country,
        color,
    }) => {
        return {
            fill: countryValue ? "#61CEF7" : "white",
            fillOpacity: 0.5,
            stroke: "black",
            strokeWidth: 1,
        };
    };

    const _renderAIMServerCalls = () => {
        return <div
            style={{
                paddingLeft: 90,
                paddingRight: 90,
                paddingTop: 20,
                marginBottom: 320,
                position: "relative",
            }}
        >
            <AIMAnalyticsMaps
                endDate={aimMapEndDate}
                startDate={aimMapStartDate}
                setEndDate={setAIMMapEndDate}
                setStartDate={setAIMMapStartDate}
                appIDs={appIDs}
                setAppIDs={setAppIDs}
            />
        </div>
    }

    const _renderRegionWiseConsentMap = () => {
        let _data = [];
        if (regionConsent.length > 0) {
            regionConsent?.map((regionItem) => {
                _data.push({
                    country: regionItem?.country?.toLowerCase(),
                    value: regionItem.logCount + " (" + regionItem.percentCount + " %)",
                });
            });
        }
        return (
            <div className="map-parent-container">
                <div className="map-main-container">
                    <WorldMap
                        color="white"
                        styleFunction={stylingFunction}
                        size={620}
                        richInteraction={false}
                        backgroundColor={"#000000"}
                        data={_data}
                    />
                </div>
            </div>
        );
    };

    function getNewReturningUserPercentage() {
        if (
            newUserCount !== undefined &&
            newUserCount !== null &&
            returningUserCount !== undefined &&
            returningUserCount !== null
        ) {
            if (newUserCount === 0 && returningUserCount === 0) {
                return {
                    newUserPercentage: "0%",
                    returningUserPercentage: "0%",
                };
            }
            const total = newUserCount + returningUserCount;
            const newUserPercentage = Math.floor((newUserCount * 100) / total);
            const returningUserPercentage = Math.ceil(
                (returningUserCount * 100) / total
            );
            return {
                newUserPercentage: `${newUserPercentage}%`,
                returningUserPercentage: `${returningUserPercentage}%`,
            };
        }
        return {
            newUserPercentage: "0%",
            returningUserPercentage: "0%",
        };
    }

    function getEmailVerifiedRejectedPercentage() {
        if (
            acceptEmailCount !== undefined &&
            acceptEmailCount !== null &&
            rejectEmailCount !== undefined &&
            rejectEmailCount !== null
        ) {
            if (acceptEmailCount === 0 && rejectEmailCount === 0) {
                return {
                    acceptedEmailPercentage: `0%`,
                    rejectedEmailPercentage: `0%`,
                };
            }
            const total = acceptEmailCount + rejectEmailCount;
            const acceptedEmailPercentage = Math.floor(
                (acceptEmailCount * 100) / total
            );
            const rejectedEmailPercentage = Math.ceil(
                (rejectEmailCount * 100) / total
            );
            return {
                acceptedEmailPercentage: `${acceptedEmailPercentage}%`,
                rejectedEmailPercentage: `${rejectedEmailPercentage}%`,
            };
        }
        return {
            acceptedEmailPercentage: `0%`,
            rejectedEmailPercentage: `0%`,
        };
    }

    return (
        <>
            <SideDrawer>
                {/* {renderHeading()} */}
                {/* {_renderRegionWiseConsentMap()} */}
                {_renderAIMServerCalls()}
                <div
                    style={{
                        marginLeft: 90,
                        marginRight: 90,
                        marginBottom: 24,
                    }}
                >
                    <IdentityProviderStatsAuthStrip
                        startDate={userAuthDataStartDate}
                        endDate={userAuthDataEndDate}
                        onChangeEndDate={setUserAuthDataEndDate}
                        emailCount={emailCount}
                        ssoCount={ssoUserCount}
                        useDatePickers
                        supportBothDatePicker
                        utmCount={utmUserCount}
                        allData={userAuthenticationData}
                        onChangeStartDate={setUserAuthDataStartDate}
                        setAuthDataAppIDs={setAuthDataAppIDs}
                        authDataAppIDs={authDataAppIDs}
                    />
                </div>
                <div
                    style={{
                        marginLeft: 90,
                        marginRight: 90,
                        marginBottom: 40,
                    }}
                >
                    <IdentityLockrPercentageComparison
                        title={"NEW VS RETURNING USERS"}
                        leftColor={"#2E70D2"}
                        rightColor={"#50E8B1"}
                        leftPercentage={getNewReturningUserPercentage().newUserPercentage}
                        rightPercentage={
                            getNewReturningUserPercentage().returningUserPercentage
                        }
                        leftTitle={"New"}
                        rightTitle={"Returning"}
                    />
                    <div
                        style={{
                            marginTop: 24,
                        }}
                    />
                    <IdentityLockrPercentageComparison
                        title={"EMAIL VERIFICATION"}
                        leftColor={"#FAC748"}
                        rightColor={"#F46666"}
                        disabled={!isEmailVerificationEnabled}
                        disabledTitle={'EMAIL VERIFICATION DISABLED'}
                        disabledButtonText="Enable Now"
                        onDisabledButtonPress={() => {
                            props.history.push('/console/apiandservices');
                        }}
                        leftPercentage={
                            getEmailVerifiedRejectedPercentage().acceptedEmailPercentage
                        }
                        rightPercentage={
                            getEmailVerifiedRejectedPercentage().rejectedEmailPercentage
                        }
                        leftTitle={"Verified"}
                        rightTitle={"Rejected"}
                        actionTitle={"View recently rejected emails"}
                        onActionTitleClick={() => {
                            setRejectEmailModal(true);
                        }}
                    />
                </div>
                {/* {renderPieChartCards()} */}
                {/* {renderTables()} */}
                {/* {renderLineChart()} */}
                <div style={{
                    marginTop: 60,
                    paddingLeft: 90,
                    paddingRight: 90,
                    marginBottom: 60,
                }}>
                    <IdentityProviderGraphStrip
                        graphData={userAuthenticationGraph}
                        endDate={loginTrendsEndDate}
                        startDate={loginTrendsStartDate}
                        title={'Login Trends'}
                        supportCustomDatePicker={true}
                        supportComparePrevious
                        historyData={userAuthenticationHistoryGraph}
                        onChangeEndDate={setLoginTrendsEndDate}
                        onChangeStartDate={setLoginTrendsStartDate}
                        setLoginTrendsAppIDs={setLoginTrendsAppIDs}
                        loginTrendsAppIDs={loginTrendsAppIDs}
                    />
                </div>
                {/* <LockrMailUserPreferences /> */}
            </SideDrawer>
            <Modal
                transitionName=""
                maskTransitionName=""
                centered
                width={716}
                bodyStyle={{
                    height: 666,
                    width: 716,
                    backgroundColor: "#353738",
                    paddingLeft: 60,
                    paddingTop: 40,
                    overflowX: "hidden",
                    paddingRight: 0,
                }}
                maskStyle={{ backdropFilter: "blur(8px)" }}
                onOk={() => {
                    setRejectEmailModal(false);
                }}
                onCancel={() => {
                    setRejectEmailModal(false);
                }}
                footer={null}
                closable={false}
            // closeIcon={<img src={require('../assets/modal-close.png')}></img>}
            >
                <div>
                    {" "}
                    <Typography.Title
                        style={{
                            display: "inline-block",
                            fontFamily: "Montserrat",
                            color: "#fff",
                            fontWeight: 700,
                            fontSize: 44,
                        }}
                    >
                        Rejected emails
                    </Typography.Title>
                    <img
                        onClick={() => {
                            setRejectEmailModal(false);
                        }}
                        style={{ marginLeft: 228 }}
                        src={require("../../assets/modal-close.png")}
                        className="view-all-modal-x-button"
                    ></img>
                </div>
                <div style={{ marginTop: 32, marginRight: 69 }}>
                    {rejectedEmailList?.map((email) => (
                        <div
                            style={{
                                borderBottom: "1px solid #8B8B8B",
                                paddingBottom: 16,
                                marginTop: 16,
                                color: "white",
                                fontSize: 16,
                                fontFamily: "Montserrat",
                            }}
                        >
                            {email.lockrEmail}
                        </div>
                    ))}
                </div>
            </Modal>
            {rejectEmailModal && <RejectedEmailsModal
                visible={rejectEmailModal}
                onClose={() => {
                    setRejectEmailModal(false);
                }}
                startDate={userAuthDataStartDate}
                endDate={userAuthDataEndDate}
            />}
        </>
    );
}

export default withRouter(Analytics);
