import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Modal, Row, Typography } from 'antd';
import { TitleText } from './NewAppModal';
import { TitleTextPassword } from './TitleTextPassword';

export function CreateInfosumConnectionModal({
    display,
    onSave,
    onCancel,
    defaultConnectionDetails,
    isDuplicate,
    s3URL,
    s3AccessKey,
    secretKey,
    region,
    isEdit,
    error,
    emailColumnName,
    dimensionColumnName,
    timestampColumnName,
    removeError,
    batch
}) {
    const [connectionDetails, setConnectionDetails] = useState(defaultConnectionDetails ? { ...defaultConnectionDetails } : {
        name: '',
        campaignId: '',
        destinationFileName: '',
        region,
        secretKey,
        s3AccessKey,
        emailColumnName,
        dimensionColumnName,
        timestampColumnName,
        s3URL,
        emailHashColumnName: ''
    });

    function checkValidation() {
        let validation = true;
        if (batch && !connectionDetails.name) {
            validation = false;
        }
        if (!connectionDetails.campaignId) {
            validation = false;
        }
        if (!connectionDetails.destinationFileName) {
            validation = false;
        }
        if (!connectionDetails.emailColumnName) {
            validation = false;
        }

        //check if the mode is duplicate and edit and there is some change in the filename of the connection.
        if (isDuplicate || isEdit) {
            if (defaultConnectionDetails?.destinationFileName === connectionDetails?.destinationFileName) {
                validation = false;
            }
        }

        return validation;
    }

    return (
        <Modal
            visible={display}
            style={{ top: 20, paddingBottom: 20, }}
            centered
            width={776}
            footer={null}
            closable={false}
            destroyOnClose
            bodyStyle={{ minHeight: 638, width: 776, backgroundColor: '#353738', paddingLeft: 60, paddingTop: 40, paddingRight: 0, overflowX: 'hidden' }}
        >
            <img alt={'Close'} onClick={onCancel} src={require('../assets/modal-close.png')} className='delete-x-button-connection' />
            <div>
                <Typography.Title style={{ display: 'inline-block', fontFamily: 'Montserrat', color: '#fff', fontWeight: 700, fontSize: 20, paddingBottom: 40, paddingTop: 8 }}>{isEdit ? 'Edit Analysis' : isDuplicate ? `Duplicate Analysis` : `New Analysis`}</Typography.Title>
            </div>
            <div>
                {batch &&
                    <>
                        <TitleText
                            placeholder={" "}
                            label={"Name"}
                            marginLeft={242}
                            supportCopy
                            value={connectionDetails.name}
                            onChange={(event) => {
                                setConnectionDetails({
                                    ...connectionDetails, name: event.target.value
                                })
                                removeError?.()
                            }}
                        />
                        <div style={{ marginTop: 24 }} />
                    </>}
                <TitleText
                    placeholder={" "}
                    label={"S3 bucket URL"}
                    marginLeft={173}
                    disabled
                    supportCopy
                    value={connectionDetails.s3URL}
                />
                <div style={{ marginTop: 24 }} />
                <TitleText
                    placeholder={" "}
                    label={"Access Key"}
                    marginLeft={203}
                    disabled
                    supportCopy
                    value={connectionDetails.s3AccessKey}
                />
                <div style={{ marginTop: 24 }} />
                <TitleTextPassword
                    placeholder={" "}
                    label={"Secret Key"}
                    marginLeft={206}
                    disabled
                    supportCopy
                    value={connectionDetails.secretKey}
                />
                <div style={{ marginTop: 24 }} />
                <TitleText
                    placeholder={" "}
                    label={"Region"}
                    marginLeft={233}
                    disabled
                    supportCopy
                    value={connectionDetails.region}
                />
                <div style={{ marginTop: 24 }} />
                <TitleText
                    placeholder={" "}
                    label={"Destination filename"}
                    marginLeft={123}
                    value={connectionDetails.destinationFileName}
                    onChange={(event) => {
                        setConnectionDetails({
                            ...connectionDetails, destinationFileName: event.target.value
                        })
                        removeError?.()
                    }}
                />
                <div style={{ marginTop: 24 }} />
                <TitleText
                    placeholder={" "}
                    label={"Email column name"}
                    marginLeft={127}
                    value={connectionDetails.emailColumnName}
                    onChange={(event) => {
                        setConnectionDetails({
                            ...connectionDetails, emailColumnName: event.target.value
                        })
                        removeError?.()
                    }}
                />
                <div style={{ marginTop: 24 }} />
                {batch &&
                    <>
                        <TitleText
                            placeholder={" "}
                            label={"Email Hash Column Name"}
                            marginLeft={76}
                            value={connectionDetails.emailHashColumnName}
                            onChange={event => {
                                setConnectionDetails({
                                    ...connectionDetails, emailHashColumnName: event.target.value,
                                })
                            }}
                        />
                    </>}
                {!batch &&
                    <>
                        <TitleText
                            placeholder={" "}
                            label={"TimeStamp column name"}
                            marginLeft={78}
                            value={connectionDetails.timestampColumnName}
                            onChange={(event) => {
                                setConnectionDetails({
                                    ...connectionDetails, timestampColumnName: event.target.value
                                })
                                removeError?.()
                            }}
                        />
                        <div style={{ marginTop: 24 }} />
                        <TitleText
                            placeholder={" "}
                            label={"Dimension column name"}
                            marginLeft={84}
                            value={connectionDetails.dimensionColumnName}
                            onChange={(event) => {
                                setConnectionDetails({
                                    ...connectionDetails, dimensionColumnName: event.target.value
                                })
                                removeError?.()
                            }}
                        />
                    </>
                }
                <div style={{ marginTop: 24 }} />
                <TitleText
                    placeholder={" "}
                    label={"Infosum External ID"}
                    marginLeft={130}
                    value={connectionDetails.campaignId}
                    onChange={(event) => {
                        setConnectionDetails({
                            ...connectionDetails, campaignId: event.target.value
                        })
                        removeError?.();
                    }}
                />
            </div>
            <Row style={{ marginTop: 50, display: 'flex', flexDirection: 'column' }}>
                <div style={{ marginLeft: 0, marginBottom: 8 }}>
                    <label className='errorText'>{error}</label>
                </div>
                <Button
                    disabled={!checkValidation()}
                    type={"primary"}
                    style={{ width: 200 }}
                    loading={false}
                    onClick={() => {
                        onSave(connectionDetails);
                    }}
                    className='sign-in'
                >{"Confirm"}</Button>
            </Row>
        </Modal>
    )
}