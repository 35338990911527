import React from "react";

export function IdentityLockrPercentageComparison({
  leftPercentage,
  rightPercentage,
  leftColor,
  rightColor,
  title,
  leftTitle,
  rightTitle,
  actionTitle,
  onActionTitleClick = () => { },
  disabled = false,
  disabledTitle,
  disabledButtonText = '',
  onDisabledButtonPress = () => {}
}) {
  return (
    <div style={{
      position: 'relative'
    }}>
      <div
        style={{
          width: "100%",
          background: "black",
          filter: disabled ? 'blur(5px)' : 'blur(0px)',
          height: 160,
          paddingLeft: 30,
          paddingTop: 32,
          paddingRight: 30,
          paddingBottom: 32,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <span
            style={{
              color: "white",
              fontFamily: "Montserrat",
              fontSize: 16,
              fontWeight: "400",
            }}
          >
            {title}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: 10,
                width: 10,
                background: leftColor,
                borderRadius: 20,
                marginLeft: 24,
                marginRight: 6,
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                fontSize: 16,
                fontWeight: "700",
              }}
            >
              {leftTitle}
            </span>
            <div
              style={{
                height: 10,
                width: 10,
                background: rightColor,
                borderRadius: 20,
                marginLeft: 24,
                marginRight: 6,
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                fontSize: 16,
                fontWeight: "700",
              }}
            >
              {rightTitle}
            </span>
            {!!actionTitle && (
              <div
                onClick={() => {
                  onActionTitleClick();
                }}
                style={{
                  color: "white",
                  marginLeft: 24,
                  paddingLeft: 16,
                  paddingTop: 12,
                  paddingBottom: 12,
                  paddingRight: 16,
                  fontWeight: "700",
                  background: "#26282A",
                  fontFamily: "Montserrat",
                  cursor: "pointer",
                }}
              >
                {actionTitle}
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: 18,
            gap: 10,
          }}
        >
          {leftPercentage == "0%" && rightPercentage == "0%" ? <div style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
              <span style={{ color: "white" }}>There is no data available for the selected date range.</span>
            </div> : <>
              <div
                style={{
                  height: 6,
                  width: leftPercentage,
                  maxWidth: `calc(100% - 100px)`,
                  background: leftColor,
                  position: "relative",
                  borderRadius: 100,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    right: -48,
                    top: -12,
                    height: 30,
                    width: 50,
                    background: leftColor,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 6,
                  }}
                >
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      fontSize: 16,
                      fontWeight: "700",
                    }}
                  >
                    {leftPercentage}
                  </span>
                </div>
              </div>
              <div
                style={{
                  height: 6,
                  marginLeft: 48,
                  width: `calc(${rightPercentage} - 48px)`,
                  background: rightColor,
                  borderRadius: 100,
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    top: -12,
                    height: 30,
                    width: 50,
                    background: rightColor,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 6,
                  }}
                >
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      fontSize: 16,
                      fontWeight: "700",
                    }}
                  >
                    {rightPercentage}
                  </span>
                </div>
              </div>
          </>}
        </div>
      </div>
      {disabled && <div style={{
        position: 'absolute',
        width: '100%',
        zIndex: 1000,
        height: '100%',
        background: '#31313422',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row'
      }}>
        <span style={{
          color: 'white',
          fontFamily: 'Montserrat',
          fontSize: 16,
          fontWeight: '400'
        }}>{disabledTitle}</span>
        <div onClick={onDisabledButtonPress} style={{
          marginLeft: 16,
          backgroundColor: '#313134',
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 8,
          cursor: 'pointer',
          paddingBottom: 8,
        }}>
          <span onClick={onDisabledButtonPress} style={{
            fontWeight: '700',
            fontSize: 14,
            fontStyle: 'normal',
            fontFamily: 'Montserrat',
            color: 'white',
            cursor: 'pointer',
          }}>{disabledButtonText}</span>
        </div>
      </div>}
    </div>
  );
}
