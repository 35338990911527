import { ConfigProvider, DatePicker, Typography, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import locale from "antd/lib/date-picker/locale/en_US";
import moment from "moment";
import React from "react";
import { AiOutlineDown } from "react-icons/ai";
import { DATE_OPTIONS, IdentityProviderNonDatePicker } from "./identityProviderNonDatePicker";
import { AppSelectionDropdown } from "./AppSelectionDropdown";

export function IdentityProviderlockrNMailStrip({
  totalUsers,
  avgEmails,
  consentedPercentage,
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  lockrMailAppIDs,
  setLockrMailAppIDs
}) {
  const dateFormat = "dddd MM/DD";

  const customFormat = (value) => `${value.format(dateFormat)}`;

  function renderDateFilters() {
    return (
      <div>
        <ConfigProvider locale={locale}>
          <DatePicker
            disabledDate={(date) => moment(date) > moment()}
            value={moment(startDate)}
            onChange={(e) => {
              onChangeStartDate(moment(e));
            }}
            format={customFormat}
            className="dashboard-date-picker"
            dropdownClassName="calender-date-picker"
            popupStyle={{ background: "#171717" }}
            suffixIcon={<AiOutlineDown color="#fff"></AiOutlineDown>}
            allowClear={false}
          ></DatePicker>
          <Typography.Text className="datepicker-btw-to">to</Typography.Text>
          <DatePicker
            disabledDate={(date) =>
              moment(date) > moment() || moment(startDate) > moment(date)
            }
            value={moment(endDate)}
            onChange={(e) => {
              onChangeEndDate(moment(e));
            }}
            format={customFormat}
            className="dashboard-date-picker"
            dropdownClassName="calender-date-picker"
            suffixIcon={<AiOutlineDown color="#fff"></AiOutlineDown>}
            allowClear={false}
          ></DatePicker>
        </ConfigProvider>
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              color: "white",
              fontSize: 24,
              fontWeight: "700",
              fontFamily: "Montserrat",
            }}
          >
            lockrMail
          </span>
          <span
            style={{
              color: "#8B8B8B",
              fontSize: 14,
              fontWeight: "400",
              fontFamily: "Montserrat",
            }}
          >
            Last updated {moment().format("hh:mm A")}
          </span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AppSelectionDropdown value={lockrMailAppIDs} onChange={appIDs => { setLockrMailAppIDs(appIDs) }} />
          <IdentityProviderNonDatePicker
            onOptionSelected={(dateOption) => {
              switch (dateOption) {
                case DATE_OPTIONS.LAST_12_MONTHS:
                  onChangeStartDate(moment().subtract(1, "year"));
                  onChangeEndDate(moment());
                  break;
                case DATE_OPTIONS.LAST_24_HOURS:
                  onChangeStartDate(moment().subtract(1, "day"));
                  onChangeEndDate(moment());
                  break;
                case DATE_OPTIONS.LAST_30_DAYS:
                  onChangeStartDate(moment().subtract(1, "month"));
                  onChangeEndDate(moment());
                  break;
                case DATE_OPTIONS.LAST_7_DAYS:
                  onChangeStartDate(moment().subtract(7, "days"));
                  onChangeEndDate(moment());
                  break;
                case DATE_OPTIONS.LAST_90_DAYS:
                  onChangeStartDate(moment().subtract(3, "months"));
                  onChangeEndDate(moment());
                  break;
                default:
                  break;
              }
            }}
          />
        </div>
      </div>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          gap: 10,
          paddingLeft: 10,
          paddingRight: 10,
          marginTop: 32,
          width: "100%",
          background: "#1D1D1D",
          height: "150px",
          boxShadow: "8px 8px 0px 0px #000",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            height: 127,
            borderRadius: 2,
            background: "black",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <span
            style={{
              color: "#61CEF7",
              fontSize: 40,
              fontWeight: "400",
              fontFamily: "Montserrat",
            }}
          >
            {totalUsers}
          </span>
          <span
            style={{
              color: "#8B8B8B",
              fontSize: 14,
              fontWeight: "400",
              marginTop: -4,
              fontFamily: "Montserrat",
            }}
          >
            Total lockrMail users
          </span>
          <Tooltip
            title={"Unique count of lockrMail users who have authenticated on the domain."}
            overlayInnerStyle={{
              padding: 16,
            }}
          >
            <InfoCircleOutlined 
              style={{
                position: "absolute",
                top: 15,
                right: 15,
                zIndex: 200,
                color: "grey",
                fontSize: 16,
                fontWeight: "400",
              }}
            />
          </Tooltip>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            height: 127,
            borderRadius: 2,
            background: "black",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <span
            style={{
              color: "#61CEF7",
              fontSize: 40,
              fontWeight: "400",
              fontFamily: "Montserrat",
            }}
          >
            {avgEmails}
          </span>
          <span
            style={{
              color: "#8B8B8B",
              fontSize: 14,
              fontWeight: "400",
              marginTop: -4,
              fontFamily: "Montserrat",
            }}
          >
            Avg. destination emails / lockrMail
          </span>
          <Tooltip
            title={"Average number of destination email addresses connected to each lockrMail account."}
            overlayInnerStyle={{
              padding: 16,
            }}
          >
            <InfoCircleOutlined 
              style={{
                position: "absolute",
                top: 15,
                right: 15,
                zIndex: 200,
                color: "grey",
                fontSize: 16,
                fontWeight: "400",
              }}
            />
          </Tooltip>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            height: 127,
            borderRadius: 2,
            background: "black",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <span
            style={{
              color: "#61CEF7",
              fontSize: 40,
              fontWeight: "400",
              fontFamily: "Montserrat",
            }}
          >
            {`${consentedPercentage}%`}
          </span>
          <span
            style={{
              color: "#8B8B8B",
              fontSize: 14,
              fontWeight: "400",
              marginTop: -4,
              fontFamily: "Montserrat",
            }}
          >
            Consent
          </span>
        </div>
      </div>
    </>
  );
}
