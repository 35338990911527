import { Tooltip, Typography, Switch } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import React from "react";

export function MacroDetectionSettings({
  isMacroDetectionSelected,
  setIsMacroDetectionSelected,
  isIuiMacroDetectionSelected,
  setIsIluiMacroDetectionSelected
}) {
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography.Text
          style={{ fontSize: 16, fontWeight: "400", marginBottom: 4 }}
        >
          Macro Detection
        </Typography.Text>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 180,
            maxWidth: 300,
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: 'center'
            }}
          >
            <Typography.Text
              style={{
                fontSize: 16,
                fontWeight: "400",
                marginBottom: 4,
              }}
            >
              Raw Email
              <Tooltip
                title={
                  "If a UTM Tag is not defined, a publisher can choose to have AIM detect any raw email addresses which are included as a macro in the URL string. No need to define the macro, our mechanism will detect a full email address and trigger AIM as long as no consent flags are seen on the page/browser."
                }
              >
                <InfoCircleOutlined
                  style={{
                    marginLeft: 16,
                  }}
                />
              </Tooltip>
            </Typography.Text>
            <Switch
              style={{ marginLeft: 40 }}
              checked={isMacroDetectionSelected}
              onChange={(checked) => {
                setIsMacroDetectionSelected(checked);
              }}
            ></Switch>
          </div>
          <Typography.Text
            style={{
              maxWidth: 300,
              fontSize: 12,
              fontWeight: "400",
              marginBottom: 4,
              color: "#bab8b8",
            }}
            className="sub-text"
          >
            {isMacroDetectionSelected
              ? "Auto-detection will check all macros in the URL for any raw emails that might have been added to campaigns."
              : "Auto-detection is restricted and will not cull raw emails from the URL only those defined by the UTM parameters."}
          </Typography.Text>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: 'center',
              marginTop: 20
            }}
          >
            <Typography.Text
              style={{
                fontSize: 16,
                fontWeight: "400",
                marginBottom: 4,
              }}
            >
              lockrMail
              <Tooltip
                title={
                  "lockr can detect any lockrMail user's click-through event to your domain in an encrypted UTM parameter added during a re-direction. No need to define the macro, our mechanism will detect and trigger AIM as long as no consent flags are seen on the page/browser."
                }
              >
                <InfoCircleOutlined
                  style={{
                    marginLeft: 16,
                  }}
                />
              </Tooltip>
            </Typography.Text>
            <Switch
              style={{ marginLeft: 40 }}
              checked={isIuiMacroDetectionSelected}
              onChange={(checked) => {
                setIsIluiMacroDetectionSelected(checked);
              }}
            ></Switch>
          </div>
          <Typography.Text
            style={{
              maxWidth: 300,
              fontSize: 12,
              fontWeight: "400",
              marginBottom: 4,
              color: "#bab8b8",
            }}
            className="sub-text"
          >
            Automatically identify lockrMail users' click-throughs without adjusting UTM settings in your ESP
          </Typography.Text>
        </div>
      </div>
    </div>
  );
}
