import { Dropdown } from "antd"
import { AiOutlineCheck, AiOutlineDown, AiOutlinePlus } from "react-icons/ai"
import deleteIcon from '../../../../assets/remove_icon.png'
import { attributesNotToIncudeInDropdown, conditionDropdown, uuidConditionDropdown } from "../CSVDataEndpoint"



export function CustomConditionsBuilder({ selectedCohort = { configuration: { appliedFilters: { allCondition: 'AND', filters: [] }, selectedAttributes: [], name: "", } }, editEnabled = false, attributes = [], updateSelectedCohortFilters = () => { }, updateSelectedCohortConditions = () => { } }) {
    return <>
        {selectedCohort?.configuration?.appliedFilters?.filters.length > 0 && <Dropdown disabled={!editEnabled} trigger={["click"]} overlay={
            <div style={{ background: 'black', color: 'white', width: 20, cursor: 'pointer', }}>
                <div onClick={() => { updateSelectedCohortConditions({ ...selectedCohort?.configuration?.appliedFilters, allCondition: "AND" }) }} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: 8, padding: 8 }}><AiOutlineCheck color={selectedCohort?.configuration?.appliedFilters?.allCondition === 'AND' ? '#61CEF7' : 'transparent'} />All are true</div>
                <div onClick={() => { updateSelectedCohortConditions({ ...selectedCohort?.configuration?.appliedFilters, allCondition: "OR" }) }} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: 8, padding: 8 }}><AiOutlineCheck color={selectedCohort?.configuration?.appliedFilters?.allCondition === 'OR' ? '#61CEF7' : 'transparent'} />Any one is true</div>
            </div>}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 8, cursor: 'pointer', color: 'white', marginTop: 24, marginLeft: 8 }}>{selectedCohort?.configuration?.appliedFilters?.allCondition === 'AND' ? "All are true" : "Any one is true"}
                {editEnabled && <AiOutlineDown
                    style={{
                        cursor: "pointer",
                        color: "white",

                        right: -220,
                        top: 18,
                    }}
                />}
            </div>

        </Dropdown>}
        <div style={{ marginTop: 8 }} >

            {selectedCohort?.configuration?.appliedFilters?.filters.map((filter, index) => (
                <div style={{ display: 'flex', padding: 8, gap: 8, alignItems: 'center' }}>
                    {/* SELECT FOR ATTRIBUTE SELECT */}
                    <Dropdown disabled={!(editEnabled)} trigger={["click"]} overlay={<div style={{ background: 'black', padding: 8, width: 220 }}>
                        {attributes.filter(a => !attributesNotToIncudeInDropdown.includes(a.key))?.map(cd => (
                            <div onClick={() => {
                                let tempFilters = []
                                let tempCohorts = JSON.parse(JSON.stringify(selectedCohort))
                                tempFilters = tempCohorts?.configuration?.appliedFilters?.filters
                                tempFilters[index] = { ...tempFilters[index], attr: cd.key, type: cd.type }
                                updateSelectedCohortFilters({ ...selectedCohort?.configuration?.appliedFilters, filters: tempFilters })
                            }} style={{ color: 'white', padding: 4 }}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}> <AiOutlineCheck color={cd.key === filter.attr ? "#61CEF7" : "transparent"} />{cd.label}</div>
                            </div>
                        ))}
                    </div>}>
                        <div style={{ position: 'relative' }}>
                            <input style={{ width: 220, minWidth: 100, cursor: 'pointer', color: 'white' }} placeholder="Select Attribute" readOnly className="lockr-data-endpoint-input" value={attributes?.find(a => a.key === filter.attr)?.label}></input>
                            <div style={{ position: 'absolute', top: 20, right: 10 }}>
                                {editEnabled && <AiOutlineDown />}
                            </div>
                        </div>

                    </Dropdown>

                    {/* SELECT FOR CONDITION SELECT */}
                    <Dropdown disabled={!(editEnabled)} trigger={["click"]} overlay={
                        <div style={{ background: 'black', padding: 8, width: 170 }}>
                            {attributes.find(a => a.key === filter.attr)?.type === "uuid" ? uuidConditionDropdown.map(cd => (
                                <div onClick={() => {
                                    let tempFilters = []
                                    let tempCohorts = JSON.parse(JSON.stringify(selectedCohort))
                                    tempFilters = tempCohorts?.configuration?.appliedFilters?.filters
                                    tempFilters[index] = { ...tempFilters[index], conditionKey: cd.key }
                                    updateSelectedCohortFilters({ ...selectedCohort?.configuration?.appliedFilters, filters: tempFilters })
                                }} style={{ color: 'white', padding: 4 }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}> <AiOutlineCheck color={cd.key === filter.conditionKey ? "#61CEF7" : "transparent"} />{cd.label}</div>
                                </div>
                            )) :
                                conditionDropdown.map(cd => (
                                    <div onClick={() => {
                                        let tempFilters = []
                                        let tempCohorts = JSON.parse(JSON.stringify(selectedCohort))
                                        tempFilters = tempCohorts?.configuration?.appliedFilters?.filters
                                        tempFilters[index] = { ...tempFilters[index], conditionKey: cd.key }
                                        updateSelectedCohortFilters({ ...selectedCohort?.configuration?.appliedFilters, filters: tempFilters })
                                    }} style={{ color: 'white', padding: 4 }}>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}> <AiOutlineCheck color={cd.key === filter.conditionKey ? "#61CEF7" : "transparent"} />{cd.label}</div>
                                    </div>
                                ))}
                        </div>
                    }>
                        <div style={{ position: 'relative' }}>
                            <input style={{ minWidth: 60, cursor: 'pointer', color: 'white' }} placeholder="Select Condition" readOnly className="lockr-data-endpoint-input" value={conditionDropdown.find(cd => cd.key === filter.conditionKey)?.label}></input>
                            <div style={{ position: 'absolute', top: 20, right: 10 }}>
                                {editEnabled && <AiOutlineDown />}
                            </div>
                        </div>

                    </Dropdown>

                    {/* If IS NULL ANOTHER DROPDOWN  ELSE AN INPUT FOR CONDITON */}

                    {filter.conditionKey && (filter.conditionKey === 'isNull' ?
                        <Dropdown disabled={!(editEnabled)} trigger={["click"]} overlay={<div style={{ background: 'black', padding: 8, width: 170 }}>
                            <div onClick={() => {
                                let tempFilters = []
                                let tempCohorts = JSON.parse(JSON.stringify(selectedCohort))
                                tempFilters = tempCohorts?.configuration?.appliedFilters?.filters
                                tempFilters[index].value = true
                                updateSelectedCohortFilters({ ...selectedCohort?.configuration?.appliedFilters, filters: tempFilters })
                            }} style={{ color: 'white', padding: 4 }}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}> <AiOutlineCheck color={filter.value ? "#61CEF7" : "transparent"} />{"True"}</div>
                            </div>
                            <div onClick={() => {
                                let tempFilters = []
                                let tempCohorts = JSON.parse(JSON.stringify(selectedCohort))
                                tempFilters = tempCohorts?.configuration?.appliedFilters?.filters
                                tempFilters[index].value = false
                                updateSelectedCohortFilters({ ...selectedCohort?.configuration?.appliedFilters, filters: tempFilters })
                            }} style={{ color: 'white', padding: 4 }}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}> <AiOutlineCheck color={(filter.value === false) ? "#61CEF7" : "transparent"} />{"False"}</div>
                            </div>
                        </div>}>
                            <div style={{ position: 'relative' }}>
                                <input value={filter.value === "" ? "" : ((filter.value === false) ? "False" : "True")} style={{ minWidth: 60, cursor: 'pointer', color: 'white' }} placeholder="Select Value" readOnly className="lockr-data-endpoint-input"></input>
                                <div style={{ position: 'absolute', top: 20, right: 10 }}>
                                    {editEnabled && <AiOutlineDown />}
                                </div>
                            </div>

                        </Dropdown> : <input disabled={!(editEnabled)} value={filter.value} onChange={(e) => {
                            let tempFilters = []
                            let tempCohorts = JSON.parse(JSON.stringify(selectedCohort))
                            tempFilters = tempCohorts?.configuration?.appliedFilters?.filters
                            tempFilters[index].value = e.target.value;
                            updateSelectedCohortFilters({ ...selectedCohort?.configuration?.appliedFilters, filters: tempFilters })

                        }} placeholder={conditionDropdown.find(c => c.key === filter.conditionKey)?.label} style={{ background: 'black', border: '1px solid #8B8B8B', marginTop: 4, padding: 8, height: 48, color: 'white' }} />
                    )}

                    {/* Delete Icon */}
                    <div>
                        {(editEnabled) && < img src={deleteIcon} alt="delete" onClick={() => {
                            if (editEnabled) {
                                let tempFilters = []
                                let tempCohorts = JSON.parse(JSON.stringify(selectedCohort))
                                tempFilters = tempCohorts?.configuration?.appliedFilters?.filters
                                tempFilters.splice(index, 1)
                                updateSelectedCohortFilters({ ...selectedCohort?.configuration?.appliedFilters, filters: tempFilters })
                            }
                        }} cursor={'pointer'} style={{ height: 24, width: 21.6 }} />}
                    </div>
                </div>
            ))}
            {editEnabled && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16, marginTop: 16 }}>
                {(editEnabled) ? <div onClick={() => {
                    if (editEnabled) {
                        updateSelectedCohortFilters({
                            ...selectedCohort?.configuration?.appliedFilters, filters: [...selectedCohort?.configuration?.appliedFilters?.filters, {
                                attr: "",
                                conditionKey: "",
                                value: ""
                            }]
                        })
                    }
                }} style={{ display: 'flex', alignItems: 'center', fontSize: 14, fontWeight: 'bold', padding: 4, gap: 8, cursor: 'pointer', width: 'max-content' }}> <AiOutlinePlus /> Add Filter </div> : <>&nbsp;</>}
            </div>}
        </div>
    </>
}