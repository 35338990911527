import React, { useState } from 'react';
import { Button, Modal, Row, Typography } from 'antd';
import { TitleText } from './NewAppModal';

export function CreateAwsModal({
    display,
    onSave,
    onCancel,
    isDuplicate,
    defaultConnectionDetails,
    isEdit,
    error,
    batch
}) {
    const [connectionDetails, setConnectionDetails] = useState(defaultConnectionDetails ?? {
        name: '',
        s3URL: '',
        accessKey: '',
        secretKey: '',
        region: '',
        destinationFileName: '',
        emailColumnName: '',
        dimensionColumnName: '',
        timestampColumnName: '',
        emailHashColumnName: ''
    })

    function checkValidation() {
        let validation = true;
        if (connectionDetails?.region?.trim?.() === "" || connectionDetails.s3URL.trim() === "" || connectionDetails.accessKey.trim() === "" || connectionDetails.secretKey.trim() === "" || connectionDetails.destinationFileName.trim() === "" || connectionDetails.emailColumnName.trim() === "") {
            validation = false;
        }
        return validation;
    }

    return (
        <Modal
            visible={display}
            style={{ top: 20, paddingBottom: 20 }}
            centered
            width={776}
            footer={null}
            closable={false}
            destroyOnClose
            bodyStyle={{ minHeight: 638, width: 776, backgroundColor: '#353738', paddingLeft: 60, paddingTop: 40, paddingRight: 0, overflowX: 'hidden' }}
        >
            <img alt={'Close'} onClick={onCancel} src={require('../assets/modal-close.png')} className='delete-x-button-connection' />
            <div>
                <Typography.Title style={{ display: 'inline-block', fontFamily: 'Montserrat', color: '#fff', fontWeight: 700, fontSize: 20, paddingBottom: 40, paddingTop: 8 }}>{isEdit ? 'Edit Analysis' : isDuplicate ? `Duplicate Analysis` : `New Analysis`}</Typography.Title>
            </div>
            <div>
                {batch &&
                    <>
                        <TitleText
                            placeholder={" "}
                            label={"Name"}
                            marginLeft={222}
                            value={connectionDetails.name}
                            onChange={event => {
                                setConnectionDetails({
                                    ...connectionDetails, name: event.target.value,
                                })
                            }}
                        />
                        <div style={{ marginTop: 24 }} />
                    </>}
                <TitleText
                    placeholder={" "}
                    label={"S3 bucket URL"}
                    marginLeft={153}
                    value={connectionDetails.s3URL}
                    onChange={event => {
                        setConnectionDetails({
                            ...connectionDetails, s3URL: event.target.value,
                        })
                    }}
                />
                <div style={{ marginTop: 16 }} />
                <TitleText
                    placeholder={" "}
                    label={"Access Key"}
                    marginLeft={184}
                    value={connectionDetails.accessKey}
                    onChange={event => {
                        setConnectionDetails({
                            ...connectionDetails, accessKey: event.target.value,
                        })
                    }}
                />
                <div style={{ marginTop: 16 }} />
                <TitleText
                    placeholder={" "}
                    label={"Secret Key"}
                    marginLeft={187}
                    value={connectionDetails.secretKey}
                    onChange={event => {
                        setConnectionDetails({
                            ...connectionDetails, secretKey: event.target.value,
                        })
                    }}
                />
                <div style={{ marginTop: 16 }} />
                <TitleText
                    placeholder={" "}
                    label={"Region"}
                    marginLeft={212}
                    value={connectionDetails.region}
                    onChange={event => {
                        setConnectionDetails({
                            ...connectionDetails, region: event.target.value,
                        })
                    }}
                />
                <div style={{ marginTop: 16 }} />
                <TitleText
                    placeholder={" "}
                    label={"Source File"}
                    marginLeft={182}
                    value={connectionDetails.destinationFileName}
                    onChange={event => {
                        setConnectionDetails({
                            ...connectionDetails, destinationFileName: event.target.value,
                        })
                    }}
                />
                <div style={{ marginTop: 16 }} />
                <TitleText
                    placeholder={" "}
                    label={"Email Column Name"}
                    marginLeft={102}
                    value={connectionDetails.emailColumnName}
                    onChange={event => {
                        setConnectionDetails({
                            ...connectionDetails, emailColumnName: event.target.value,
                        })
                    }}
                />
                <div style={{ marginTop: 16 }} />
                {batch &&
                    <>
                        <TitleText
                            placeholder={" "}
                            label={"Email Hash Column Name"}
                            marginLeft={58}
                            value={connectionDetails.emailHashColumnName}
                            onChange={event => {
                                setConnectionDetails({
                                    ...connectionDetails, emailHashColumnName: event.target.value,
                                })
                            }}
                        />
                        <div style={{ marginTop: 16 }} />
                    </>}
                {!batch &&
                    <><TitleText
                        placeholder={" "}
                        label={"TimeStamp Column Name"}
                        marginLeft={55}
                        value={connectionDetails.timestampColumnName}
                        onChange={event => {
                            setConnectionDetails({
                                ...connectionDetails, timestampColumnName: event.target.value,
                            })
                        }}
                    />
                        <div style={{ marginTop: 16 }} />
                        <TitleText
                            placeholder={" "}
                            label={"Dimension Column Name"}
                            marginLeft={60}
                            value={connectionDetails.dimensionColumnName}
                            onChange={event => {
                                setConnectionDetails({
                                    ...connectionDetails, dimensionColumnName: event.target.value,
                                })
                            }}
                        />
                    </>
                }
            </div>
            <Row style={{ marginTop: 50, display: 'flex', flexDirection: 'column' }}>
                <div style={{ marginLeft: 0, marginBottom: 0 }}>
                    <label className='errorText'>{error}</label>
                </div>
                <Button
                    disabled={!checkValidation()}
                    type={"primary"}
                    style={{ width: 200 }}
                    loading={false}
                    onClick={() => {
                        onSave(connectionDetails);
                    }}
                    className='sign-in'
                >{"Confirm"}</Button>
            </Row>
        </Modal>
    )
}