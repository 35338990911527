import { Input } from "antd";
import React, { useState } from "react";
import { TitleText } from "./NewAppModal";

export function TitleTextPassword({
  marginLeft,
  label,
  value = '',
  type,
  disabled,
  supportCopy,
  isPointer,
  onChange,
  placeholder,
  eyeRight,
  hideEye = false,
  supportDisabledColor = false
}) {
  const [displayPassword, setDisplayPassword] = useState(false);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <TitleText
        label={label}
        supportCopy={supportCopy}
        marginLeft={marginLeft}
        value={value}
        supportDisabledColor = {supportDisabledColor}
        customInput={
          <Input
            type={type}
            disabled={disabled}
            value={displayPassword ? value : "*".repeat(value.length)}
            visibilityToggle={false}
            style={{
              paddingRight: supportCopy ? 72 : 8,
              color: "#fff",
              border: "1px solid #8B8B8B",
              cursor: disabled ? "not-allowed" : isPointer ? "pointer" : "auto",
              backgroundColor: supportDisabledColor && disabled ? '#aeaeae !important' : 'transparent'
            }}
            onChange={onChange}
            className={("form-input new-app-modal-input ant-input-40 connection-screen-input ") + (supportDisabledColor && disabled && 'title-text-disabled-style')}
            placeholder={placeholder || " "}
          />
        }
      />
      {!hideEye && (
        <img
          alt="eye-off"
          onClick={() => {
            setDisplayPassword(!displayPassword);
          }}
          src={
            displayPassword
              ? require("../assets/eye_open.png")
              : require("../assets/eye_off.png")
          }
          style={{
            height: 16,
            width: 16,
            cursor: "pointer",
            position: "absolute",
            top: 12,
            right: eyeRight ? eyeRight : supportCopy ? 108 : 90,
          }}
        />
      )}
    </div>
  );
}
