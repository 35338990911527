import { Button, Col, Dropdown, Input, Menu, Modal, Row, Upload, message } from "antd";
import React, { useRef, useState } from "react";

import DirectUpload from "../../src/assets/direct_upload.svg";
import InfoSum from "../../src/assets/infosum_logo_grey.svg";
import LockrLogo from "../../src/assets/lockr_logo_grey.svg";
import LockrDisabledLogo from "../../src/assets/lockr_self_disabled.svg";
import SnowFlakeContActive from "../../src/assets/snowflake_cont_active.svg";

import SnowFlakeCont from "../../src/assets/snowflake_cont.svg";
import SnowFlakeSnap from "../../src/assets/snowflake_snap.svg";
import AWS from "../../src/assets/aws_grey.svg";
import TradeDesk from "../../src/assets/The_Trade_Desk.svg";
import MParticle from "../../src/assets/mparticle_grey.svg";
import ActiveAWS from "../../src/assets/aws_logo.png";
import ActiveInfoSum from "../../src/assets/infosum_logo.png"

import { createBatchData, dataEndpointFullRefresh, getAllConnections, getAllGenericConnections, getS3EndPoints } from "../Utils/ApiActions";
import { AiOutlineCheck, AiOutlineDown } from "react-icons/ai";
import { useEffect } from "react";


const connectionTypes = [
  { label: "Direct upload", key: "Direct", imgSrc: DirectUpload, status: "Active" },
  { label: "Lockr Self serve", key: "lockr", imgSrc: LockrDisabledLogo, activeImg: LockrLogo, status: "Disabled" },
  { label: "Snowflake - Cont", key: "Snowflake-Cont", imgSrc: SnowFlakeCont, status: "Disabled" },
  { label: "Snowflake - Snap", key: "Snowflake-Snap", imgSrc: SnowFlakeSnap, activeImg: SnowFlakeContActive, status: "Active" },
  { label: "Infosum", key: "INFOSUM", imgSrc: InfoSum, activeImg: ActiveInfoSum, status: "Active" },
  { label: "AWS", key: "AWS", imgSrc: AWS, activeImg: ActiveAWS, status: "Active" },
  { label: "The Trade Desk", key: "trade-desk", imgSrc: TradeDesk, status: "Disabled" },
  { label: "Mparticle", key: "Mparticle", imgSrc: MParticle, status: "Disabled" },
];

const dataEndpointTypes = [
  { label: "AWS", key: "AWS", imgSrc: require("../../src/assets/aws_logo.png"), status: "Active" },
  { label: "Snowflake", key: "SNOWFLAKE", imgSrc: require("../../src/assets/snowflake_logo.png"), status: "Active" },
  { label: "Optable", key: "OPTABLE", imgSrc: require("../../src/assets/optable_logo.jpeg"), status: "Active" },
  { label: "GCP", key: "google-cloud-platform", imgSrc: require("../../src/assets/gcp_logo.png"), status: "Disabled" },
  { label: "Azure", key: "azure", imgSrc: require("../../src/assets/azure.png"), status: "Disabled" },
  { label: "BlueConic", key: "blueconic", imgSrc: require("../../src/assets/blueconic_logo.jpeg"), status: "Disabled" },
  { label: "Tealium", key: "tealium", imgSrc: require("../../src/assets/tealium_logo.png"), status: "Disabled" },
  { label: "Infosum", key: "infosum", imgSrc: require("../../src/assets/infosum_logo.png"), status: "Disabled" },
  { label: "Piano", key: "piano", imgSrc: require("../../src/assets/piano_logo.jpeg"), status: "Disabled" },
  { label: "Index Exchange", key: "index-exchange", imgSrc: require("../../src/assets/index_exchange_logo.png"), status: "Disabled" },
  { label: "Symitri", key: "symitri", imgSrc: require("../../src/assets/symitri_logo.png"), status: "Disabled" },
  { label: "Lotame", key: "lotame", imgSrc: require("../../src/assets/lotame_logo.png"), status: "Disabled" },
  // { label: "Google Ad Manager", key: "google-ad-manager", imgSrc: require("../../src/assets/google_ad_manager.png"), status: "Disabled" },
];


export default function InitiateConnectionModal({ visible, onClose, idType, type, batchProcessID }) {
  const [selectedKey, setSelectedKey] = useState(type === "connection" ? "Direct" : "AWS");
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [connections, setConnections] = useState([]);
  const [selectedConnection, setSelectedConnection] = useState();
  const [data, setData] = useState(type === "connection" ? connectionTypes : dataEndpointTypes);

  const [items, setItems] = useState();

  const handleSelect = (key) => {
    setSelectedKey(key);
  };

  useEffect(() => {
    setSelectedKey(type === "connection" ? "Direct" : "AWS")
    setData(type === "connection" ? connectionTypes : dataEndpointTypes)
  }, [type])

  useEffect(() => {
    setConnections([])
    setSelectedConnection(null)
    if (type === "connection") {
      if (selectedKey !== "Snowflake-Snap") {
        fetchGeneralConnections(selectedKey)
      } else {
        fetchSnowflakeConnections()
      }

    } else if (type === "endpoint") {
      fetchEndpointConnections(selectedKey)
    }
  }, [selectedKey, type])

  useEffect(() => {
    setItems(
      <Menu>
        {connections.length > 0 ? (
          connections.map((connection) => {
            let parsedConfig = {};
            if (connection.configuration) {
              parsedConfig = JSON.parse(connection.configuration || "{}");
            }

            return (
              <Menu.Item
                key={connection.id}
                onClick={() => {
                  setSelectedConnection(connection);
                }}
                style={{
                  backgroundColor:
                    selectedConnection?.id === connection.id ? "#61CEF7" : "transparent",
                }}
              >
                <div
                  style={{
                    cursor: "pointer",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: 32, height: 12, marginRight: 8 }}>
                    <AiOutlineCheck
                      style={{
                        color:
                          selectedConnection?.id === connection.id ? "#61CEF7" : "transparent",
                      }}
                    />
                  </div>
                  {selectedKey === "Snowflake-Snap"
                    ? `${connection?.snowflakeDB} / ${connection?.snowflakeInputTable}`
                    : parsedConfig?.name}
                </div>
              </Menu.Item>
            );
          })
        ) : (
          <Menu.Item
            key="no-connection"
            style={{
              cursor: "not-allowed",
              color: "#aaa",
              pointerEvents: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              No connections
            </div>
          </Menu.Item>
        )}
      </Menu>
    );
  }, [connections, selectedConnection, selectedKey]);


  const handleFileUpload = (file) => {
    setSelectedFile(file);
    return false; // Prevent automatic upload
  };

  const handleBeginUpload = async () => {

    if (type === "connection") {

      // Validate required fields
      if (!idType) {
        message.error("idType is required.");
        return;
      }
      if (!selectedKey) {
        message.error("Please select a connection type.");
        return;
      }

      if (!selectedFile && !selectedConnection) {
        message.error("Please select a file to upload.");
        return;
      }

      setUploading(true);

      try {
        // Prepare form data
        const formData = new FormData();
        formData.append("idType", idType);
        formData.append("connectionType", selectedKey);
        if (selectedKey === 'Direct') {
          formData.append("file", selectedFile);
        } else {
          formData.append("connectionID", selectedConnection.id);
        }

        const response = await createBatchData(formData);

        if (response) {
          message.success("Upload initiated successfully.");
          onClose(); // Close the modal
        } else {
          message.error("Failed to initiate upload.");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        message.error("An error occurred while uploading the file.");
      } finally {
        setUploading(false);
      }
    } else if (type === "endpoint") {
      if (!selectedKey) {
        message.error("Please select a connection type.");
        return;
      }

      if (!selectedFile && !selectedConnection) {
        message.error("Please select a connection.");
        return;
      }

      setUploading(true);
      await dataEndpointFullRefresh(selectedConnection.id, batchProcessID)
      message.success("Success.");
      setUploading(false);
      onClose();
    }
  };

  async function fetchGeneralConnections(type) {
    try {
      setUploading(true);
      const connectionsResponse = await getAllGenericConnections(type, 'batch');
      if (connectionsResponse.success && connectionsResponse.data.length) {
        setConnections(connectionsResponse.data)
      }
    } catch (error) {
      console.log(`Unable to get the ${type} connections`);
      console.log(error);

    }
    setUploading(false);
  }

  async function fetchSnowflakeConnections() {
    try {
      setUploading(true);
      const connectionsResponse = await getAllConnections('Snapshot', 'batch');
      if (connectionsResponse.success && connectionsResponse.data.length) {
        setConnections(connectionsResponse.data)
      }
    } catch (error) {
      console.log(`Unable to get the Snowflake connections`);
      console.log(error);

    }
    setUploading(false);
  }




  async function fetchEndpointConnections(type) {
    try {
      setUploading(true);
      const connectionsResponse = await getS3EndPoints(type);
      if (connectionsResponse.success && connectionsResponse.data.length) {
        setConnections(connectionsResponse.data)
      }
    } catch (error) {
      console.log(`Unable to get the aws connections`);
      console.log(error);

    }
    setUploading(false);
  }


  return (
    <Modal
      visible={visible}
      style={{
        paddingBottom: 20,
      }}
      centered
      width={750}
      footer={null}
      closable={false}
      destroyOnClose
      bodyStyle={{
        width: 750,
        backgroundColor: "#353738",
        padding: "60px",
      }}
    >
      <img
        alt={"Close"}
        onClick={onClose}
        src={require("../assets/modal-close.png")}
        className="delete-x-button-connection"
        style={{ cursor: "pointer" }} // Add cursor pointer for better UX
      />
      <div>
        <span
          style={{
            color: "white",
            fontSize: 24,
            fontWeight: "700",
            fontFamily: "Montserrat",
          }}
        >
          {type === "connection" ? "Connection Type" : "Data Endpoints"}
        </span>
      </div>
      <div style={{ marginTop: "30px", marginBottom: "30px" }}>
        <Row gutter={[16, 16]}>
          {data.map(({ label, key, imgSrc, status, activeImg }) => (
            <Col span={6} key={key}>
              <div
                onClick={() => status === "Active" && handleSelect(key)}
                style={{
                  textAlign: "center",
                  cursor: status === "Active" ? "pointer" : "not-allowed",
                  backgroundColor: selectedKey === key ? "#50E8B1" : "#26282A",
                  color: selectedKey === key ? "#000000" : "#FFFFFF",
                  transition: "all 0.3s ease",
                  border:
                    selectedKey === key
                      ? "1px solid #50E8B1"
                      : "1px solid #26282A",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  padding: "4px",
                }}
              >
                <div
                  style={{
                    backgroundColor:
                      selectedKey === key ? "#ffffff" : "#1D1D1D",
                    height: "100px", // Fixed height for the icon container
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={selectedKey === key ? activeImg ? activeImg : imgSrc : imgSrc}
                    alt={label}
                    style={{
                      display: "block", // Ensure the image behaves like a block element
                      // width: "100%",
                      maxWidth: "100%", // Set a maximum width for large images
                      //   maxHeight: "80px", // Set a maximum height for consistency
                      padding: "10px",
                    }}
                  />
                </div>
                <div
                  style={{
                    fontWeight: "600",
                    fontSize: "12px",
                    textAlign: "left",
                    margin: "9px",
                    fontFamily: "Montserrat",
                  }}
                >
                  {label}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <div style={{ marginTop: "24px", display: "flex", width: "100%" }}>
        <div style={{ width: "100%" }} className="connection_input">
          {selectedKey === "Direct" ? <Upload
            beforeUpload={handleFileUpload}
            showUploadList={false} // Prevent default upload list
            accept=".xlsx, .csv"// Allowed file types
            multiple={false} // Allow single file upload
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  backgroundColor: "#333",
                  color: "#ccc",
                  border: "1px solid #8B8B8B",
                  borderRadius: "2px",
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 0px 0px 12px",
                  fontSize: "14px",
                  fontWeight: "400",
                  fontFamily: "Montserrat",
                  justifyContent: "space-between",
                }}
              >
                {selectedFile ? (
                  <span style={{ color: "#fff" }}>{selectedFile.name}</span>
                ) : (
                  <span style={{ color: "#ccc" }}>Upload file</span>
                )}
                <Button className="upload">Browse</Button>
              </div>
            </div>
          </Upload>
            :
            <Dropdown
              overlay={items}
              trigger={['click']}
              overlayClassName="file-select-dropdown"
              dropdownRender={(menu) => (
                <div
                  style={{
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  {menu}
                </div>
              )}

            >

              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#333",
                      color: "#ccc",
                      border: "1px solid #8B8B8B",
                      borderRadius: "2px",
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                      padding: "0px 0px 0px 12px",
                      fontSize: "14px",
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ color: "#ccc" }}>{selectedConnection ? selectedKey === "Snowflake-Snap" ? `${selectedConnection.snowflakeDB} / ${selectedConnection.snowflakeInputTable}` : selectedConnection.configuration ? (JSON.parse(selectedConnection.configuration)).name : "Select file" : "Select file"}</span>
                    <AiOutlineDown
                      style={{
                        cursor: "pointer",
                        color: "white",
                        margin: "15px 10px",
                      }}
                    />
                  </div>
                </div>

              </div>
              {/* </a> */}
            </Dropdown>}
        </div>
      </div>
      <Button
        type="primary"
        className="begin-upload"
        style={{
          marginTop: "30px",
          backgroundColor: "#61CEF7 !importnt",
          borderColor: "#00b7ff",
          fontSize: "16px",
          padding: "20px 30px",
          height: "auto",
          width: "auto",
        }}
        onClick={handleBeginUpload}
        loading={uploading} // Show loading spinner when uploading
      >
        Begin upload
      </Button>
    </Modal>
  );
}
