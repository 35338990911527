import { Button, Col, Row } from 'antd'
import React, { memo } from 'react'

const RegCommonButton = memo(props => {
    return (
        <Row style={{ marginTop: 32, width: '100%' }}>
            <Col sm={6} xs={22}>
                <Button onClick={(event) => {
                    props?.onClick(event)
                }} className={props.verifyLoading ? 'verify-button' : 'login-button'} block size={'large'} style={{ background: '#000' }} {...props}>{props.btntext}</Button>
            </Col>
        </Row>
    )
})

export default RegCommonButton