import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Input, Modal, Row, Typography } from 'antd';
import { TitleText } from './NewAppModal';
import { AiOutlineDown } from 'react-icons/ai';


export function FrequencyDropDown({ value, onChange, hideYearly = false, hideHourly = false }) {

    const frequencyList = ['Daily', 'Weekly', 'Monthly'];

    if (!hideYearly) {
        frequencyList.push('Yearly');
    }

    if(!hideHourly) {
        frequencyList.splice(0, 0, 'Hourly');
    }

    const menu = (
        <div className='verticle-dropdown-container' style={{ height: !hideYearly && !hideHourly ? 160 : (hideHourly || hideYearly) ? 140 : 120, background: 'black', overflowY: 'scroll' }}>
            {
                frequencyList.map(frequencyItem => {
                    return (
                        <div style={{ cursor: 'pointer', color: '#fff' }} className='frequency-dropdown-option' onClick={() => {
                            onChange(frequencyItem);
                        }}>
                            <div style={{ display: 'inline-block', width: 32, height: 12 }}>
                            </div>
                            {frequencyItem}
                        </div>
                    );
                })
            }
        </div>
    )

    return (
        <div className='snowflake-frequency-picker-container'>
            <Dropdown className='vertical-dropdown' overlay={menu} trigger={['click']}>
                <div className='lockr-signup-control' style={{ position: 'relative', height: 48 }}>
                    <AiOutlineDown style={{ position: 'absolute', right: 16, top: 12, color: 'white' }} />
                    <TitleText
                        onFocusCapture={() => { }}
                        onBlurCapture={() => { }}
                        style={{ color: '#fff', borderColor: '#8B8B8B', borderRadius: 2, borderTopWidth: 0 }}
                        value={value}
                        placeholder={'Frequency'}
                        readOnly
                    />
                </div>
            </Dropdown>
        </div>
    )
}

function CreateConnection({ display, onSave, onSync, onCancel, type, defaultConnectionDetails, isDuplicate, supportsFrequency }) {

    const [connectionDetails, setConnectionDetails] = useState(defaultConnectionDetails ?? { frequency: 'Daily' });

    function checkValidation() {
        const urlRegExp = /^(http|https):\/\/[\w\-]+(\.[\w\-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;

        let validation = true;
        if (!connectionDetails?.snowflakeAccount || connectionDetails?.snowflakeAccount?.trim?.() === '') {
            validation = false;
        }
        if (!connectionDetails?.snowflakeClientID || connectionDetails?.snowflakeClientID?.trim?.() === '') {
            validation = false;
        }
        if (!connectionDetails?.snowflakeClientSecret || connectionDetails?.snowflakeClientSecret?.trim?.() === '') {
            validation = false;
        }
        if (!connectionDetails?.authorizationUrl || connectionDetails?.authorizationUrl?.trim?.() === '') {
            validation = false;
        }
        if (!urlRegExp.test(connectionDetails?.authorizationUrl)) {
            validation = false;
        }
        if (!connectionDetails?.snowflakeWarehouse || connectionDetails?.snowflakeWarehouse?.trim?.() === '') {
            validation = false;
        }
        if (!connectionDetails?.snowflakeDB || connectionDetails?.snowflakeDB?.trim?.() === '') {
            validation = false;
        }
        if (!connectionDetails?.snowflakeInputTable || connectionDetails?.snowflakeInputTable?.trim?.() === '') {
            validation = false;
        }
        if (!connectionDetails?.emailColumnName || connectionDetails?.emailColumnName?.trim?.() === '') {
            validation = false;
        }
        if (!connectionDetails?.snowflakeOutputTable || connectionDetails?.snowflakeOutputTable?.trim?.() === '') {
            validation = false;
        }

        return validation;
    }

    return (
        <Modal
            visible={display}
            style={{ top: 20, paddingBottom: 20 }}
            centered
            width={776}
            footer={null}
            closable={false}
            destroyOnClose
            bodyStyle={{ minHeight: 686, width: 776, backgroundColor: '#353738', paddingLeft: 60, paddingTop: 40, paddingRight: 0, overflowX: 'hidden' }}
        >
            <img alt={'Close'} onClick={onCancel} src={require('../assets/modal-close.png')} className="delete-x-button-connection" />
            {isDuplicate && <div style={{ flexDirection: 'column', display: 'flex' }}>
                <Typography.Title style={{ fontFamily: 'Montserrat', color: '#fff', fontWeight: 700, fontSize: 24, paddingBottom: 0, paddingTop: 0 }}>{`Duplicate Test`}</Typography.Title>
                <Typography.Title className='duplicate-connection-subtitle' style={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: 14, paddingBottom: 0, marginTop: -2 }}>{`Please adjust the settings below to run this test from a duplicate entry.`}</Typography.Title>
                <div style={{
                    background: '#8B8B8B',
                    width: '100%',
                    marginTop: 24,
                    height: 1,
                    marginBottom: 34,
                }} />
            </div>}
            <div>
                <Typography.Title style={{ display: 'inline-block', fontFamily: 'Montserrat', color: '#fff', fontWeight: 700, fontSize: 20, paddingBottom: 40, paddingTop: 8 }}>{`Connection Settings`}</Typography.Title>
            </div>
            <div>
                <TitleText
                    value={connectionDetails?.snowflakeAccount}
                    placeholder={" "}
                    label={"Snowflake Account"}
                    marginLeft={148}
                    onChange={event => setConnectionDetails({
                        ...connectionDetails, snowflakeAccount: event.target.value
                    })}
                />
                <div style={{ marginTop: 16 }} />
                <TitleText
                    marginLeft={233}
                    label={"Client ID"}
                    value={connectionDetails?.snowflakeClientID}
                    placeholder={" "}
                    onChange={event => setConnectionDetails({ ...connectionDetails, snowflakeClientID: event.target.value })}
                />
                <div style={{ marginTop: 16 }} />
                <TitleText
                    marginLeft={200}
                    label={"Client Secret"}
                    value={connectionDetails?.snowflakeClientSecret}
                    placeholder={" "}
                    onChange={event => setConnectionDetails({ ...connectionDetails, snowflakeClientSecret: event.target.value })}
                />
                <div style={{ marginTop: 16 }} />
                <TitleText
                    marginLeft={155}
                    label={'Authorization URL'}
                    value={connectionDetails?.authorizationUrl}
                    placeholder={' '}
                    onChange={event => setConnectionDetails({ ...connectionDetails, authorizationUrl: event.target.value })}
                />
                <div style={{ marginTop: 16 }} />
                <TitleText
                    label={'Snowflake Warehouse'}
                    value={connectionDetails?.snowflakeWarehouse}
                    placeholder={' '}
                    marginLeft={123}
                    onChange={event => setConnectionDetails({ ...connectionDetails, snowflakeWarehouse: event.target.value })}
                />
                <div style={{ marginTop: 16 }} />
                <TitleText
                    label={'Snowflake Database'}
                    value={connectionDetails?.snowflakeDB}
                    placeholder={' '}
                    marginLeft={138}
                    onChange={event => setConnectionDetails({ ...connectionDetails, snowflakeDB: event.target.value })}
                />
                <div style={{ marginTop: 16 }} />
                <TitleText
                    label={'Snowflake Table'}
                    value={connectionDetails?.snowflakeInputTable}
                    placeholder={' '}
                    marginLeft={170}
                    onChange={event => setConnectionDetails({ ...connectionDetails, snowflakeInputTable: event.target.value })}
                />
                <div style={{ marginTop: 16 }} />
                <TitleText
                    label={'Email Column'}
                    value={connectionDetails?.emailColumnName}
                    placeholder={' '}
                    marginLeft={185}
                    onChange={event => setConnectionDetails({ ...connectionDetails, emailColumnName: event.target.value })}
                />
                <div style={{ marginTop: 16 }} />
                <TitleText
                    label={'Timestamp Column'}
                    value={connectionDetails?.timestampColumnName}
                    placeholder={' '}
                    marginLeft={140}
                    onChange={event => setConnectionDetails({ ...connectionDetails, timestampColumnName: event.target.value })}
                />
                <div style={{ marginTop: 16 }} />
                <TitleText
                    label={'Dimension Column'}
                    value={connectionDetails?.dimensionColumnName}
                    placeholder={' '}
                    marginLeft={143}
                    onChange={event => setConnectionDetails({ ...connectionDetails, dimensionColumnName: event.target.value })}
                />
                <div style={{ marginTop: 16 }} />
                <TitleText
                    label={'Output Table'}
                    value={connectionDetails?.snowflakeOutputTable}
                    placeholder={' '}
                    marginLeft={193}
                    onChange={event => setConnectionDetails({ ...connectionDetails, snowflakeOutputTable: event.target.value })}
                />
                {
                    supportsFrequency && <div style={{ marginTop: 16 }}>
                        <TitleText
                            label={'Frequency'}
                            marginLeft={213}
                            customInput={
                                <FrequencyDropDown
                                    value={connectionDetails?.frequency ?? 'Daily'}
                                    onChange={event => setConnectionDetails({ ...connectionDetails, frequency: event })}
                                />
                            }
                            onChange={event => {
                                console.log(event);
                            }}
                        />
                    </div>
                }
                <Row style={{ marginTop: 50 }}>
                    {type !== 'Snapshot' && <Button
                        disabled={!checkValidation()}
                        onClick={() => {
                            onSave(connectionDetails);
                        }} block type={"primary"} style={{ width: 200 }} loading={false} className={"sign-in"}>
                        <label>{"Save"}</label>
                    </Button>}
                    <div style={{ marginLeft: 16 }} />
                    {type === 'Snapshot' && <Button
                        disabled={!checkValidation()}
                        onClick={() => {
                            onSync(connectionDetails);
                        }} block type={"primary"} style={{ width: 200 }} loading={false} className={"sign-in"}>
                        <label>{"Sync Data"}</label>
                    </Button>}
                </Row>
            </div>

        </Modal>
    )
}

CreateConnection.propTypes = {
    display: PropTypes.bool,
    onSave: PropTypes.func.isRequired,
    onSync: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    supportsFrequency: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    defaultConnectionDetails: PropTypes.object,
    mode: PropTypes.number,
    isDuplicate: PropTypes.bool
}

export default CreateConnection;