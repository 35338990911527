import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../Components/customHook/useQuery';
import { syncSnowflakeSnapshot, validateSnowflakeCodeAction } from '../../Utils/ApiActions';

export function SnowflakeSnapshotCallback() {

    const query = useQuery();
    const code = query.get('code');
    const history = useHistory();
    const snowflakeIntegrationId = query.get('state').split(':')[0];
    const type = query.get('state').split(':')[1].split('_')[0];
    const isBatch = query.get('state').split(':')[1].split('_')?.[1]

    useEffect(() => {
        validateSnowflakeCode();
    }, [])

    async function validateSnowflakeCode() {
        if (code && snowflakeIntegrationId) {
            try{                
                await validateSnowflakeCodeAction(code, snowflakeIntegrationId);
                type === 'Snapshot' && startSnowflakeProcessing();             
                type !== 'Snapshot' && history.push('/console/connectionList?type=Continuous' + (isBatch ? '&batch=true' : ''));
            }catch(error){
                console.log('Unable to validate the snowflake code at the moment');
                console.log(error);
            }            
        }
    }

    async function startSnowflakeProcessing(){
        if(code && snowflakeIntegrationId){
            try{
                const snapShotResponse = await syncSnowflakeSnapshot(snowflakeIntegrationId);
                snapShotResponse.success && history.push('/console/connectionList?type=Snapshot' + (isBatch ? '&batch=true' : ''));
            }catch(error){
                console.log('Unable to start the snowflake processing at the moment');
                console.log(error);
            }
        }
    }

    return (
        <div style={{ fontSize: 32, height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
            Loading...
        </div>
    )

}