import { Col, Row, Typography } from 'antd'
import React, { memo } from 'react'

const RegCommonTitle = memo(props => {
    return (
        <Row style={{ marginTop: 32, width: '100%' }}>
            <Col>
                <div class="reg-common-title">{props.title || 'Welcome'}</div>
                {/* @Swapnil: Comment out following control because heading tag adding some 
              additional css related to margin specifically bottom margin and font size is 
              applying through css class reg-common-title. In inspect it showing 38px 
              Because heading tag css over write on following control */}
                {/* <Typography.Title className='reg-common-title'>
                  {props.title || 'Welcome'}
              </Typography.Title> */}
            </Col>
        </Row>)
})

export default RegCommonTitle