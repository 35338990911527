import { Button, Col, Input, messamessage, ge, Row, Typography, message, Modal } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { handleSignIn } from '../../Utils/ApiActions'
import RegCommonTitle from '../../Components/RegCommonTitle'
import FloatLabel from '../../Components/FloatLabel/FloatLabel'
import { userStore } from '../../Store/UserStore'
import { getDefaultPageByRole, USER_ROLE_ENUM } from '../../Utils/Helper'

function LoginFormInput(props) {
    return (
        <div className="lockr-form-control" style={{ ...props.lockrformstyle, ...{ display: 'flex', flexDirection: 'row', alignItems: 'center' } }}>
            <Input style={{ color: '#fff', border: '0px' }} onChange={(event) => {
                props?.onChange(event);
            }} placeholder={props.placeholder}
                {...props}>

            </Input>
            <Button onClick={(event) => {
                props?.forgotOnClick(event)
            }} className='lockr-link' style={{ fontWeight: 'bold', border: '#282c34 1px solid', borderLeftColor: '#1d1d1d', height: 56, fontSize: 12 }} type="link">Forgot?</Button>
        </div>
    )
}

export function LoginFormInputEmail(props) {
    return (
        <div className="lockr-form-control" style={{ ...props.lockrformstyle, ...{ display: 'flex', flexDirection: 'row', alignItems: 'center' } }}>
            <Input className={props.inputClassName ?? ''} style={{ color: '#fff', border: '0px', ...(props?.inputStyle ?? {}) }} onChange={(event) => {
                props?.onChange(event);
            }} placeholder={props.placeholder}
                {...props}>

            </Input>
            {/* <Button onClick={(event) => {
                props?.forgotOnClick(event)
            }} className='lockr-link' style={{ fontWeight: 'bold', border: '#282c34 1px solid', borderLeftColor: '#1d1d1d', height: 56, fontSize: 12 }} type="link">Forgot?</Button> */}
        </div>
    )
}


const Login = (props) => {
    const [emailAddress, setEmailAddress] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState('')
    const [dispalyLoading, setDisplayLoading] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false);
    const [isDeletedData, setIsDeletedData] = useState({});
    const userContext = useContext(userStore);

    useEffect(() => {
        if (localStorage.getItem('authToken')) {    
            const role = userContext.state.userRole;        
            props.history.replace(getDefaultPageByRole(role) ?? "/console/dashboard");
        }
    })


    function focusOut() {
        let elements = document.getElementsByClassName("lockr-form-control");
        if (elements.length === 0) {
            return;
        }
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('lockr-form-control-focus');
        }
    }
    function getFocus() {
        let elements = document.getElementsByClassName("lockr-form-control");
        if (elements.length === 0) {
            return;
        }
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add('lockr-form-control-focus');
        }
    }

    function checkValidation() {
        let _validate = true
        let _emailReg = new RegExp(/^[^@]+@[^@]{2,}\.[^@]{2,}$/)
        if (emailAddress.trim() === '' || !_emailReg.test(emailAddress)) {
            setError(true)
            setErrorText('Email you input is invalid. Please confirm it is formatted properly.');
            return _validate = false
        }
        if (password.trim() === '') {
            setError(true)
            setErrorText("Password can't be blank");
            return _validate = false
        }
        return _validate
    }
    const handleLogin = (event) => {
        event.preventDefault();
        if (checkValidation()) {
            setDisplayLoading(true)
            let _loginReq = {
                username: emailAddress,
                password: password
            }
            handleSignIn(_loginReq).then(res => {
                // console.log(res);
                setDisplayLoading(false)
                if (res?.data?.isDeleted) {
                    setDeleteModal(true);
                    setIsDeletedData(res?.data);
                    console.log(res)
                }
                else {
                    let _authTokens = res?.data;                    
                    localStorage.setItem("authToken", _authTokens.access_token);
                    localStorage.setItem("refreshToken", _authTokens.refresh_token);
                    localStorage.setItem("publisherName", _authTokens?.publisherDetails?.businessName || "Publisher");                    
                    userContext.actions.setUserRole(_authTokens.isChild ? _authTokens.role : USER_ROLE_ENUM.SUPER_ADMIN);
                    props.history.replace(getDefaultPageByRole(_authTokens.role ?? USER_ROLE_ENUM.SUPER_ADMIN) ?? "/console/dashboard")
                }
            }).catch(err => {
                console.log(err)
                setDisplayLoading(false)
                setError(true)
                setErrorText('The username and password you have entered does not match our record')
            })
        }
    }


    const _renderReactivateModal = () => {
        return (<Modal
            transitionName=""
            maskTransitionName=""
            width={716}
            bodyStyle={{
                width: 716,
                backgroundColor: "#353738",
                paddingLeft: 60,
                paddingTop: 40,
                paddingRight: 0,
                paddingBottom: 60,
                overflowX: "hidden",
            }}
            closable={false}
            footer={null}
            visible={deleteModal}
            onOk={() => setDeleteModal(false)}
            onCancel={() => setDeleteModal(false)}
            className="delete-app-modal-forinput"
        >
            <div>
                {/* <Typography.Title
                    style={{
                        display: "inline-block",
                        fontFamily: "Montserrat",
                        color: "#fff",
                        fontWeight: 700,
                        fontSize: 34,
                        paddingBottom: 20,
                        paddingTop: 8,
                    }}
                >
                    Delete account
                </Typography.Title> */}
                <Typography.Text style={{ display: 'inline-block', width: 596, paddingTop: 24 }}>
                    Welcome back {isDeletedData?.firstName} {isDeletedData?.lastName}! We see you are returning to Identity lockr after
                    deleting your account previously. We'd be happy to reactivate your old
                    account ({isDeletedData?.username}). To do so, please click the
                    verification link we sent to your personal email.
                </Typography.Text>
                <img
                    style={{ display: 'inline-block', position: 'absolute', right: 35, top: 40, cursor: 'pointer' }}
                    onClick={() => setDeleteModal(false)}
                    src={require("../../assets/modal-close.png")}
                // className="delete-modal-x-button"
                ></img>


            </div>
            <Button

                style={{ display: "block", marginTop: 40, width: 180, height: 50 }}
                onClick={() => {
                    // console.log(props.record);
                    setDeleteModal(false)
                }}
                type="primary"
                className="sign-in"
            >
                OK
            </Button>


        </Modal>)
    }


    document.body.classList.add('lockr-bg-img');
    return (
        // <div>
        //     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Row justify='start' className='reg-container' style={{ marginTop: 240 }}>
            {_renderReactivateModal()}
            <Row style={{ width: '100%' }}>

                <Col>
                    <img class="lockr-logo" src={require('../../assets/onboard_lock.42d74f50.png')} alt='lock-img' />
                </Col>
            </Row>
            <Row style={{ width: '100%' }}>
                <Col xs={24}>
                    <RegCommonTitle title={'Identity lockr'} />
                    {/* <Typography.Title style={{ fontSize: 28, fontFamily: 'Montserrat, sans-serif' }}>
                            Publisher Login
                            </Typography.Title> */}
                </Col>
            </Row>
            <form className="login-form" onSubmit={handleLogin} style={{ width: '100%' }}>
                <Row className='login-control' style={{ marginTop: 48 }}>
                    <Col sm={14} xs={24}>
                        <FloatLabel label='Email address' value={emailAddress}>
                            <LoginFormInputEmail
                                className={error ? 'errorInputText' : null}
                                value={emailAddress}
                                onChange={(event) => {
                                    setEmailAddress(event.target.value);
                                    setError(false);
                                }}
                                onFocus={getFocus}
                                onBlur={focusOut}
                            />
                        </FloatLabel>
                        <FloatLabel label='Password' value={password}>
                            <LoginFormInput
                                forgotOnClick={() => {
                                    props.history.push('/console/forgot');
                                }}
                                lockrformstyle={{ borderTop: 0 }}
                                className={error ? 'errorInputText' : null}
                                type='password'
                                value={password}
                                onChange={(event) => {
                                    setPassword(event.target.value);
                                    setError(false);
                                }}
                                onFocus={getFocus}
                                onBlur={focusOut}
                            />
                        </FloatLabel>
                    </Col>
                    {error && (
                        <Col sm={14} xs={24}>
                            <label className='errorText'>{errorText}</label>
                        </Col>
                    )}
                </Row>
                <Row style={{ marginTop: 32, width: '100%' }}>
                    <Col sm={5} xs={12}>
                        <Button
                            disabled={!emailAddress || !password}
                            loading={dispalyLoading}
                            className='sign-in'
                            block 
                            size='large'
                            style={{ background: '#000' }}
                            htmlType='submit'  
                        >
                            Log in
                        </Button>
                    </Col>
                </Row>
            </form>

            <Row style={{ marginTop: 48, width: '100%' }}>
                <Col sm={18} xs={24}>
                    <label style={{ fontFamily: 'Montserrat, sans-serif', color: '#fff' }}>Don't have a login?<Button onClick={() => {
                        props.history.replace("/console/signup")
                    }} type='link' style={{ fontWeight: 'bold', color: '#61cef7', fontSize: 16, paddingLeft: 4 }}>Create an account</Button> </label>
                </Col>
            </Row>
        </Row>
        //     </div>
        // </div>
    )
}

export default withRouter(Login)