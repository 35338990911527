import { Button, Col, Modal, Progress, Row, Spin, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import RegCommonButton from '../../Components/RegCommonButton'
import RegCommonInput from '../../Components/RegCommonInput'
import RegCommonTitle from '../../Components/RegCommonTitle'
import { sendResetLink } from '../../Utils/ApiActions'
import FloatLabel from '../../Components/FloatLabel/FloatLabel'
import Paragraph from 'antd/lib/typography/Paragraph'
import { CloseOutlined } from '@ant-design/icons'

const ForgotPassword = (props) => {
    const [email, setEmail] = useState('')
    const [linkSend, setLinkSend] = useState(false)
    const [displayLoading, setDisplayLoading] = useState(false)
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState('')
    const [btnDisable, setBtnDisable] = useState(true)

    useEffect(() => {
        if (checkValidation() === false) {
            setBtnDisable(true)
        } else {
            setBtnDisable(false)
        }
    }, [email])

    const handleCancle = () => {
        setLinkSend(false)
    }


    const handleForgotSubmit = () => {
        setDisplayLoading(true)
        sendResetLink({
            email: email
        }).then(response => {
            setDisplayLoading(false)
            if (response.success === true) {
                setLinkSend(true)
            } else {
                setError(true)
                setErrorText(response?.error?.message || 'Unable to send reset password link. ');
            }
        }).catch(err => {
            setDisplayLoading(false)
            setError(true)
            setErrorText("Unable to get the details due to: " + err.message);
        })
    }

    function checkValidation() {
        let _validate = true
        let _emailReg = new RegExp(/^[^@]+@[^@]{2,}\.[^@]{2,}$/)
        if (email.trim() === '' || !_emailReg.test(email)) {
            return _validate = false
        }
        return _validate
    }

    document.body.classList.add('lockr-bg-img');
    return (
        <Spin spinning={displayLoading}>
            <Row>
                <Progress className='reg-steps' percent={0} showInfo={false} />
            </Row>
            <Row justify='start' className='reg-container'>
                <Row style={{ width: '100%' }}>
                    <Col>
                        <img className="lockr-logo" src={require('../../assets/onboard_lock.42d74f50.png')} alt='lock-img' />
                    </Col>
                </Row>
                <RegCommonTitle title={'Forgot Password'} />
                <Row style={{ width: '100%' }}>
                    <Col sm={16} xs={24}>
                        <Typography.Text style={{ fontFamily: 'Montserrat, sans-serif', fontSize: 14 }}>Enter your email and we'll send you a link to reset your password.</Typography.Text>
                    </Col>
                </Row>
                <Row style={{ marginTop: 48, width: '100%' }}>
                    <Col sm={12} xs={24}>
                        <FloatLabel value={email} label={'Email address'}>
                            <RegCommonInput className={error ? 'errorInputText' : null} value={email}
                                onChange={(event) => {
                                    setError(false)
                                    setEmail(event.target.value)
                                }}
                            />
                        </FloatLabel>
                    </Col>
                    {error && <Col sm={14} xs={24}>
                        <label className='errorText'>{errorText}</label>
                    </Col>}
                </Row>
                <RegCommonButton disabled={btnDisable} btntext={'Submit'} onClick={handleForgotSubmit} />
            </Row>
            {linkSend && <Modal width={716} style={{ height: 331 }} className='verificationModal'
                bodyStyle={{ paddingTop: 0, backgroundColor: '#1d1d1d', paddingRight: 60, paddingLeft: 60, paddingTop: 48, paddingBottom: 48 }}
                closeIcon={<CloseOutlined style={{ color: '#fff', paddingTop: 60, paddingRight: 60, fontSize: 20 }} />}
                title={null}
                footer={null}
                visible={linkSend}
                maskClosable={false}
                maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.85)' }}
                closable={true}
                onCancel={handleCancle}
            >
                <Row style={{ width: '100%', marginTop: 24 }}>
                    <Paragraph>
                    Please check your inbox for an email we just sent you with instructions for how to reset your password and log into your account.
                    </Paragraph>
                </Row>
                <RegCommonButton btntext={'OK'} onClick={handleCancle} />
            </Modal>}
        </Spin>
    )
}

export default withRouter(ForgotPassword)