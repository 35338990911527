import React from "react";

export function DateRangeCalendar() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M4.86958 7H4.17392C3.98943 7 3.81248 7.07024 3.68202 7.19526C3.55156 7.32029 3.47827 7.48986 3.47827 7.66667C3.47827 7.84348 3.55156 8.01305 3.68202 8.13807C3.81248 8.2631 3.98943 8.33333 4.17392 8.33333H4.86958C5.05407 8.33333 5.23102 8.2631 5.36148 8.13807C5.49194 8.01305 5.56523 7.84348 5.56523 7.66667C5.56523 7.48986 5.49194 7.32029 5.36148 7.19526C5.23102 7.07024 5.05407 7 4.86958 7Z" fill="black" />
            <path d="M8.34773 7H7.65207C7.46757 7 7.29063 7.07024 7.16017 7.19526C7.02971 7.32029 6.95642 7.48986 6.95642 7.66667C6.95642 7.84348 7.02971 8.01305 7.16017 8.13807C7.29063 8.2631 7.46757 8.33333 7.65207 8.33333H8.34773C8.53222 8.33333 8.70917 8.2631 8.83963 8.13807C8.97009 8.01305 9.04338 7.84348 9.04338 7.66667C9.04338 7.48986 8.97009 7.32029 8.83963 7.19526C8.70917 7.07024 8.53222 7 8.34773 7Z" fill="black" />
            <path d="M11.8261 7H11.1304C10.9459 7 10.769 7.07024 10.6385 7.19526C10.508 7.32029 10.4348 7.48986 10.4348 7.66667C10.4348 7.84348 10.508 8.01305 10.6385 8.13807C10.769 8.2631 10.9459 8.33333 11.1304 8.33333H11.8261C12.0106 8.33333 12.1875 8.2631 12.318 8.13807C12.4484 8.01305 12.5217 7.84348 12.5217 7.66667C12.5217 7.48986 12.4484 7.32029 12.318 7.19526C12.1875 7.07024 12.0106 7 11.8261 7Z" fill="black" />
            <path d="M4.86958 9.66602H4.17392C3.98943 9.66602 3.81248 9.73625 3.68202 9.86128C3.55156 9.9863 3.47827 10.1559 3.47827 10.3327C3.47827 10.5095 3.55156 10.6791 3.68202 10.8041C3.81248 10.9291 3.98943 10.9993 4.17392 10.9993H4.86958C5.05407 10.9993 5.23102 10.9291 5.36148 10.8041C5.49194 10.6791 5.56523 10.5095 5.56523 10.3327C5.56523 10.1559 5.49194 9.9863 5.36148 9.86128C5.23102 9.73625 5.05407 9.66602 4.86958 9.66602Z" fill="black" />
            <path d="M8.34773 9.66602H7.65207C7.46757 9.66602 7.29063 9.73625 7.16017 9.86128C7.02971 9.9863 6.95642 10.1559 6.95642 10.3327C6.95642 10.5095 7.02971 10.6791 7.16017 10.8041C7.29063 10.9291 7.46757 10.9993 7.65207 10.9993H8.34773C8.53222 10.9993 8.70917 10.9291 8.83963 10.8041C8.97009 10.6791 9.04338 10.5095 9.04338 10.3327C9.04338 10.1559 8.97009 9.9863 8.83963 9.86128C8.70917 9.73625 8.53222 9.66602 8.34773 9.66602Z" fill="black" />
            <path d="M11.8261 9.66602H11.1304C10.9459 9.66602 10.769 9.73625 10.6385 9.86128C10.508 9.9863 10.4348 10.1559 10.4348 10.3327C10.4348 10.5095 10.508 10.6791 10.6385 10.8041C10.769 10.9291 10.9459 10.9993 11.1304 10.9993H11.8261C12.0106 10.9993 12.1875 10.9291 12.318 10.8041C12.4484 10.6791 12.5217 10.5095 12.5217 10.3327C12.5217 10.1559 12.4484 9.9863 12.318 9.86128C12.1875 9.73625 12.0106 9.66602 11.8261 9.66602Z" fill="black" />
            <path d="M4.86958 12.334H4.17392C3.98943 12.334 3.81248 12.4042 3.68202 12.5292C3.55156 12.6543 3.47827 12.8238 3.47827 13.0007C3.47827 13.1775 3.55156 13.347 3.68202 13.4721C3.81248 13.5971 3.98943 13.6673 4.17392 13.6673H4.86958C5.05407 13.6673 5.23102 13.5971 5.36148 13.4721C5.49194 13.347 5.56523 13.1775 5.56523 13.0007C5.56523 12.8238 5.49194 12.6543 5.36148 12.5292C5.23102 12.4042 5.05407 12.334 4.86958 12.334Z" fill="black" />
            <path d="M8.34773 12.334H7.65207C7.46757 12.334 7.29063 12.4042 7.16017 12.5292C7.02971 12.6543 6.95642 12.8238 6.95642 13.0007C6.95642 13.1775 7.02971 13.347 7.16017 13.4721C7.29063 13.5971 7.46757 13.6673 7.65207 13.6673H8.34773C8.53222 13.6673 8.70917 13.5971 8.83963 13.4721C8.97009 13.347 9.04338 13.1775 9.04338 13.0007C9.04338 12.8238 8.97009 12.6543 8.83963 12.5292C8.70917 12.4042 8.53222 12.334 8.34773 12.334Z" fill="black" />
            <path d="M11.8261 12.334H11.1304C10.9459 12.334 10.769 12.4042 10.6385 12.5292C10.508 12.6543 10.4348 12.8238 10.4348 13.0007C10.4348 13.1775 10.508 13.347 10.6385 13.4721C10.769 13.5971 10.9459 13.6673 11.1304 13.6673H11.8261C12.0106 13.6673 12.1875 13.5971 12.318 13.4721C12.4484 13.347 12.5217 13.1775 12.5217 13.0007C12.5217 12.8238 12.4484 12.6543 12.318 12.5292C12.1875 12.4042 12.0106 12.334 11.8261 12.334Z" fill="black" />
            <path d="M14.6087 2H12.6957C12.6495 2 12.6053 1.98244 12.5727 1.95118C12.5401 1.91993 12.5217 1.87754 12.5217 1.83333V0.666667C12.5217 0.489856 12.4484 0.320286 12.318 0.195262C12.1875 0.0702379 12.0106 0 11.8261 0C11.6416 0 11.4646 0.0702379 11.3342 0.195262C11.2037 0.320286 11.1304 0.489856 11.1304 0.666667V3.83333C11.1304 3.96594 11.0755 4.09312 10.9776 4.18689C10.8798 4.28065 10.7471 4.33333 10.6087 4.33333C10.4703 4.33333 10.3376 4.28065 10.2398 4.18689C10.1419 4.09312 10.087 3.96594 10.087 3.83333V2.33333C10.087 2.24493 10.0503 2.16014 9.98508 2.09763C9.91985 2.03512 9.83138 2 9.73913 2H5.3913C5.34518 2 5.30094 1.98244 5.26833 1.95118C5.23571 1.91993 5.21739 1.87754 5.21739 1.83333V0.666667C5.21739 0.489856 5.1441 0.320286 5.01364 0.195262C4.88318 0.0702379 4.70624 0 4.52174 0C4.33724 0 4.1603 0.0702379 4.02984 0.195262C3.89938 0.320286 3.82609 0.489856 3.82609 0.666667V3.83333C3.82609 3.96594 3.77112 4.09312 3.67327 4.18689C3.57543 4.28065 3.44272 4.33333 3.30435 4.33333C3.16597 4.33333 3.03327 4.28065 2.93542 4.18689C2.83758 4.09312 2.78261 3.96594 2.78261 3.83333V2.33333C2.78261 2.24493 2.74596 2.16014 2.68073 2.09763C2.6155 2.03512 2.52703 2 2.43478 2H1.3913C1.02231 2 0.668424 2.14048 0.407504 2.39052C0.146583 2.64057 0 2.97971 0 3.33333V14.6667C0 15.0203 0.146583 15.3594 0.407504 15.6095C0.668424 15.8595 1.02231 16 1.3913 16H14.6087C14.9777 16 15.3316 15.8595 15.5925 15.6095C15.8534 15.3594 16 15.0203 16 14.6667V3.33333C16 2.97971 15.8534 2.64057 15.5925 2.39052C15.3316 2.14048 14.9777 2 14.6087 2ZM14.6087 14.3333C14.6087 14.4217 14.5721 14.5065 14.5068 14.569C14.4416 14.6315 14.3531 14.6667 14.2609 14.6667H1.73913C1.64688 14.6667 1.55841 14.6315 1.49318 14.569C1.42795 14.5065 1.3913 14.4217 1.3913 14.3333V6.33333C1.3913 6.24493 1.42795 6.16014 1.49318 6.09763C1.55841 6.03512 1.64688 6 1.73913 6H14.2609C14.3531 6 14.4416 6.03512 14.5068 6.09763C14.5721 6.16014 14.6087 6.24493 14.6087 6.33333V14.3333Z" fill="black" />
        </svg>
    )
}