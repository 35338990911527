import {
  Avatar,
  PageHeader,
  Input,
  Typography,
  Button,
  notification,
  Row,
  Col,
  Tooltip,
  Spin,
  Modal,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  PlusOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  CopyOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { addApplication, editApplication } from "../Utils/ApiActions";
import { withRouter } from "react-router-dom";
import SideDrawer from "../Components/SideDrawer";

function TitleText(props) {    
  return (
    <Row>
      <Col xs={24}>
        <Typography.Text
          style={{ fontSize: 18, fontWeight: "400", marginBottom: 4 }}
        >
          {props.label}
        </Typography.Text>
        {props.info && (
          <Tooltip
            title={props.tooltipText ? props.tooltipText : "Sample Tooltip"}
          >
            <InfoCircleOutlined
              style={{ color: "#fff", fontSize: 12, marginLeft: 15 }}
            />
          </Tooltip>
        )}
      </Col>
      <Col xs={24} style={{ marginTop: 8, width: "320px" }}>
        {(props.customInput !== null || props.customInput !== undefined) ? props.customInput :
          <Input
            type={props.type}
            disabled={props.disabled}
            value={props.value}
            style={{ color: "#fff" }}
            onChange={(event) => {
              props?.onChange(event);
            }}
            className="form-input"
            placeholder={props.placeholder || "placeholder"}
          />}
      </Col>
    </Row>
  );
}

function MultiURLInput(props) {
  return (
    <Row>
      <Col xs={19}>
        <Input
          value={props.value}
          style={{ color: "#fff" }}
          onChange={(event) => {
            props?.onChange(event);
          }}
          className="form-input"
          placeholder={props.placeholder}
        ></Input>
      </Col>
      <Col xs={4}>
        <Button
          onClick={(event) => {
            props?.onClose(event, props.index);
          }}
          style={{ height: 56 }}
          danger
          type="primary"
          shape="default"
        >
          <CloseOutlined style={{ fontSize: 28 }}></CloseOutlined>
        </Button>
      </Col>
    </Row>
  );
}

function AuthorizationButton(props) {
  return (
    <div>
      <Typography.Text
        style={{ fontSize: 18, fontWeight: "400", marginBottom: 4 }}
      >
        {props.label}
      </Typography.Text>
      {props.info && (
        <Tooltip
          title={props.tooltipText ? props.tooltipText : "Sample Tooltip"}
        >
          <InfoCircleOutlined
            style={{ color: "#fff", fontSize: 12, marginLeft: 15 }}
          />
        </Tooltip>
      )}
      {props.midContent}
      <div style={{ marginTop: 8, width: "320px" }}>
        <Button
          onClick={(event) => {
            if (props.onClick) {
              props.onClick(event);
            }
          }}
          type="primary"
          icon={<PlusOutlined></PlusOutlined>}
        >
          <label>{props.placeholder}</label>
        </Button>
      </div>
    </div>
  );
}

function AddApplication(props) {
  const [reqBody, setReqBody] = useState({
    name: "",
    eventCallback: "",
    javaScriptOrigins: [],
    authorizedRedirectUrls: [],
    privacyPolicyUrl: "",
    termsOfUseUrl: "",
  });
  const [displayLoading, setDisplayLoading] = useState(false);
  const [showAfterCreateModal, setShowAfterCreateModal] = useState(false);
  const [createResponse, setCreateResponse] = useState({});

  const _renderTitle = () => {
    const _navParams = props.location?.state;
    return (
      <Row style={{ marginTop: 32, marginLeft: 32 }}>
        <Col span={20}>
          <Typography.Title style={{ fontSize: 28 }}>
            {_navParams?.mode === "edit"
              ? "Edit Application"
              : "Add New Application"}
          </Typography.Title>
        </Col>
      </Row>
    );
  };

  const closeModal = () => {
    setShowAfterCreateModal(false);
    props.history.goBack();
  };

  // useEffect(() => {
  //   if (showAfterCreateModal) {
  //     _renderAfterCreateModal()
  //   }

  //   // return () => {
  //   //   second
  //   // }
  // }, [showAfterCreateModal])

  useEffect(() => {
    if (props.location?.state?.mode === "edit") {
      prePopulateEditBody();
    }
  }, []);

  const prePopulateEditBody = () => {
    let _navParams = props.location?.state;
    if (_navParams?.mode === "edit") {
      // console.log(_navParams);
      let _jsOrigins = [];
      let _callbackUrls = [];
      if (_navParams.selectedRecord?.javascriptURLs?.length > 0) {
        _navParams.selectedRecord.javascriptURLs.map((item) => {
          _jsOrigins.push(item.URL);
        });
      }
      if (_navParams.selectedRecord?.callbackURLs?.length > 0) {
        _navParams.selectedRecord.callbackURLs.map((item) => {
          _callbackUrls.push(item.URL);
        });
      }
      setReqBody({
        eventCallback: _navParams.selectedRecord.eventCallback,
        javaScriptOrigins: _jsOrigins,
        authorizedRedirectUrls: _callbackUrls,
        name: _navParams.selectedRecord.name,
        privacyPolicyUrl: _navParams.selectedRecord.privacyPolicy,
        termsOfUseUrl: _navParams.selectedRecord.termsOfUsage,
      });
    }
  };

  const _renderSubmitButtons = () => {
    const _navParams = props.location?.state;
    return (
      <Row style={{ marginTop: 32, marginLeft: 16, marginBottom: 32 }}>
        <Col sm={4} xs={21}>
          <Button
            className="sign-in"
            disabled={reqBody.name === ""}
            block
            onClick={() => {
              createNewApplication();
            }}
            type="primary"
          >
            <label>{"Save"}</label>
          </Button>
        </Col>
        <Col className="addApplication-cancle" sm={4} xs={21}>
          <Button
            block
            onClick={() => {
              props.history.goBack();
            }}
            danger
            type="primary"
          >
            <label>Cancel</label>
          </Button>
        </Col>
      </Row>
    );
  };

  const onFormKeyUpdated = (key, value) => {
    let _reqBody = Object.assign({}, reqBody);
    _reqBody[key] = value;
    setReqBody(_reqBody);
  };

  const _renderJSOrigins = () => {
    return (
      <div>
        {reqBody.javaScriptOrigins.map((originItem, index) => {
          // console.log(index);
          return (
            <div style={{ margin: "8px 0px" }}>
              <MultiURLInput
                index={index}
                onChange={(event) => {
                  let _reqBody = Object.assign({}, reqBody);
                  _reqBody.javaScriptOrigins[index] = event.target.value;
                  setReqBody(_reqBody);
                }}
                onClose={(event, _index) => {
                  let _reqBody = Object.assign({}, reqBody);
                  _reqBody.javaScriptOrigins.splice(_index, 1);
                  setReqBody(_reqBody);
                }}
                placeholder={"URL"}
                value={originItem}
              ></MultiURLInput>
            </div>
          );
        })}
      </div>
    );
  };

  const _renderRedirectUrls = () => {
    return (
      <div>
        {reqBody.authorizedRedirectUrls.map((originItem, index) => {
          return (
            <div style={{ margin: "8px 0px" }}>
              <MultiURLInput
                index={index}
                onChange={(event) => {
                  let _reqBody = Object.assign({}, reqBody);
                  _reqBody.authorizedRedirectUrls[index] = event.target.value;
                  setReqBody(_reqBody);
                }}
                onClose={(event, _index) => {
                  let _reqBody = Object.assign({}, reqBody);
                  _reqBody.authorizedRedirectUrls.splice(_index, 1);
                  setReqBody(_reqBody);
                }}
                placeholder={"URL"}
                value={originItem}
              ></MultiURLInput>
            </div>
          );
        })}
      </div>
    );
  };

  const _renderAddApplicationForm = () => {
    return (
      <div className="add-setting-container">
        <Typography.Title className="add-setting-title">
          General App Settings
        </Typography.Title>
        <TitleText
          value={reqBody.name}
          label={"Name"}
          onChange={(event) => {
            onFormKeyUpdated("name", event.target.value);
          }}
          placeholder={"Name of the application"}
          info={true}
        ></TitleText>
        <div style={{ marginTop: 28 }}></div>
        <AuthorizationButton
          info={true}
          midContent={_renderJSOrigins()}
          onClick={() => {
            let _reqBody = Object.assign({}, reqBody);
            _reqBody.javaScriptOrigins.push("");
            setReqBody(_reqBody);
          }}
          label={"Authorized Javascript Origins"}
          placeholder={"Add URI"}
        ></AuthorizationButton>
        <div style={{ marginTop: 28 }}></div>
        <AuthorizationButton
          info={true}
          onClick={() => {
            let _reqBody = Object.assign({}, reqBody);
            _reqBody.authorizedRedirectUrls.push("");
            setReqBody(_reqBody);
          }}
          midContent={_renderRedirectUrls()}
          label={"Authorized redirect URIs"}
          placeholder={"Add URI"}
        ></AuthorizationButton>
        <div style={{ marginTop: 28 }}></div>
        <TitleText
          value={reqBody.eventCallback}
          onChange={(event) => {
            onFormKeyUpdated("eventCallback", event.target.value);
          }}
          label={"Event callback URL"}
          placeholder={"URL"}
          info={true}
        ></TitleText>
        <div style={{ marginTop: 28 }}></div>
        <TitleText
          value={reqBody.privacyPolicyUrl}
          onChange={(event) => {
            onFormKeyUpdated("privacyPolicyUrl", event.target.value);
          }}
          label={"Privacy policy"}
          placeholder={"URL"}
          info={true}
        ></TitleText>
        <div style={{ marginTop: 28 }}></div>
        <TitleText
          value={reqBody.termsOfUseUrl}
          onChange={(event) => {
            onFormKeyUpdated("termsOfUseUrl", event.target.value);
          }}
          label={"Terms of use"}
          placeholder={"URL"}
          info={true}
        ></TitleText>
      </div>
    );
  };

  const createNewApplication = () => {
    if (checkValidation()) {
      setDisplayLoading(true);
      let _callBackUrls = [];
      reqBody.authorizedRedirectUrls.map((item) => {
        _callBackUrls.push({
          url: item,
        });
      });
      let _jsUrls = [];
      reqBody.javaScriptOrigins.map((item) => {
        _jsUrls.push({
          url: item?.endsWith("/") ? item.slice(0, -1) : item,
        });
      });
      let _navParams = props.location?.state;
      if (_navParams?.mode === "edit") {
        editApplication(_navParams.selectedRecord?.id, {
          name: reqBody.name,
          javascriptURLs: _jsUrls,
          eventURL: reqBody.eventCallback,
          callbackURLs: _callBackUrls,
          privacyPolicy: reqBody.privacyPolicyUrl,
          termsOfUsage: reqBody.termsOfUseUrl,
        })
          .then((response) => {
            // alert("Application edited successfully");
            setDisplayLoading(false);
            props.history.goBack();
          })
          .catch((error) => {
            console.log(error);
            setDisplayLoading(false);
            alert("Unable to edit the application at the moment");
          });
      } else {
        addApplication({
          name: reqBody.name,
          javascriptURLs: _jsUrls,
          eventURL: reqBody.eventCallback,
          callbackURLs: _callBackUrls,
          privacyPolicy: reqBody.privacyPolicyUrl,
          termsOfUsage: reqBody.termsOfUseUrl,
        })
          .then((response) => {
            // message.success("New application created successfully");
            setDisplayLoading(false);
            _afterCreateModal(response.data);
          })
          .catch((error) => {
            setDisplayLoading(false);
            message.error(
              "Unable to create the application due to: " + error.message
            );
          });
      }
    }
  };

  function _afterCreateModal(data) {
    setShowAfterCreateModal(true);
    setCreateResponse(data);
  }

  const checkValidation = () => {
    let _validation = true;
    let _urlRegEx = new RegExp(
      "(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})",
      "gmi"
    );
    let _reqBody = Object.assign({}, reqBody);
    if (_reqBody.name.trim() === "") {
      _validation = false;
      notification.error({
        message: "Invalid name",
        description: "Please enter a valid name",
      });
    }
    if (_reqBody.javaScriptOrigins.length <= 0) {
      _validation = false;
      notification.error({
        message: "Invalid javascript origins",
        description: "Please enter a valid javascript origin",
      });
    }
    if (_reqBody.authorizedRedirectUrls.length <= 0) {
      _validation = false;
      notification.error({
        message: "Invalid authorization urls",
        description: "Please enter a valid authorization urls",
      });
    }
    if (!_urlRegEx.test(_reqBody.eventCallback)) {
      _validation = false;
      notification.error({
        message: "Invalid event callback url",
        description: "Please enter a valid event callback url",
      });
    }
    // if (!_urlRegEx.test(_reqBody.privacyPolicyUrl)) {
    //   _validation = false;
    //   notification.error({
    //     message: "Invalid privacy policy url",
    //     description: "Please enter a valid privacy policy url"
    //   })
    // } if (!_urlRegEx.test(_reqBody.termsOfUseUrl)) {
    //   _validation = false;
    //   notification.error({
    //     message: "Invalid terms of use url",
    //     description: "Please enter a valid terms of use url"
    //   })
    // }
    return _validation;
  };

  const renderAppIdAndSecret = () => {
    const _navParams = props.location?.state;
    if (_navParams?.mode === "edit") {
      let _selectedRecord = _navParams?.selectedRecord;
      return (
        <div className="add-setting-container">
          <Typography.Title className="add-setting-title">
            Client ID Settings
          </Typography.Title>
          <Row align="middle" style={{ height: "100%" }}>
            <Col>
              <Typography.Text style={{ fontSize: 18, fontWeight: "400" }}>
                {"App ID : "}
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text
                strong
                copyable={{
                  icon: [
                    <CopyOutlined
                      style={{ color: "#fff", fontSize: 20, marginLeft: 5 }}
                      key="copy-icon"
                    />,
                    <CheckCircleOutlined
                      style={{ color: "#5c2", fontSize: 20, marginLeft: 5 }}
                      key="copied-icon"
                    />,
                  ],
                  tooltips: ["Copy", "Copied!!"],
                }}
                style={{ fontSize: 18, fontWeight: "400", marginLeft: 10 }}
              >
                {_selectedRecord.id}
              </Typography.Text>
            </Col>
          </Row>
          {/* <TitleText  disabled label={"App ID"} value={_selectedRecord.id}></TitleText> */}
          <div style={{ marginTop: 16 }}></div>
          <Row align="middle" style={{ height: "100%" }}>
            <Col>
              <Typography.Text style={{ fontSize: 18, fontWeight: "400" }}>
                {"Secret Key :"}
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text
                strong
                copyable={{
                  text: _selectedRecord.secretKey,
                  icon: [
                    <CopyOutlined
                      style={{ color: "#fff", fontSize: 20, marginLeft: 5 }}
                      key="copy-icon"
                    />,
                    <CheckCircleOutlined
                      style={{ color: "#5c2", fontSize: 20, marginLeft: 5 }}
                      key="copied-icon"
                    />,
                  ],
                  tooltips: ["Copy", "Copied!!"],
                }}
                style={{ fontSize: 18, fontWeight: "400", marginLeft: 10 }}
              >
                {"**********************"}
              </Typography.Text>
            </Col>
          </Row>
          {/* <TitleText type={"password"} disabled label={"Secret Key"} value={_selectedRecord.secretKey}></TitleText> */}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const _renderAfterCreateModal = () => {
    return (
      <Modal
        width={600}
        className="appCreatedModal"
        closeIcon={<CloseOutlined style={{ color: "#fff" }} />}
        bodyStyle={{ paddingTop: 0, backgroundColor: "#1d1d1d" }}
        onOk={closeModal}
        title={<Typography.Title level={3}>OAuth app created</Typography.Title>}
        visible={showAfterCreateModal}
        footer={[
          <Button type="primary" onClick={closeModal}>
            OK
          </Button>,
        ]}
        onCancel={closeModal}
      >
        <Typography.Text>
          Your app is created! Please use the following app id and secret for
          integrating lockr APIs.{" "}
        </Typography.Text>
        <Typography.Text>
          The app ID and secret key can be accessed later when you select the
          application on dashboard.{" "}
        </Typography.Text>
        <Row align="middle" style={{ height: "100%", marginTop: 16 }}>
          <Col>
            <Typography.Text style={{ fontSize: 18, fontWeight: "400" }}>
              {"App ID : "}
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text
              strong
              copyable={{
                icon: [
                  <CopyOutlined
                    style={{ color: "#fff", fontSize: 20, marginLeft: 5 }}
                    key="copy-icon"
                  />,
                  <CheckCircleOutlined
                    style={{ color: "#5c2", fontSize: 20, marginLeft: 5 }}
                    key="copied-icon"
                  />,
                ],
                tooltips: ["Copy", "Copied!!"],
              }}
              style={{ fontSize: 18, fontWeight: "400", marginLeft: 10 }}
            >
              {createResponse?.app_id}
            </Typography.Text>
          </Col>
        </Row>
        <div style={{ marginTop: 16 }}></div>
        <Row align="middle" style={{ height: "100%" }}>
          <Col>
            <Typography.Text style={{ fontSize: 18, fontWeight: "400" }}>
              {"Secret Key :"}
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text
              strong
              copyable={{
                text: createResponse?.secret_key,
                icon: [
                  <CopyOutlined
                    style={{ color: "#fff", fontSize: 20, marginLeft: 5 }}
                    key="copy-icon"
                  />,
                  <CheckCircleOutlined
                    style={{ color: "#5c2", fontSize: 20, marginLeft: 5 }}
                    key="copied-icon"
                  />,
                ],
                tooltips: ["Copy", "Copied!!"],
              }}
              style={{ fontSize: 18, fontWeight: "400", marginLeft: 10 }}
            >
              {"**********************"}
            </Typography.Text>
          </Col>
        </Row>
      </Modal>
    );
  };

  return (
    <SideDrawer>
      <Spin spinning={displayLoading}>
        {_renderTitle()}
        {renderAppIdAndSecret()}
        {_renderAddApplicationForm()}
        {_renderSubmitButtons()}
        {showAfterCreateModal && _renderAfterCreateModal()}
      </Spin>
    </SideDrawer>
  );
}

export default withRouter(AddApplication);
