/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import SideDrawer from "../../../Components/SideDrawer";
import { Button, Col, Row, Typography } from "antd";
import {
  deleteGoogleAuthFile,
  getGoogleAuthFile,
  listAllApplications,
  uploadGoogleAuthFile,
} from "../../../Utils/ApiActions";
import { MdChevronRight } from "react-icons/md";
import DeleteTypeModal from "../../../Components/LockrDeleteModal";

export function GoogleAuthentication() {
  const [appList, setAppList] = useState([]);
  const [selectedApp, setSelectedApp] = useState(undefined);
  const [showDeleteForGoogleAuthFile, setShowDeleteForGoogleAuthFile] =
    useState(false);

  const [googleAuthFile, setGoogleAuthFile] = useState(undefined);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const googleAuthFileRef = useRef();

  useEffect(() => {
    fetchAppList();
  }, []);

  useEffect(() => {
    !!selectedApp && getGoogleAuthStoreCall();
  }, [selectedApp]);

  async function fetchAppList() {
    try {
      const response = await listAllApplications();
      if (response.data?.length) {
        setAppList(response.data);
        !selectedApp && setSelectedApp(response.data[0]);
      }
    } catch (error) {
      console.log("Unable to get the list of the apps");
      console.log(error);
    }
  }

  function renderApplist() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 0.5,
          background: "black",
          borderRadius: 8,
          maxWidth: 300,
          maxHeight: "calc(100vh - 410px)",
          overflow: "scroll",
        }}
      >
        {appList?.length &&
          appList.map((item) => {
            console.log("🚀 ~ appList.map ~ item:", item);
            return (
              <>
                <div
                  onClick={() => {
                    setSelectedApp(item);
                  }}
                  style={{
                    cursor: "pointer",
                    minHeight: 60,
                    borderBottom: "1px solid #ffffff22",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 20,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                    }}
                  >
                    <label
                      style={{
                        fontSize: 18,
                        color:
                          selectedApp?.id === item.id ? "#61CEF7" : "white",
                      }}
                    >
                      {item.name}
                    </label>
                  </div>
                  <MdChevronRight
                    style={{
                      color: selectedApp?.id === item.id ? "#61CEF7" : "white",
                      fontSize: 22,
                    }}
                  />
                </div>
              </>
            );
          })}
      </div>
    );
  }

  async function deleteGoogleAuthStoreCall() {
    try {
      await deleteGoogleAuthFile(selectedApp.id);
      getGoogleAuthStoreCall();
    } catch (error) {
      console.log("Unable to delete the google auth file at the moment");
      console.log(error);
    }
  }

  async function getGoogleAuthStoreCall() {
    try {
      const data = await getGoogleAuthFile(selectedApp.id);
      setGoogleAuthFile(data.data);
    } catch (error) {
      console.log("Unable to get the google auth file at the moment");
      console.log(error);
    }
  }

  async function updateGoogleAuthStoreCall(jsonData, fileName) {
    try {
      await uploadGoogleAuthFile(selectedApp.id, jsonData, fileName);
      getGoogleAuthStoreCall();
    } catch (error) {
      console.log("Unable to upload the google auth file at the moment");
      console.log(error);
    }
  }

  function handleGoogleFileUpload(event) {
    console.log(event.target.files);
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      console.log(text);
      updateGoogleAuthStoreCall(text, file.name);
    };
    reader.readAsText(file);
  }

  function renderGoogleAuthSettings() {
    return (
      <>
        <div
          style={{
            background: "#000000",
            minHeight: "140px",
            position: "relative",
            width: "100%",
            borderRadius: 8,
            padding: 16,
            marginTop: 16,
          }}
        >
          <div
            style={{
              flexDirection: "column",
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ flexDirection: "column", display: "flex" }}>
                <label
                  style={{
                    color: "white",
                    fontWeight: "400",
                    fontFamily: "Montserrat",
                    fontSize: 14,
                  }}
                >
                  {"Google Custom Authentication"}
                </label>
                <span
                  style={{
                    color: "#8B8B8B",
                    fontSize: 12,
                    fontWeight: "500",
                    fontFamily: "Montserrat",
                    marginTop: 16,
                  }}
                >
                  lockr Generates the Google Authentication Token for the custom
                  google login, upload the private key to enable it
                </span>
              </div>
              <Button
                type="primary"
                style={{
                  marginLeft: 16,
                  marginRight: 32,
                }}
                className="sign-in vendor-add-url-button"
                disabled={!!googleAuthFile}
                onClick={() => {
                  googleAuthFileRef?.current?.click?.();
                }}
              >
                Upload
              </Button>
              <input
                onChange={handleGoogleFileUpload}
                accept="application/json"
                ref={googleAuthFileRef}
                type="file"
                style={{ display: "none" }}
              />
            </div>
          </div>
          {!!googleAuthFile && (
            <>
              <div
                style={{
                  marginTop: 40,
                  height: 1,
                  width: "100%",
                  background: "#353738",
                  marginBottom: 40,
                }}
              />
              <span className="sensitivity-setting-domain-title">
                Your uploaded private key:
              </span>
              <div
                onMouseLeave={() => {
                  setShowDeleteForGoogleAuthFile(false);
                }}
                onMouseEnter={() => {
                  setShowDeleteForGoogleAuthFile(true);
                }}
                style={{
                  marginTop: 20,
                }}
              >
                <span
                  style={{
                    color: "#50E8B1",
                    fontSize: 14,
                    fontWeight: "700",
                    marginTop: 40,
                    fontFamily: "Montserrat",
                  }}
                >
                  {googleAuthFile.originalName}
                </span>
                <img
                  onClick={() => {
                    googleAuthFileRef?.current?.click?.();
                  }}
                  style={{
                    marginLeft: "8px",
                    height: "18px",
                    cursor: "pointer",
                    visibility: showDeleteForGoogleAuthFile
                      ? "visible"
                      : "hidden",
                    width: "18px",
                  }}
                  alt="edit"
                  src={require("../../../assets/setting-icon_no_border.png")}
                />
                <img
                  onClick={() => {
                    // deleteGoogleAuthStoreCall();
                    setShowDeleteConfirmation(true);
                  }}
                  style={{
                    marginLeft: "10px",
                    height: "18px",
                    cursor: "pointer",
                    visibility: showDeleteForGoogleAuthFile
                      ? "visible"
                      : "hidden",
                    width: "18px",
                  }}
                  alt="delete"
                  src={require("../../../assets/domain_remove.png")}
                />
              </div>
            </>
          )}
        </div>
      </>
    );
  }

  function renderAppDetails() {
    if (!selectedApp) {
      return <></>;
    }
    return (
      <div
        style={{
          width: "100%",
          flex: 1,
          paddingLeft: 8,
          paddingRight: 8,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: 16,
            paddingTop: 16,
          }}
        >
          <label
            className="app-list-details-header"
            style={{
              color: "white",
              fontSize: 24,
              fontWeight: "400",
            }}
          >
            {selectedApp?.name}
          </label>
        </div>
        {renderGoogleAuthSettings()}
      </div>
    );
  }

  return (
    <SideDrawer>
      <Row
        justify="space-between"
        align="middle"
        className="apiandservices-newapp-btn"
      >
        <Typography.Title className="apiandservices-heading">
          Google Custom Authentication
        </Typography.Title>
        <div style={{ marginTop: 8 }} />
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <label
            style={{ fontSize: 14, fontFamily: "Montserrat", color: "#8B8B8B" }}
            className="dashboard-as-of-today"
          >
            lockr Generates the Google Authentication Token for the custom
            google login, upload the private key to enable it
          </label>
        </div>
      </Row>
      <div
        style={{
          width: "100%",
          paddingLeft: 90,
          paddingRight: 90,
          display: "flex",
          flexDirection: "row",
          gap: 16,
          overflow: "hidden",
        }}
      >
        {renderApplist()}
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            background: "black",
          }}
        >
          {renderAppDetails()}
        </div>
      </div>
      {showDeleteConfirmation && (
        <DeleteTypeModal
          visible={showDeleteConfirmation}
          title="Delete File"
          message={`Are you sure you want to delete the file?
        Deleting the file will stop the generation of the Google token in the authentication response.`}
          confirmText={"Please type the word “DELETE” below to confirm"}
          handleCancel={() => {
            setShowDeleteConfirmation(false);
          }}
          handleOk={() => {
            deleteGoogleAuthStoreCall();
            setShowDeleteConfirmation(false);
          }}
        />
      )}
    </SideDrawer>
  );
}
