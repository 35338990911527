import { Button, Space, Table, Typography, message, Spin, Empty } from "antd";
import { useEffect, useState } from "react";
import InitiateConnectionModal from "../../../Components/InitiateConnectionModal";
import { identitProvidersType } from "../../../Utils/Helper";
import {
  deleteBatchProcess,
  fetchBatchData,
  startProcess,
} from "../../../Utils/ApiActions";
import { ShowDeleteConfirm } from "../../../App";

function getIdentityProviderName(type) {
  const providerNames = {
    [identitProvidersType.UID]: "UID2",
    [identitProvidersType.RAMPID]: "LiveRamp",
    [identitProvidersType.ID5]: "ID5",
    [identitProvidersType.EUID]: "EUID"
  };

  return providerNames[type] || "Unknown Provider";
}

export function BatchUpload({idType = identitProvidersType.RAMPID}) {
  // Component to display status dots
  const StatusDot = ({ color }) => (
    <span
      style={{
        height: 12,
        width: 12,
        borderWidth: 1,
        borderRadius: "50%",
        background: color,
        marginRight: "8px",
      }}
    />
  );

  // State management
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [selectedBatchId, setSelectedBatchId] = useState(null);
  // Function to fetch batch details from the API
  const fetchBatchDetails = async () => {
    setLoading(true);
    try {
      const response = await fetchBatchData(idType);
      setDataSource(Array.isArray(response) ? response : []);
    } catch (error) {
      console.error("Error fetching batch details:", error);
      message.error("Failed to fetch batch details.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchBatchDetails();
  }, []);

  useEffect(() => {
    console.log("Modal state changed:", isModalOpen);
  }, [isModalOpen]);

  // Handle modal open with type
  const OpenConnectionModal = (type = "connection", batchProcessID) => {
    // Default type
    console.log(
      "Called OpenConnectionModal with type:",
      type,
      "With Batch ID",
      batchProcessID
    );
    setModalType(type);
    setIsModalOpen(true);
    console.log("setIsModalOpen to true");
    if (batchProcessID) {
      console.log("Batch ProcessId", batchProcessID);
      setSelectedBatchId(batchProcessID);
    }
  };

  // Handle modal close and refresh data
  const closConnectioneModal = () => {
    console.log("Called closConnectioneModal");
    setIsModalOpen(false);
    console.log("setIsModalOpen to false");
    setModalType(null); // Reset modal type
    fetchBatchDetails();
  };

  // Define table columns
  const logsColumns = [
    {
      title: "File Name",
      key: "fileName",
      dataIndex: "fileName",
      width: "20%",
      align: "left",
    },
    {
      title: "Upload Date/Time",
      key: "date_time",
      dataIndex: "createdAt",
      width: "20%",
      align: "right",
      render: (text) => new Date(text).toLocaleString(), // Format date
    },
    {
      title: "LiveRamp Token Count",
      key: "token_count",
      dataIndex: "totalRecords",
      width: "20%",
      align: "right",
      render: (count) => parseInt(count, 10).toLocaleString(), // Format number
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      align: "right",
      width: "20%",
      render: (status) => {
        let color = "";
        if (status === "Loaded") color = "#8B8B8B";
        if (status === "Processing") color = "#FAC748";
        if (status === "Completed") color = "#50E8B1";

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <StatusDot color={color} />
            <span
              style={{
                color: "white",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "18px",
              }}
            >
              {status}
            </span>
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      dataIndex: "actions",
      align: "right",
      width: "20%",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Space>
              {record.status === "Loaded" && (
                <>
                  <Button
                    className="batch-button"
                    type="primary"
                    onClick={() => handleRunAnalysis(record)}
                  >
                    Run Analysis
                  </Button>
                  {/* 
                  <Button
                    className="batch-button"
                    type="primary"
                    onClick={() => handleSendToUID2(record)}
                  >
                    Send to UID2
                  </Button> 
                  */}
                </>
              )}
              {record.status === "Processing" && (
                <>
                  <Button
                    type="primary"
                    className="batch-button batch-button-disabled"
                    disabled
                  >
                    Run Analysis
                  </Button>
                  {/* 
                  <Button
                    className="batch-button batch-button-disabled"
                    disabled
                    type="primary"
                  >
                    Send to UID2
                  </Button>
                  */}
                </>
              )}
              {record.status === "Completed" && (
                <>
                  <Button
                    type="primary"
                    className="batch-button"
                    onClick={() => OpenConnectionModal("endpoint", record.id)}
                  >
                    Select Data Endpoint
                  </Button>
                </>
              )}
              {/* {record.status === "Processing" ? (
                <img
                  style={{
                    marginLeft: 16,
                    height: 16,
                    cursor: "pointer",
                    width: 16,
                  }}
                  alt="stop"
                  src={require("../../../assets/stop_icon.png")}
                  onClick={() => handleStopProcessing(record)}
                />
              ) : ( */}
              <ShowDeleteConfirm
                displayLoading={loading}
                record={record}
                title={"Delete Batch"}
                confirmDelete={handleRemoveRecord}
              />
              {/* <img
                  style={{
                    marginLeft: 16,
                    height: 16,
                    cursor: record.status !== "Processing" ? "pointer" : "not-allowed",
                    width: 16,
                  }}
                  alt="remove"
                  src={require("../../../assets/remove_icon.png")}
                  onClick={() => record.status !== "Processing" && handleRemoveRecord(record)}
                /> */}
              {/* )} */}
            </Space>
          </div>
        );
      },
    },
  ];

  // Action button handlers
  const handleRunAnalysis = async (record) => {
    try {
      await startProcess(record.id);
      // message.success(`Analysis started for ${record.fileName}.`);
      fetchBatchDetails(); // Refresh data to reflect status changes
    } catch (error) {
      console.error("Error running analysis:", error);
      message.error("Failed to start analysis.");
    }
  };

  const handleSelectDataEndpoint = (record) => {
    // Implement the logic to select data endpoint
    message.info(`Selecting data endpoint for ${record.fileName}`);
    // Example: Open another modal or navigate to a different page
  };

  const handleStopProcessing = async (record) => {
    try {
      message.success(`Processing stopped for ${record.fileName}.`);
      fetchBatchDetails(); // Refresh data to reflect status changes
    } catch (error) {
      console.error("Error stopping processing:", error);
      message.error("Failed to stop processing.");
    }
  };

  const handleRemoveRecord = async (record) => {
    try {
      await deleteBatchProcess(record.id ? record.id : record);
      message.success(`Removed ${record.fileName} successfully.`);
      fetchBatchDetails(); // Refresh data after deletion
    } catch (error) {
      console.error("Error removing record:", error);
      message.error("Failed to remove the record.");
    }
  };

  return (
    <>
      <div style={{ marginTop: 50 }} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "24px",
          color: "white",
          fontFamily: "Montserrat",
          marginBottom: 20,
        }}
      >
        {/* Status Indicators */}
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <StatusDot color="#8B8B8B" />
          <div style={{ fontSize: "14px", lineHeight: "18px" }}>Loaded</div>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <StatusDot color="#FAC748" />
          <div style={{ fontSize: "14px", lineHeight: "18px" }}>Processing</div>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <StatusDot color="#50E8B1" />
          <div style={{ fontSize: "14px", lineHeight: "18px" }}>Completed</div>
        </div>
      </div>
      <div
        style={{ background: "black", padding: "20px", borderRadius: "8px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography.Title
            style={{
              fontSize: "20px",
              fontWeight: "700",
              fontFamily: "Montserrat",
              marginBottom: "0",
            }}
          >
            Batch upload {getIdentityProviderName(idType)}
          </Typography.Title>
          <Button
            className="dashboard-download-btn"
            style={{ marginTop: "0px" }}
            type="primary"
            shape="default"
            onClick={() => OpenConnectionModal()}
          >
            Initiate Connection
          </Button>
        </div>
        {loading ? (
          <div style={{ textAlign: "center", padding: "50px 0" }}>
            <Spin size="large" />
          </div>
        ) : (
          <Table
            scroll={{ x: "max-content" }}
            style={{ overflowWrap: "anywhere", marginTop: "24px" }}
            className="dashboard-tables no-border-table"
            rowKey={"id"}
            columns={logsColumns}
            dataSource={dataSource}
            pagination={{ pageSize: 10 }}
            locale={{
              emptyText: (
                <div
                  style={{
                    textAlign: "center",
                    padding: "20px 0",
                    color: "#8B8B8B",
                    fontSize: "16px",
                  }}
                >
                  No batch data found.
                </div>
              ),
            }}
          />
        )}
      </div>
      <InitiateConnectionModal
        visible={isModalOpen}
        onClose={closConnectioneModal}
        idType={idType}
        type={modalType}
        batchProcessID={selectedBatchId}
      />
    </>
  );
}
