/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal,  Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getRejectedEmailList } from "../../../Utils/ApiActions";
import { LockrFromToDatePicker } from "../../Connections/components/IdentityProviderStatsAuthStrip";
import {useJsonToCsv} from 'react-json-csv';

export function RejectedEmailsModal({
    visible,
    startDate,
    endDate,
    onClose
}) {

    const [modalStartDate, setModalStartDate] = useState(startDate ?? moment().subtract('7', 'day'));
    const [modalEndDate, setModalEndDate] = useState(endDate ?? moment());
    const [rejectedEmailList, setRejectedEmailList] = useState([]);

    const {saveAsCsv} = useJsonToCsv();

    useEffect(() => {
        fetchRejectedEmails();
    }, [modalEndDate, modalStartDate]);

    async function fetchRejectedEmails() {
        try {
            const response = await getRejectedEmailList(
                modalStartDate.valueOf(),
                modalEndDate.valueOf(),
            )
            console.log("🚀 ~ fetchRejectedEmails ~ response:", response)
            setRejectedEmailList(response.data);
        } catch (error) {
            console.log('Unabe to get the email list at the moment');
            console.log(error);
        }
    }

    return (
        <Modal
            transitionName=""
            maskTransitionName=""
            centered
            width={716}
            bodyStyle={{
                height: 566,
                width: 716,
                backgroundColor: "#353738",
                paddingLeft: 60,
                paddingTop: 40,
                overflowX: "hidden",
                paddingRight: 0,
            }}
            maskStyle={{ backdropFilter: "blur(8px)" }}
            visible={visible}
            onOk={() => { }}
            onCancel={() => { onClose(); }}
            footer={null}
            closable={false}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden'
            }}>
                <Typography.Title
                    style={{
                        display: "inline-block",
                        fontFamily: "Montserrat",
                        color: "#fff",
                        fontWeight: 700,
                        fontSize: 32,
                    }}
                >
                    Recently rejected emails
                </Typography.Title>
                <img
                    alt="close"
                    onClick={() => {
                        onClose();
                    }}
                    style={{ position: 'absolute', right: 60, top: 48, cursor: 'pointer'  }}
                    src={require("../../../assets/modal-close.png")}
                ></img>
                <span style={{
                    fontFamily: 'Montserrat',
                    color: '#EDEDED',
                    fontSize: 16,
                    fontWeight: '400',
                    marginTop: -5
                }}>You have <span
                    style={{
                        backgroundColor: '#50E8B1',
                        color: '#171717',
                        fontWeight: '600',
                        fontSize: 12,
                        padding: 2,
                        fontFamily: 'Montserrat',
                        borderRadius: 5,
                    }}
                >{rejectedEmailList.length}</span> rejected emails</span>
                <div style={{
                    background: '#26282A',
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 60,
                    overflow: 'auto',
                    height: 312,
                    marginTop: 40,
                    paddingLeft: 20,
                    paddingRight: 20,
                }}>
                    {rejectedEmailList?.length ? rejectedEmailList.map(emailitem => {
                        return (
                            <span
                                style={{
                                    color: 'white',
                                    fontFamily: 'Montserrat',
                                    fontWeight: '400',
                                    fontSize: 16,
                                    marginTop: 20,
                                    paddingBottom: 20,
                                    borderBottom: '1px solid #3F4142',
                                }}
                            >{emailitem.lockrEmail}</span>
                        )
                    }) : <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <span style={{ color: "white" }}>There is no data available for the selected date range.</span>
                    </div>}
                </div>
                <div style = {{
                    display: 'flex',
                    flexDirection: 'row',                    
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingRight: 60
                }}>
                    <div style={{
                        width: 392,
                        marginTop: 16,
                    }}>
                        <LockrFromToDatePicker
                            startDate={modalStartDate}
                            endDate={modalEndDate}
                            onChangeEndDate={setModalEndDate}
                            onChangeStartDate={setModalStartDate}
                        />                        
                    </div>
                    <div style = {{
                        paddingTop: 18,
                    }}>
                    <Button onClick={()=>{
                        saveAsCsv({
                            data: rejectedEmailList,
                            fields: {
                                'lockrEmail': "Email"
                            },
                            filename: 'rejected-emails'
                        })
                    }} style = {{
                        height: 38,
                        color: 'black',
                        fontFamily: 'Montserrat',
                        fontWeight: '700',
                        paddingLeft: 48,
                        fontSize: 14,
                        paddingRight: 48,
                    }}>Export</Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}