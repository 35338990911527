import { Affix, Button, Col, Dropdown, Input, Modal, Popover, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import SideDrawer from "../../Components/SideDrawer";
import { InfoCircleOutlined } from "@ant-design/icons";
import { changePassword, deletUser, editProfile, editSelfUser, editUser, getProfile, getVerticalList } from "../../Utils/ApiActions";
import { getTimeZones } from "@vvo/tzdb";
import { AiOutlineDown } from 'react-icons/ai';
import PhoneInput from 'react-phone-input-2'
import '../../phone-style.css'
import { PhoneNumberInput } from "../../Components/PhoneNumberInput";
import iconChecked from '../../assets/checkbox1.svg';
import sanitize from 'sanitize-html'
import { validateInput } from "../../Utils/Helper";



const ShowDeleteConfirm = (props) => {
    const [confirmDeleteModal, setConfimDeleteModal] = useState("");
    const [beforeDeleteModal, setBeforeDeleteModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false);

    const handleOk = () => {
        setDeleteModal(false);
        setConfimDeleteModal("");
    };

    const handleCancel = () => {
        setDeleteModal(false);
        setConfimDeleteModal("");
    };
    return (
        <div style={{ display: "inline" }}>
            <Button onClick={() => setBeforeDeleteModal(true)} type="default" style={{ height: 48, width: 172, background: 'transparent', borderColor: '#F46666', color: '#F46666', fontFamily: 'Montserrat', fontSize: 16, fontWeight: 'bold' }}>Delete</Button>
            <Modal
                transitionName=""
                maskTransitionName=""
                width={600}
                bodyStyle={{
                    width: 600,
                    backgroundColor: "#353738",
                    paddingLeft: 60,
                    paddingTop: 40,
                    paddingRight: 0,
                    paddingBottom: 60,
                    overflowX: "hidden",
                }}
                closable={false}
                footer={null}
                visible={beforeDeleteModal}
                onOk={() => { setBeforeDeleteModal(false) }}
                onCancel={() => { setBeforeDeleteModal(false) }}
                className="delete-app-modal-forinput"
            >
                <div>
                    <Typography.Title
                        style={{
                            display: "inline-block",
                            fontFamily: "Montserrat",
                            color: "#fff",
                            fontWeight: 700,
                            fontSize: 34,
                            paddingBottom: 20,
                            paddingTop: 8,
                        }}
                    >
                        Just a minute...
                    </Typography.Title>
                    <img
                        onClick={() => { setBeforeDeleteModal(false) }}

                        src={require("../../assets/modal-close.png")}
                        className="delete-modal-x-button"
                    ></img>
                </div>
                <div style={{ marginRight: 60 }}>
                    <Typography.Text>
                        Are you sure you want to delete your account? Any live applications that are currently using the lockr API will be impacted.

                    </Typography.Text>
                </div>
                <Button


                    style={{ display: "block", marginTop: 40, width: 180, height: 50 }}
                    onClick={() => {
                        setBeforeDeleteModal(false);
                        setDeleteModal(true)
                        // console.log(props.record);
                        //   props.confirmDelete(props.record);
                    }}
                    type="primary"
                    className="sign-in"
                >
                    Delete API Key
                </Button>
            </Modal>
            <Modal
                transitionName=""
                maskTransitionName=""
                width={600}
                bodyStyle={{
                    width: 600,
                    backgroundColor: "#353738",
                    paddingLeft: 60,
                    paddingTop: 40,
                    paddingRight: 0,
                    paddingBottom: 60,
                    overflowX: "hidden",
                }}
                closable={false}
                footer={null}
                visible={deleteModal}
                onOk={handleOk}
                onCancel={handleCancel}
                className="delete-app-modal-forinput"
            >
                <div>
                    <Typography.Title
                        style={{
                            display: "inline-block",
                            fontFamily: "Montserrat",
                            color: "#fff",
                            fontWeight: 700,
                            fontSize: 34,
                            paddingBottom: 20,
                            paddingTop: 8,
                        }}
                    >
                        Delete account
                    </Typography.Title>
                    <img
                        onClick={handleCancel}

                        src={require("../../assets/modal-close.png")}
                        className="delete-modal-x-button"
                    ></img>
                </div>
                <Typography.Text>
                    Please confirm by typing DELETE into the field below:
                </Typography.Text>
                <Input
                    placeholder="DELETE"
                    value={confirmDeleteModal}
                    onChange={(e) => setConfimDeleteModal(e.target.value)}
                    className="delete-app-modal-input"
                ></Input>
                <Button
                    loading={props.displayLoading}
                    disabled={confirmDeleteModal !== "DELETE"}
                    style={{ display: "block", marginTop: 40, width: 180, height: 50 }}
                    onClick={() => {
                        // console.log(props.record);
                        //   props.confirmDelete(props.record);
                        deletUser()
                            .then(response => {
                                // console.log(response);
                                localStorage.removeItem("refreshToken");
                                localStorage.removeItem("authToken");
                                localStorage.removeItem("publisherName");
                                window.location.replace('https://loc.kr/')
                            })
                            .catch(error => {
                                console.log(error)
                            })
                    }}
                    type="primary"
                    className="sign-in"
                >
                    Delete
                </Button>
            </Modal>
        </div>
    );
};



function VerticalItem(props) {

    return <div className='verticle-dropdown-option' onClick={() => props.onChange(props.verticle)} style={{ color: props.value === props.verticle.name ? "#61CEF7" : "#fff" }}>

        <div style={{ display: 'inline-block', width: 32, height: 12 }}>
            {props.value === props.verticle.name &&
                <img src={require('../../assets/tick.png')}></img>}
        </div>
        {props.verticle.name}

    </div>
}


function CommonInput(props) {


    const [dropdownVisible, setDropdownVisible] = useState(false)
    const [changePasswordVisible, setIsChangePasswordVisible] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [oldPasswordSet, setOldPasswordSet] = useState(false);
    const [newPasswordSet, setNewPasswordSet] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [oldPassNotMatch, setOldPassNotMatch] = useState(false);
    const [passMatch, setPassMatch] = useState(false)
    const [isPasswordChanged, setIsPasswordChanged] = useState(false);
    const [passwordChangeError, setPasswordChangeError] = useState(undefined);


    const checkValidation = () => {

        let _validation = false;
        if (newPassword === confirmNewPassword && oldPassword !== '' && newPassword !== "" && confirmNewPassword !== "" && newPassword.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/)) {

            _validation = true;
        }
        if (oldPassword === "") {
            setOldPasswordSet(true);
        }
        else if (newPassword === "" || confirmNewPassword === "") {
            setNewPasswordSet(true);
        }

        else if (!newPassword.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/)) {
            setPasswordError(true);
        }
        else if (!(newPassword === confirmNewPassword)) {

            setPassMatch(true);
        }
        return _validation
    }

    const _changePassword = () => {

        if (checkValidation()) {
            changePassword({ oldPassword: oldPassword, newPassword: newPassword })
                .then(response => {
                    if (response.success == false) {
                        setOldPassNotMatch(true)
                        setPasswordChangeError(response.error?.message ?? undefined);
                    }
                    else {
                        setIsPasswordChanged(true)
                        closePopover();
                    }
                })
                .catch(error => { console.log(error) });
        }
        else {
            // console.log('Cannot Validate')
        }


    }

    const menu = (
        <div className='verticle-dropdown-container' style={{ height: 180, width: 445, background: 'black', overflowY: 'scroll' }}>
            {props?.options?.map(verticle => (
                <VerticalItem value={props.value} verticle={verticle} onChange={props.onChange}></VerticalItem>
            ))}
        </div>
    );

    const closePopover = () => {
        setIsChangePasswordVisible(false);
        setOldPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
        setOldPasswordSet(false);
        setNewPasswordSet(false);
        setPasswordError(false);
        setOldPassNotMatch(false);
        setPassMatch(false)
    }

    const content = (
        <div style={{ height: 232, width: 430, background: '#353738' }}>
            <div className="popover-contents">
                <div style={{ paddingLeft: 18, paddingRight: 18 }}>
                    <Input value={oldPassword} onChange={(e) => { setOldPassword(e.target.value); setNewPasswordSet(false); setOldPasswordSet(false); setPasswordError(false); setOldPassNotMatch(false); setPassMatch(false) }} type={'password'} placeholder="Current Password" className="popover-input"></Input>
                    <Input value={newPassword} type={'password'} onChange={(e) => { setNewPassword(e.target.value); setNewPasswordSet(false); setOldPasswordSet(false); setPasswordError(false); setOldPassNotMatch(false); setPassMatch(false) }} className="popover-input" placeholder="New Passsword"></Input>
                    <Input value={confirmNewPassword} type={'password'} onChange={(e) => { setConfirmNewPassword(e.target.value); setNewPasswordSet(false); setOldPasswordSet(false); setPasswordError(false); setOldPassNotMatch(false); setPassMatch(false) }} className="popover-input" placeholder="Confirm Password"></Input>
                </div>

                {oldPasswordSet && <div style={{ marginLeft: 15, marginTop: 8, color: '#f46666', fontFamily: 'Montserrat' }}> Please fill the current password.</div>}
                {newPasswordSet && <div style={{ marginLeft: 15, marginTop: 8, color: '#f46666', fontFamily: 'Montserrat' }}> Please fill the new password.</div>}
                {passwordError && <div style={{ marginLeft: 15, marginTop: 8, color: '#f46666', fontFamily: 'Montserrat' }}> Password doesn't meet requirements.</div>}
                {passMatch && <div style={{ marginLeft: 15, marginTop: 8, color: '#f46666', fontFamily: 'Montserrat' }}> Passwords do not match.</div>}
                {oldPassNotMatch && <div style={{ marginLeft: 15, marginTop: 8, color: '#f46666', fontFamily: 'Montserrat' }}> {passwordChangeError ? passwordChangeError : `Sorry, there are some troubles to reset password.`}</div>}
                <Button onClick={() => { _changePassword() }} style={{ fontWeight: 'bold', height: 48, fontSize: 16, color: '#61CEF7', cursor: 'pointer', fontFamily: 'Montserrat' }} type='link'>Submit</Button>

                <Button style={{ height: 48, fontSize: 16, color: '#8b8b8b', cursor: 'pointer', fontFamily: 'Montserrat', marginLeft: 120 }} type='link' onClick={() => { closePopover() }}>Close</Button>

            </div>
        </div>
    );
    if (props.dropdown) {
        return (
            <Row align="middle" style={{ width: '100%', height: 48, marginBottom: 24 }}>
                {/* <Col sm={6} style={{ color: 'white', fontSize: 16, fontFamily: "Montserrat" }}>{props.name}</Col> */}
                {/* <Col sm={18} style={{ textAlign: 'end' }}><Input className="profile-screen-input" /></Col> */}
                <div style={{ height: 22, width: 173, marginRight: 98, display: 'inline-block', color: 'white', fontSize: 16, fontFamily: "Montserrat" }}>{props.name}</div>
                <Dropdown visible={dropdownVisible} className='verticle-dropdown' trigger={['click']} overlay={menu}>
                    <div style={{ position: 'relative' }}>
                        <AiOutlineDown style={{ position: 'absolute', right: 8, color: 'ActiveBorder', top: 16 }} ></AiOutlineDown>
                        <Input onFocusCapture={() => { setDropdownVisible(true); }}
                            onBlurCapture={() => {
                                setTimeout(() => {
                                    setDropdownVisible(false)
                                }, 200)
                            }} style={{ cursor: 'pointer', paddingRight: 8 }} readOnly value={props.value} onChange={e => { props.onChange(e.target.value) }} type={props.type} className="profile-screen-input" />

                    </div>

                </Dropdown>
            </Row>



        )
    }


    return (
        <Row align="middle" style={{ width: '100%', height: 48, marginBottom: 24 }}>
            {/* <Col sm={6} style={{ color: 'white', fontSize: 16, fontFamily: "Montserrat" }}>{props.name}</Col> */}
            {/* <Col sm={18} style={{ textAlign: 'end' }}><Input className="profile-screen-input" /></Col> */}
            <div style={{ height: 22, width: 173, marginRight: 98, display: 'inline-block', color: 'white', fontSize: 16, fontFamily: "Montserrat" }}>{props.name}</div>
            <Popover placement="right" content={content} visible={changePasswordVisible}>
                <div style={{ width: 445, display: 'inline-block', position: 'relative' }}>

                    {props.name === 'Phone number' ?
                        <div className="profile-screen-phone-input-div"><PhoneNumberInput value={props.value} onChange={props.onChange} inputClass="profile-screen-phone-input"></PhoneNumberInput></div> :
                        <Input readOnly={props.readOnly} value={props.value} onChange={e => { props.onChange(e.target.value) }} type={props.type} className="profile-screen-input" />}

                    {props.name == 'Password' && <Button onClick={() => setIsChangePasswordVisible(true)} style={{ fontWeight: 'bold', height: 48, fontSize: 14, position: 'absolute', right: 0, color: '#61CEF7', cursor: 'pointer', fontFamily: 'Montserrat' }} type="link">{isPasswordChanged ? <>Password Changed <img style={{ marginLeft: 4 }} src={iconChecked}></img></> : "Change"} </Button>}
                </div>
            </Popover>
        </Row >
    )
}

function Profile(props) {




    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [timeZone, setTimeZone] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [businessWebsite, setBusinessWebsite] = useState('');
    const [verticals, setVerticals] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('1');
    const [verticleOptions, setVerticleOptions] = useState([]);
    const [top, setTop] = useState(10);
    const [disabled, setDisabled] = useState(true);
    const [userProfile, setUserProfile] = useState({})
    const [profileTimezones, setProfileTimezones] = useState(getTimeZones());
    const [isChildUser, setIsChildUser] = useState(false);




    useEffect(() => {

        getVerticalList()
            .then(response => {

                setVerticleOptions(response.data)
                getUserProfile(response.data)
            })
            .catch(error => {
                console.log(error);
            })


    }, [])

    const getUserProfile = (verticalList) => {


        getProfile()
            .then(response => {
                var indexOfVertical = verticalList.findIndex(vertical => { return vertical.id === response?.data?.businessVertical });
                var indexOfTimeZone = profileTimezones.findIndex(timezone => { return timezone.name === response.data.timezone })
                setUserProfile(response.data)
                setFirstName(response.data.firstName);
                setLastName(response.data.lastName);
                setEmail(response.data.email);
                setPassword(response.data.password);
                setIsChildUser(response.data.isChildUser ?? false);
                response.data.businessName && setBusinessName(response.data?.businessName);
                response.data.businessURL && setBusinessWebsite(response.data?.businessURL);
                response.data.businessVertical && setVerticals(verticalList[indexOfVertical])
                response.data.businessPhone && setPhoneNumber(response.data.businessPhone)
                response.data.timezone && setTimeZone({
                    value: profileTimezones[indexOfTimeZone].name,
                    name: parseRawFormat(profileTimezones[indexOfTimeZone]),
                    timeZone: profileTimezones[indexOfTimeZone].name,
                    abbreviation: profileTimezones[indexOfTimeZone].abbreviation,
                    currentTimeOffsetInMinutes: profileTimezones[indexOfTimeZone].currentTimeOffsetInMinutes
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        setFirstNameError("");
        setLastNameError("");
        if (isChildUser) {
            if ((firstName !== userProfile?.firstName || lastName !== userProfile?.lastName)) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        } else {
            if ((firstName !== userProfile?.firstName || lastName !== userProfile?.lastName || phoneNumber !== userProfile?.businessPhone || timeZone?.value !== userProfile?.timezone) && (phoneNumber?.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im) || !phoneNumber)) {
                setDisabled(false)
            } else {
                setDisabled(true)
            }
        }

    }, [firstName, lastName, phoneNumber, timeZone?.value, userProfile?.businessPhone, userProfile?.firstName, userProfile?.lastName, userProfile?.timezone])

    const renderHeading = () => {
        return (<Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: 40 }}
            className="identity-suite-dashboard-heading">
            <Col sm={12} xs={24}>
                <Typography.Title className="dashboard-title">
                    Account Profile
                </Typography.Title>
                <label style={{ fontSize: 14, fontFamily: "Montserrat", color: '#8B8B8B' }} className='dashboard-as-of-today'>
                    Member since March 2022
                </label>

            </Col>
        </Row>)
    }

    const _onSaveChanges = () => {
        setFirstNameError("");
        setLastNameError("");
        if (!validateInput(firstName)) {
            setFirstNameError("First name cannot contain URL");
            return;
        }
        if (!validateInput(lastName)) {
            setLastNameError("Last name cannot contain URL");
            return;
        }
        let reqBody = {
            firstName: sanitize(firstName, { allowedTags: [] }),
            lastName: sanitize(lastName, { allowedTags: [] }),
            businessPhone: phoneNumber,
            timezone: timeZone.value,
            timezoneOffset: timeZone.currentTimeOffsetInMinutes,
            timezoneAbbrevation: timeZone.abbreviation,
        }
        if (isChildUser) {
            editSelfUser({
                firstName: sanitize(firstName, { allowedTags: [] }),
                lastName: sanitize(lastName, { allowedTags: [] }),
                email,
            })
        } else {
            editProfile(reqBody)
                .then(response => {
                    // console.log(response);
                    getUserProfile(verticleOptions)
                })
                .catch(error => {
                    console.log(error)
                })
        }

    }

    const _renderBusinessInformation = () => {
        return (
            <Row style={{ marginTop: 60 }}>
                <Row style={{ color: 'white', fontFamily: 'Montserrat', fontWeight: 700, marginBottom: 16, width: '100%' }}>
                    Business Information
                </Row>
                {/* <Row align="middle" style={{ width: '100%', height: 48 }}>
                    <Col sm={6} style={{ color: 'white' }}>First Name</Col>
                    <Col sm={18} style={{ textAlign: 'end' }}><Input className="profile-screen-input" /></Col>
                </Row> */}
                <CommonInput readOnly name={'Business name'} value={businessName} onChange={text => setBusinessName(text)}></CommonInput>
                <CommonInput readOnly name={'Business website'} value={businessWebsite} onChange={text => setBusinessWebsite(text)}></CommonInput>
                <CommonInput readOnly verticleOptions={verticleOptions} name={'Verticals'} value={verticals.name} onChange={text => setVerticals(text)}></CommonInput>
                <CommonInput name={'Phone number'} value={phoneNumber} onChange={text => { setPhoneNumber(text) }}></CommonInput>


            </Row>

        )
    }

    const _renderDeleteAccount = () => {
        return (<Row style={{ marginTop: 60 }}>
            <Row style={{ color: 'white', fontFamily: 'Montserrat', fontWeight: 700, marginBottom: 16, width: '100%' }}>
                Delete Account
            </Row>
            <Col>
                <Row style={{ color: 'white', fontSize: 16, fontWeight: 400, fontFamily: 'Montserrat' }}>This action cannot be reversed.</Row>
                <Row style={{ color: '#8B8B8B', fontSize: 14, fontWeight: 400, fontFamily: 'Montserrat' }}>All content and history will be removed immediately.
                    <InfoCircleOutlined style={{ color: "#888888", fontSize: 16, marginLeft: 8, paddingTop: 4 }} /></Row>
            </Col>
            <Col style={{ marginLeft: 153 }}>
                <ShowDeleteConfirm></ShowDeleteConfirm>
            </Col>
        </Row>)
    }

    const _renderSecurity = () => {
        return (<Row style={{ marginTop: 60 }}>
            <Row style={{ color: 'white', fontFamily: 'Montserrat', fontWeight: 700, marginBottom: 16, width: '100%' }}>
                Security
            </Row>
            <Col>
                <Row style={{ color: 'white', fontSize: 16, fontWeight: 400, fontFamily: 'Montserrat' }}>Two-factor authentication is not enabled yet. </Row>
                <Row style={{ color: '#8B8B8B', fontSize: 14, fontWeight: 400, fontFamily: 'Montserrat' }}>Add an additional layer of security to your account
                    <InfoCircleOutlined style={{ color: "#888888", fontSize: 16, marginLeft: 8, paddingTop: 4 }} /></Row>
            </Col>
            <Col style={{ marginLeft: 170 }}>
                <Button type="default" style={{ height: 48, width: 172, background: 'transparent', borderColor: '#61CEF7', color: '#61CEF7', fontFamily: 'Montserrat', fontSize: 16, fontWeight: 'bold' }}>Enable</Button>
            </Col>
        </Row>)
    }

    const _renderPersonalInformation = () => {
        return (
            <Row >
                <Row style={{ color: 'white', fontFamily: 'Montserrat', fontWeight: 700, marginBottom: 16, width: '100%' }}>
                    Personal Information
                </Row>
                {/* <Row align="middle" style={{ width: '100%', height: 48 }}>
                    <Col sm={6} style={{ color: 'white' }}>First Name</Col>
                    <Col sm={18} style={{ textAlign: 'end' }}><Input className="profile-screen-input" /></Col>
                </Row> */}
                <CommonInput name={'First name'} value={firstName} onChange={text => { setFirstName(text) }}></CommonInput>
                {firstNameError && <div className="account-error">{firstNameError}</div>}
                <CommonInput name={'Last name'} value={lastName} onChange={text => { setLastName(text) }}></CommonInput>
                {lastNameError && <div className="account-error">{lastNameError}</div>}
                <CommonInput readOnly name={'Email'} value={email} onChange={text => { setEmail(text) }}></CommonInput>
                {!isChildUser && <CommonInput name={'Password'} value={password} type='password' onChange={text => setPassword(text)} readOnly></CommonInput>}
                {!isChildUser && <CommonInput dropdown options={timezones()} name={'Timezone'} value={timeZone.name} onChange={text => { setTimeZone(text) }}></CommonInput>}
            </Row>
        )
    }
    const renderInputUi = () => {
        return (
            <Row>
                {_renderPersonalInformation()}
                {!isChildUser && _renderBusinessInformation()}
                {/* {_renderSecurity()} */}
                {!isChildUser && _renderDeleteAccount()}

            </Row>
        )
    }


    function parseRawFormat({ name, rawFormat }) {
        const dividerIdx = rawFormat.indexOf(' ')
        let offset = rawFormat.substr(0, dividerIdx).trim()
        offset = offset.replace(')', '').replace(' ', '')
        const label = rawFormat.replace(offset, '').trim()
        const result = `GMT(${offset}) ${label}`
        // console.log(name, result)
        return result
    }

    function timezones() {
        const results = []

        // console.log(profileTimezones)
        // timezones = ArrayHelper.sort(timezones, 'rawOffsetInMinutes')
        for (let timezone of profileTimezones) {
            try {
                const { name, alternativeName, rawFormat } = timezone
                results.push({
                    value: name,
                    name: parseRawFormat(timezone),
                    timeZone: timezone.name,
                    abbreviation: timezone.abbreviation,
                    currentTimeOffsetInMinutes: timezone.currentTimeOffsetInMinutes
                })
            } catch (error) {
                console.log(error)
            }
        }

        return results
    }

    // console.log(timezones())


    return (
        <SideDrawer>
            {renderHeading()}
            <Row style={{ marginLeft: 91, marginRight: 91, border: '1px solid #26282A', marginBottom: 40 }}></Row>
            <Row style={{ marginRight: 91, marginLeft: 91 }}>
                <Col>{renderInputUi()}</Col>
                {/* <Col sm={24} xs={7} lg={7} xl={7} style={{ textAlign: 'end' }}> */}
                <Affix offsetTop={top} style={{ position: 'absolute', right: 91 }}>
                    <Button onClick={() => { _onSaveChanges() }} disabled={disabled} className="sign-in" type="default" style={{ width: 262, height: 60, marginTop: 38 }}>Save Changes</Button>
                </Affix>
                {/* </Col> */}
            </Row>
        </SideDrawer>
    )
}

export default Profile;