import { Button, PageHeader } from "antd";
import React, { useEffect, useState } from "react";
import lockrIcon from "../../assets/lockrMobile.svg";
import FloatLabel from "../../Components/FloatLabel/FloatLabel";
import { LoginFormInputEmail } from "../Login/Login";
import moment from 'moment';
import { sendMobileOnboardingEmail } from "../../Utils/ApiActions";
import { DomainTick } from "../../App";

function MobileView(props) {

  /**
   * Email Type
   * @date 18/01/2024 - 01:22:14
   *
   * @type {string}
   */
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  function checkValidation() {
    const _emailReg = new RegExp(/^[^@]+@[^@]{2,}\.[^@]{2,}$/);
    if (email.trim() === '' || !_emailReg.test(email)) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    isSuccess && setTimeout(() => {
      setIsSuccess(false);
    }, 1500)
  }, [isSuccess])

  function renderFooter() {
    return (
      <div style={{
        flex: 1,
        height: 95,
        position: 'fixed',
        bottom: 0,
        width: '100%',
        paddingLeft: 30,
        paddingRight: 30,
        background: '#353738',
        borderTop: '1px solid #353738',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
      >
        <img
          style={{
            height: 30,
            width: 19,
          }}
          alt="lockr_logo"
          src={require('../../assets/mobile_footer_logo.png')}
        />
        <label style={{
          fontFamily: 'Montserrat',
          fontWeight: '400',
          fontSize: 12,
          color: '#828282'
        }}>© {moment().format('YYYY')} lockr</label>
      </div>
    )
  }

  async function triggerEmail() {
    setLoading(true);
    try {
      await sendMobileOnboardingEmail(email);
      setIsSuccess(true);
    } catch (error) {
      console.log('Unable to send the email for onboarding');
      console.log(error);
    }
    setLoading(false);
  }

  return (
    <div>
      <PageHeader
        title={
          <div style={{ flexDirection: "row" }}>
            <img
              onClick={() => {
                window.open('https://loc.kr/', '_blank');
              }}
              className="lockr-logo-pagehead"
              alt="logo"
              src={require("../../assets/lockr-logo.png")}
            ></img>
            {/* <label style = {{color: "white", marginLeft: 16}}>{"Publisher Platform"}</label> */}
          </div>
        }
      ></PageHeader>
      <div
        style={{
          color: "white",
          padding: 32,
          paddingTop: 24,
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <img
          style={{
            display: "block",
            marginBottom: 16,
            height: 172,
            width: "100%",
          }}
          class="lockr-logo"
          src={lockrIcon}
          alt="lock-img"
        />
        <div style={{ marginTop: 40 }}></div>
        <div
          style={{
            textAlign: "center",
            fontSize: 32,
            fontFamily: "Montserrat",
            fontWeight: 700,
          }}
        >
          Oops...
        </div>
        <div
          style={{
            marginTop: 8,
            fontFamily: "Montserrat",
            fontSize: 18,
            fontWeight: '500',
            textAlign: "center",
          }}
        >
          Audience Integration Manager (AIM) is only available on a laptop or desktop
        </div>
        <div style={{
          marginTop: 10,
          textAlign: 'center',
          color: '#8B8B8B',
          fontWeight: '400'
        }}>
          If you are trying to create an AIM account or intent on testing but are not near a laptop or desktop, please put your email below and we'll send you a reminder.
        </div>
        <div style={{ marginTop: 24, }} />
        {!isSuccess && <FloatLabel label={'Email address'} value={email}>
          <LoginFormInputEmail
            value={email}
            inputClassName={'mobile-input-class'}
            onChange={(event) => {
              setEmail(event.target.value);
            }} onFocus={(event) => {

            }} onBlur={(event) => {

            }}
          />
        </FloatLabel>}
        <div style={{ marginTop: 10 }} />
        {!isSuccess && <Button
          disabled={!checkValidation()}
          className="sign-in"
          block
          loading={loading}
          onClick={() => {
            triggerEmail();
          }}
          size="middle"
          style={{ background: '#000' }}
        >Remind me</Button>}
        {isSuccess && <div style={{
          display: 'flex',
          gap: 10,
          marginTop: 10,
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <DomainTick height={18} width={18} />
          <span style={{
            color: '#50E8B1',
            fontFamily: 'Montserrat',
            fontSize: 16
          }}>Reminder Sent</span>
        </div>}
        <div style={{ height: 100 }} />
      </div>
      {renderFooter()}
    </div>
  );
}

export default MobileView;
