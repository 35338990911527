import React, { useEffect, useState } from "react";
import { BASE_URL, listAllApplications } from "../../../Utils/ApiActions";
import SideDrawer from "../../../Components/SideDrawer";
import { Row, Typography } from "antd";
import { useHistory } from "react-router-dom";
import { TitleValuePair } from "../../../App";

export function AuthOAuthentication() {
  const [appList, setAppList] = useState([]);
  const [appListLoading, setAppListLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    fetchAppList();
  }, []);

  async function fetchAppList() {
    try {
      setAppListLoading(true);
      const response = await listAllApplications();
      if (response.data?.length) {
        setAppList(response.data);
      }
      setAppListLoading(false);
    } catch (error) {
      setAppListLoading(false);
      console.log("Unable to get the list of the apps");
      console.log(error);
    }
  }

  function renderEmptyState() {
    if (!appList.length && !appListLoading) {
      return (
        <div
          style={{
            width: "100%",
            minHeight: 200,
            borderRadius: 8,
            background: "black",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <span
            style={{
              color: "white",
              fontSize: 18,
            }}
          >
            Please create a new app in the{" "}
            <span
              style={{
                color: "#61cff7",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/console/apiandservices");
              }}
            >
              Api & Services
            </span>
            , to enable the auth0 OIDC integration.
          </span>
        </div>
      );
    }
    return <></>;
  }

  function renderOIDCDetails() {
    if (appList.length) {
      return (
        <div
          style={{
            width: "100%",
            minHeight: 200,
            borderRadius: 8,
            background: "black",
            padding: 32,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 32,
            }}
          >
            <TitleValuePair
              title={"OIDC Configuration"}
              supportCopy
              value={`${BASE_URL}/publisher/.well-known/openid-configuration`}
              copyValue={`${BASE_URL}/publisher/.well-known/openid-configuration`}
            />
            <TitleValuePair
              title={"Authorization Endpoint"}
              supportCopy
              value={`${BASE_URL}/publisher/oauth2/v1/sdk/login`}
              copyValue={`${BASE_URL}/publisher/oauth2/v1/sdk/login`}
            />
            <TitleValuePair
              title={"Token Endpoint"}
              supportCopy
              value={`${BASE_URL}/oauth2/getToken`}
              copyValue={`${BASE_URL}/oauth2/getToken`}
            />
            <TitleValuePair
              title={"Token Keys Endpoint"}
              supportCopy
              value={`${BASE_URL}/publisher/.well-known/jwks.json`}
              copyValue={`${BASE_URL}/publisher/.well-known/jwks.json`}
            />
          </div>
          <div
            style={{
              marginTop: 32,
              width: "100%",
              height: 1,
              background: "#cecece44",
            }}
          />
          <span
            style={{
              color: "white",
              fontSize: 14,
              marginTop: 32,
              fontFamily: "Montserrat",
            }}
          >
            The Client ID and Client Secret is available on the{" "}
            <span
              style={{
                color: "#61cff7",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/console/apiandservices");
              }}
            >
              API & Services page
            </span>{" "}
            once an Application is configured.
          </span>
        </div>
      );
    }
    return <></>;
  }

  return (
    <SideDrawer>
      <Row
        justify="space-between"
        align={"middle"}
        className="apiandservices-newapp-btn"
      >
        <Typography.Title className="apiandservices-heading">
          Auth0 By Okta OIDC
        </Typography.Title>
        <div style={{ marginTop: 8 }} />
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <label
            style={{ fontSize: 14, fontFamily: "Montserrat", color: "#8B8B8B" }}
            className="dashboard-as-of-today"
          >
            Use lockr's provided OIDC configurations to activate lockrMail SSO within AuthO's Identity offering.
          </label>          
        </div>
      </Row>
      <div
        style={{
          paddingLeft: 90,
          paddingRight: 90,
        }}
      >
        {renderEmptyState()}
        {renderOIDCDetails()}
      </div>
    </SideDrawer>
  );
}
