import React, { useEffect, useRef, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { getDataEndpointProgress } from "../../../../Utils/ApiActions";

const DATA_ENDPOINT_MESSAGES = {
  PROCESSING: "PROCESSING",
  FETCHING_DATA: "FETCHING_DATA",
  PREPARING_FILE: "PREPARING_FILE",
  PUSHING_DATA: "PUSHING_DATA",
  COMPLETED: "COMPLETED",
};

const DATA_ENDPOINT_MESSAGES_STRING = {
  PROCESSING: "Processing File",
  FETCHING_DATA: "Fetching Data",
  PREPARING_FILE: "Preparing the file",
  PUSHING_DATA: "Pushing Data",
  COMPLETED: "Completed",
};

function CompletedTick() {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        zIndex: 1200,
      }}
    >
      <path
        d="M0.00012207 30C0.00012207 37.9565 3.16084 45.5872 8.78693 51.2133C14.413 56.8394 22.0437 60.0001 30.0002 60.0001C37.9567 60.0001 45.5873 56.8394 51.2134 51.2133C56.8395 45.5872 60.0002 37.9565 60.0002 30C60.0002 22.0435 56.8395 14.4129 51.2134 8.78681C45.5873 3.16071 37.9567 0 30.0002 0C22.0437 0 14.413 3.16071 8.78693 8.78681C3.16084 14.4129 0.00012207 22.0435 0.00012207 30Z"
        fill="#50E8B1"
      />
      <path
        d="M23.7628 45.634C23.191 45.6344 22.6247 45.522 22.0964 45.3032C21.5682 45.0844 21.0882 44.7635 20.6842 44.359L11.977 35.6518C11.1605 34.8353 10.7018 33.7279 10.7018 32.5732C10.7018 31.4185 11.1605 30.3111 11.977 29.4947C12.7935 28.6782 13.9009 28.2195 15.0556 28.2195C16.2103 28.2195 17.3177 28.6782 18.1342 29.4947L23.7628 35.1286L41.4914 17.4018C41.8935 16.9881 42.3739 16.6584 42.9046 16.432C43.4352 16.2056 44.0056 16.0869 44.5826 16.0829C45.1595 16.0788 45.7315 16.1895 46.2653 16.4085C46.7991 16.6274 47.284 16.9503 47.692 17.3583C48.0999 17.7664 48.4226 18.2514 48.6414 18.7853C48.8602 19.3191 48.9707 19.8912 48.9665 20.4681C48.9623 21.045 48.8435 21.6154 48.6169 22.146C48.3903 22.6766 48.0605 23.1569 47.6467 23.5589L26.8413 44.359C26.4375 44.7639 25.9576 45.0849 25.4293 45.3037C24.901 45.5225 24.3346 45.6348 23.7628 45.634Z"
        fill="white"
      />
    </svg>
  );
}

export function FullRefreshLoader({ dataConfigurationId, onClose }) {
  const intervalRef = useRef({
    value: null,
  });

  const [status, setStatus] = useState(DATA_ENDPOINT_MESSAGES.PROCESSING);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    intervalRef.current.value = setInterval(() => {
      fetchEndpointDetails();
    }, [2000]);

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  async function fetchEndpointDetails() {
    try {
      const response = await getDataEndpointProgress(dataConfigurationId);
      if (
        response.success &&
        response.data?.status === DATA_ENDPOINT_MESSAGES.COMPLETED
      ) {
        clearInterval(intervalRef.current.value);
        setStatus(DATA_ENDPOINT_MESSAGES.COMPLETED);
        setTimeout(() => {
          onClose();
        }, [2000]);
      } else if (response.success) {
        setStatus(
          response.data?.status ?? DATA_ENDPOINT_MESSAGES_STRING.PROCESSING
        );
      }
    } catch (error) {
      console.log("Unable to get the status for the endpoint");
      console.log(error);
    }
  }

  return (
    <div
      style={{
        position: "fixed",
        justifyContent: "center",
        alignItems: "center",
        top: '25%',
        width: "100%",
        display: "flex",
        flexDirection: "column",
        zIndex: 2000,
      }}
    >
      {status === DATA_ENDPOINT_MESSAGES.COMPLETED ? (
        <CompletedTick />
      ) : (
          <RotatingLines width="60" strokeColor="white" />
      )}
      <label
        style={{
          color: "white",
          marginTop: 12,
          fontSize: 24,
          fontWeight: "700",
          fontFamily: "Montserrat",
        }}
      >
        {DATA_ENDPOINT_MESSAGES_STRING[status]}
      </label>
    </div>
  );
}
