import { Affix, Button, Col, message, Modal, Progress, Row, Spin, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { CheckSquareOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import RegCommonButton from '../../Components/RegCommonButton'
import RegCommonInput from '../../Components/RegCommonInput'
import RegCommonTitle from '../../Components/RegCommonTitle'
import { emailRegistered } from '../../Utils/ApiActions'
import FloatLabel from '../../Components/FloatLabel/FloatLabel'
import verifyIcon from '../../assets/verify-icon.svg'

const Step1 = (props) => {
    const [email, setEmail] = useState('')
    const [displayLoading, setDisplayLoading] = useState(false)
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState('')
    const [btnDisable, setBtnDisable] = useState(true)
    const [deleteModal, setDeleteModal] = useState(false)
    const [isDeletedData, setIsDeletedData] = useState({})

    useEffect(() => {
        if (checkValidation()) {
            setBtnDisable(false)
        } else {
            setBtnDisable(true)
        }
        return () => {
        }
    }, [email])

    const _renderReactivateModal = () => {
        return (<Modal
            transitionName=""
            maskTransitionName=""
            width={716}
            bodyStyle={{
                width: 716,
                backgroundColor: "#353738",
                paddingLeft: 60,
                paddingTop: 40,
                paddingRight: 0,
                paddingBottom: 60,
                overflowX: "hidden",
            }}
            closable={false}
            footer={null}
            visible={deleteModal}
            onOk={() => setDeleteModal(false)}
            onCancel={() => setDeleteModal(false)}
            className="delete-app-modal-forinput"
        >
            <div>
                {/* <Typography.Title
                    style={{
                        display: "inline-block",
                        fontFamily: "Montserrat",
                        color: "#fff",
                        fontWeight: 700,
                        fontSize: 34,
                        paddingBottom: 20,
                        paddingTop: 8,
                    }}
                >
                    Delete account
                </Typography.Title> */}
                <Typography.Text style={{ display: 'inline-block', width: 596, paddingTop: 24 }}>
                    Welcome back {isDeletedData?.firstName} {isDeletedData?.lastName}! We see you are returning to Identity lockr after
                    deleting your account previously. We'd be happy to reactivate your old
                    account ({isDeletedData?.username}). To do so, please click the
                    verification link we sent to your personal email.
                </Typography.Text>
                <img
                    style={{ display: 'inline-block', position: 'absolute', right: 35, top: 40, cursor: 'pointer' }}
                    onClick={() => setDeleteModal(false)}
                    src={require("../../assets/modal-close.png")}
                // className="delete-modal-x-button"
                ></img>


            </div>
            <Button

                style={{ display: "block", marginTop: 40, width: 180, height: 50 }}
                onClick={() => {
                    // console.log(props.record);
                    setDeleteModal(false)
                }}
                type="primary"
                className="sign-in"
            >
                OK
            </Button>


        </Modal>)
    }



    const checkEmailAlreadyRegister = () => {
        setDisplayLoading(true)
        emailRegistered(email).then(response => {
            setDisplayLoading(false)
            setIsDeletedData(response.data)
            if (response.success === false) {
                props.history.push('/console/signup/step2', { signUpDetails: { email: email } })
            } else {
                if (response?.data) {
                    setDeleteModal(true)
                }
                else {
                    if (response.status = 5010) {
                        setError(true);
                        setErrorText(response?.errorMessage)
                    }
                    else {
                        setError(true)
                        setErrorText(response?.error?.message || 'Email address already registered');
                    }
                }

            }
        }).catch(err => {
            setDisplayLoading(false)
            setError(true)
            setErrorText("Unable to get the details due to: " + err.message);
        })
    }

    function checkValidation() {
        let _validate = true
        let _emailReg = new RegExp(/^[^@]+@[^@]{2,}\.[^@]{2,}$/)
        if (email.trim() === '' || !_emailReg.test(email)) {
            return _validate = false
        }
        return _validate
    }

    document.body.classList.add('lockr-bg-img');
    return (
        <Spin spinning={false}>
            {_renderReactivateModal()}
            <Row>
                <Progress className='reg-steps' percent={25} showInfo={false} />
            </Row>
            <Row justify='start' className='reg-container'>
                <Row style={{ width: '100%' }}>
                    <Col>
                        <img className="lockr-logo" src={require('../../assets/onboard_lock.42d74f50.png')} alt='lock-img' />
                    </Col>
                </Row>
                <RegCommonTitle title={'What is your email?'} />
                <Row style={{ marginTop: 48, width: '100%' }}>
                    <Col sm={12} xs={24}>
                        <FloatLabel value={email} label={'Work email'}>
                            <RegCommonInput className={error ? 'errorInputText' : null} value={email}
                                onChange={(event) => {
                                    setError(false)
                                    setEmail(event.target.value)
                                }}
                            />
                        </FloatLabel>
                    </Col>
                    {error && <Col sm={14} xs={24}>
                        <div dangerouslySetInnerHTML={{__html: errorText?.replace('login', `<a style = "color: rgb(97, 206, 247); font-weight: bold;" href = '/console/login'>login</a>`)}} className='errorText-register' />
                    </Col>}
                </Row>
                <RegCommonButton icon={displayLoading ? <img className='loading-img' src={verifyIcon} style={{ marginRight: 24 }}></img> : <></>} verifyLoading={displayLoading} type="verify" disabled={btnDisable} btntext={displayLoading ? 'Verifying...' : 'Next'} onClick={checkEmailAlreadyRegister} />
                <div className='signup-option'>
                    <span>Already have an Identity lockr account?</span>
                    <a href='/console/login' className='signin-option'>Sign in</a>
                </div>
            </Row>
        </Spin>
    )
}

export default withRouter(Step1)