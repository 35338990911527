import { Col, Progress, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import FloatLabel from '../../Components/FloatLabel/FloatLabel'
import RegCommonButton from '../../Components/RegCommonButton'
import RegCommonInput from '../../Components/RegCommonInput'
import RegCommonTitle from '../../Components/RegCommonTitle'
import { validateInput } from '../../Utils/Helper'
import sanitize from 'sanitize-html'

const Step2 = (props) => {
    function focusOut() {
        let elements = document.getElementsByTagName("input");
        if (elements.length === 0) {
            return;
        }
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('lockr-form-control-focus');
        }
    }
    function getFocus() {
        let elements = document.getElementsByTagName("input");
        if (elements.length === 0) {
            return;
        }
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add('lockr-form-control-focus');
        }
    }

    const [fName, setFName] = useState('')
    const [lName, setLName] = useState('')
    const _signUpDetails = props?.location?.state?.signUpDetails
    useEffect(() => {
        if (props?.location?.state === undefined || _signUpDetails === undefined) {
            props.history.replace('/console/signup')
        }
    }, [])

    document.body.classList.add('lockr-bg-img');
    return (
        <div>
            <Row>
                <Progress className='reg-steps' percent={50} showInfo={false} />
            </Row>
            <Row justify='start' className='reg-container'>
                <Row style={{ width: '100%' }}>
                    <Col>
                        <img className="lockr-logo" src={require('../../assets/onboard_lock.42d74f50.png')} alt='lock-img' />
                    </Col>
                </Row>
                <RegCommonTitle title={'What is your name?'} />
                <Row className="lockr-join2" style={{ marginTop: 48 }}>
                    <Col sm={14} xs={24}>
                        <FloatLabel value={fName} label={'First name'}>
                            <RegCommonInput value={fName}
                                maxLength={64}
                                onChange={(event) => {
                                    setFName(event.target.value)
                                }} onFocus={(event) => {
                                    getFocus();
                                }} onBlur={(event) => {
                                    focusOut();
                                }}
                            />
                        </FloatLabel>
                    </Col>
                    <Col sm={14} xs={24}>
                        <FloatLabel value={lName} label={'Last name'}>
                            <RegCommonInput value={lName}
                                maxLength={64}
                                onChange={(event) => {
                                    setLName(event.target.value)
                                }} onFocus={(event) => {
                                    getFocus();
                                }} onBlur={(event) => {
                                    focusOut();
                                }}
                            />
                        </FloatLabel>
                    </Col>
                </Row>
                <RegCommonButton disabled={fName === '' || lName === '' || !validateInput(sanitize(fName, { allowedTags: [] })) || !validateInput(sanitize(lName, { allowedTags: [] }))} btntext={'Next'} onClick={() => {

                    props.history.push('/console/signup/step3', {
                        signUpDetails: {
                            email: _signUpDetails.email,
                            fname: sanitize(fName, { allowedTags: [] }),
                            lname: sanitize(lName, { allowedTags: [] })
                        }
                    })
                }} />
            </Row>
        </div>
    )
}

export default withRouter(Step2)