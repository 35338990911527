/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from "react";
import SideDrawer from "../../../Components/SideDrawer";
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Input,
  Modal,
  Row,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { TitleText } from "../../../Components/NewAppModal";
import {
  dataEndpointFullRefresh,
  deleteAWSEndpoint,
  getCVSDataEndpointAttributes,
  getExternalID,
  getS3EndPoints,
  newS3DataEndPoint,
  updateS3DataEndPoint,
} from "../../../Utils/ApiActions";
import {
  DATA_ENDPOINTS,
  generateUUID,
  mapSnowflakStatusToUI,
  mapSnowflakeStatusToColor,
} from "../../../Utils/Helper";
import { AiOutlineCheck, AiOutlineDown } from "react-icons/ai";
import DeleteTypeModal from "../../../Components/LockrDeleteModal";
import { RotatingLines } from "react-loader-spinner";
import { FullRefreshLoader } from "./components/full-refresh-loader";
import { useHistory } from "react-router-dom";
import { AppSelectionDropdown } from "./components/AppSelectionDropdown";
import { LockrInfoIcon } from "../../../Components/UIDCredentialsModal";
import { CommonDropdown } from "../../../Components/custom-dropdown";
import { CustomConditionsBuilder } from "./components/CustomConditionsBuilder";

export function FrequencyDropDown({
  value,
  onChange,
  hideYearly = false,
  hideHourly = false,
}) {
  const frequencyList = ["Daily", "Weekly", "Monthly"];
  const [open, setOpen] = useState(false);

  if (!hideYearly) {
    frequencyList.push("Yearly");
  }

  if (!hideHourly) {
    frequencyList.splice(0, 0, "Hourly");
  }

  const menu = (
    <div
      className="verticle-dropdown-container"
      style={{
        height:
          !hideYearly && !hideHourly
            ? 160
            : hideHourly || hideYearly
              ? 140
              : 120,
        background: "black",
        paddingTop: 8,
        overflowY: "scroll",
      }}
    >
      {frequencyList.map((frequencyItem) => {
        return (
          <div
            style={{ cursor: "pointer", color: "#fff" }}
            className="frequency-dropdown-option"
            onClick={() => {
              onChange(frequencyItem);
              setOpen(false);
            }}
          >
            <div style={{ display: "inline-block", width: 32, height: 12 }}>
              <AiOutlineCheck
                style={{
                  color: value === frequencyItem ? "#61CEF7" : "transparent",
                }}
              />
            </div>
            {frequencyItem}
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="snowflake-frequency-picker-container">
      <Dropdown
        overlay={menu}
        open={open}
        onOpenChange={setOpen}
        trigger={["click"]}
      >
        <div style={{ position: "relative", height: 48 }}>
          <input
            onClick={() => {
              setOpen(true);
            }}
            className="lockr-data-endpoint-input"
            onFocusCapture={() => { }}
            value={value === "Complete Dump" ? "Complete" : value}
            style={{
              cursor: "pointer",
            }}
            placeholder={"Delivery Frequency"}
            readOnly
            onBlurCapture={() => { }}
          />
          <AiOutlineDown
            style={{
              cursor: "pointer",
              color: "white",
              position: "absolute",
              right: -220,
              top: 18,
            }}
          />
        </div>
      </Dropdown>
    </div>
  );
}

export function DataEndpointTitleInput({
  title,
  value,
  onChange,
  customInput,
  disabled,
  infoText,
}) {
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 8,
          alignItems: "center",
          marginBottom: 4,
        }}
      >
        <span
          style={{
            fontWeight: "500",
            fontSize: 14,
            fontFamily: "Montserrat",
            color: "white",
          }}
        >
          {title}
        </span>
        {infoText && (
          <Tooltip
            style={{
              cursor: "pointer",
            }}
            title={infoText}
          >
            <div>
              <LockrInfoIcon />
            </div>
          </Tooltip>
        )}
      </div>
      <div
        style={{
          marginTop: 4,
        }}
      >
        {customInput ? (
          customInput
        ) : (
          <>
            <input
              disabled={disabled}
              className="lockr-data-endpoint-input"
              style={{
                cursor: disabled ? "not-allowed" : "auto",
              }}
              placeholder=""
              value={value}
              disabled={disabled}
              onChange={onChange}
            />
          </>
        )}
      </div>
    </div>
  );
}

export function DataEndpointTitleValue({
  title,
  value,
  showDropdown = false,
  isEdit = false,
  infoText,
}) {
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 8,
          alignItems: "center",
          marginBottom: 4,
        }}
      >
        <span
          style={{
            fontWeight: "500",
            fontSize: 14,
            fontFamily: "Montserrat",
            color: "white",
          }}
        >
          {title}
        </span>
        {infoText && (
          <Tooltip
            style={{
              cursor: "pointer",
            }}
            title={infoText}
          >
            <div>
              <LockrInfoIcon />
            </div>
          </Tooltip>
        )}
      </div>
      <div
        style={{
          background: "#26282A",
          minHeight: 50,
          minWidth: 600,
          maxWidth: 600,
          display: "flex",
          alignItems: "center",
          marginTop: 4,
          paddingLeft: 8,
          position: "relative",
        }}
      >
        <span
          style={{
            fontWeight: "500",
            minWidth: 550,
            maxWidth: 550,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: 14,
            fontFamily: "Montserrat",
            color: isEdit ? "white" : "#8B8B8B",
          }}
        >
          {value}
        </span>
        {showDropdown && (
          <AiOutlineDown
            style={{
              color: isEdit ? "white" : "#8B8B8B",
              position: "absolute",
              right: 16,
            }}
          />
        )}
      </div>
    </div>
  );
}

export function DataEndpointAWSAccessDropDown({ value, onChange }) {
  const accessList = ["AWS IAM", "AWS Access Key"];
  const [open, setOpen] = useState(false);

  const menu = (
    <div
      className="verticle-dropdown-container"
      style={{
        paddingTop: 8,
        background: "black",
        overflow: "scroll",
      }}
    >
      {accessList.map((accessItem) => {
        return (
          <div
            style={{
              cursor: "pointer",
              color: "#fff",
            }}
            className="frequency-dropdown-option"
            onClick={() => {
              onChange(accessItem);
              setOpen(false);
            }}
          >
            <div
              style={{
                display: "inline-block",
                width: 32,
                height: 32,
              }}
            >
              <AiOutlineCheck
                style={{
                  color: value === accessItem ? "#61CEF7" : "transparent",
                }}
              />
            </div>
            {accessItem}
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="snowflake-frequency-picker-container">
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        open={open}
        onOpenChange={setOpen}
      >
        <div style={{ position: "relative", height: 48 }}>
          <input
            onClick={() => {
              setOpen(true);
            }}
            className="lockr-data-endpoint-input"
            onFocusCapture={() => { }}
            value={value}
            style={{
              cursor: "pointer",
            }}
            placeholder={"AWS Access Type"}
            readOnly
            onBlurCapture={() => { }}
          />
          <AiOutlineDown
            style={{
              cursor: "pointer",
              color: "white",
              position: "absolute",
              right: -220,
              top: 20,
            }}
          />
        </div>
      </Dropdown>
    </div>
  );
}

export function DataEndpointTypeDropDown({ value, onChange }) {
  const frequencyList = ["Incremental", "Complete Dump"];
  const [open, setOpen] = useState(false);

  const menu = (
    <div
      className="verticle-dropdown-container"
      style={{
        paddingTop: 8,
        background: "black",
        overflow: "scroll",
      }}
    >
      {frequencyList.map((frequencyItem) => {
        return (
          <div
            style={{
              cursor: "pointer",
              color: "#fff",
            }}
            className="frequency-dropdown-option"
            onClick={(e) => {
              onChange(frequencyItem);
              setOpen(false);
            }}
          >
            <div style={{ display: "inline-block", width: 32, height: 12 }}>
              <AiOutlineCheck
                style={{
                  color: value === frequencyItem ? "#61CEF7" : "transparent",
                }}
              />
            </div>
            {frequencyItem === "Complete Dump" ? "Complete" : frequencyItem}
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="snowflake-frequency-picker-container">
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        open={open}
        onOpenChange={setOpen}
      >
        <div style={{ position: "relative", height: 48 }}>
          <input
            onClick={() => {
              setOpen(true);
            }}
            className="lockr-data-endpoint-input"
            onFocusCapture={() => { }}
            value={value === "Complete Dump" ? "Complete" : value}
            style={{
              cursor: "pointer",
            }}
            placeholder={"Delivery Frequency"}
            readOnly
            onBlurCapture={() => { }}
          />
          <AiOutlineDown
            style={{
              cursor: "pointer",
              color: "white",
              position: "absolute",
              right: -220,
              top: 20,
            }}
          />
        </div>
      </Dropdown>
    </div>
  );
}

export function FullRefreshNoticeModal({ onOk, onCancel, display }) {
  return (
    <Modal
      visible={display}
      style={{ top: 20, paddingBottom: 20 }}
      centered
      width={776}
      footer={null}
      closable={false}
      destroyOnClose
      bodyStyle={{
        minHeight: 320,
        width: 776,
        backgroundColor: "#353738",
        paddingLeft: 60,
        paddingTop: 40,
        paddingRight: 0,
        overflowX: "hidden",
      }}
    >
      <img
        alt={"Close"}
        onClick={onCancel}
        src={require("../../../assets/modal-close.png")}
        className="delete-x-button-connection"
      />
      <div
        style={{
          marginTop: 20,
        }}
      >
        <label className="lockr-full-refresh-title">Full Refresh Notice:</label>
        <div
          style={{
            marginTop: 8,
            paddingRight: 16,
          }}
        >
          <label className="lockr-full-refresh-description">
            Please note than a full refresh will override all current data at
            the endpoint, respect user opt-out flags, and ensure the database is
            completely up to date.
          </label>
        </div>
        <div
          style={{
            marginTop: 30,
            display: "flex",
            flexDirection: "row",
            gap: 24,
          }}
        >
          <Button
            onClick={onOk}
            style={{ display: "block", marginTop: 0, width: 180, height: 50 }}
            type="primary"
            className="sign-in"
          >
            Confirm
          </Button>
          <Button
            style={{ display: "block", marginTop: 0, width: 180, height: 50 }}
            type="primary"
            onClick={onCancel}
            className="cancel-button"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export function ReturnToConnectionHubModal({ onOk, display }) {
  return (
    <Modal
      visible={display}
      style={{ top: 50, paddingBottom: 20 }}
      maskStyle={{ backdropFilter: "blur(8px)" }}
      centered
      width={720}
      height={350}
      footer={null}
      closable={false}
      destroyOnClose
      bodyStyle={{
        width: 720,
        backgroundColor: "#353738",
        padding: "50px 60px",
        overflowX: "hidden",
        display: "flex",
      }}
    >
      <div
        style={{
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
          gap: 12,
          zIndex: 11000,
        }}
      >
        <span
          style={{
            fontWeight: 700,
            fontFamily: "Montserrat",
            fontSize: 36,
            color: "white",
          }}
        >
          Feel free to return to the Connections Hub!
        </span>
        <div
          style={{
            paddingRight: 16,
          }}
        >
          <label className="lockr-full-refresh-description">
            Your Full Refresh is running in the background and will continue to
            process even if you navigate away. Simply{" "}
            <span
              style={{ color: "#61CEF7", cursor: "pointer" }}
              onClick={onOk}
            >
              click here
            </span>{" "}
            to go back to the Connections Hub and carry on with your work. We'll
            take care of the rest!
          </label>
        </div>
        <div
          style={{
            marginTop: 15,
            display: "flex",
            flexDirection: "row",
            gap: 24,
          }}
        >
          <Button
            onClick={onOk}
            style={{ display: "block", marginTop: 0, width: 278, height: 50 }}
            type="primary"
            className="sign-in"
          >
            Return to Connections Hub
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export function AWSDataEndPoint() {
  const defaultConnectionDetails = {
    s3Bucket: "",
    externalId: generateUUID(),
    roleARN: "",
    sessionName: "",
    region: "",
    fileName: "",
    frequency: "Daily",
    type: "Incremental",
    name: "",
    uid2Enabled: false,
    id5Enabled: false,
    liverampEnabled: false,
    firstPartyCookies: "",
    accessType: "AWS IAM",
    appIDs: [],
    domains: [],
    accessKey: "",
    secretKey: "",
    dateCreatedEnabled: false,
  };

  const [displayLoading, setDisplayLoading] = useState(false);
  const [awsConnections, setAWSConnections] = useState([]);
  const [showNewConnection, setShowNewConnection] = useState(false);
  const [showFullRefreshModal, setShowFullRefreshModal] = useState(undefined);
  const [formMode, setFormMode] = useState("NEW");
  const [cohortList, setCohortList] = useState([{ id: "", configuration: { appliedFilters: { allCondition: 'AND', filters: [] }, selectedAttributes: [], name: "", } }]);
  const [selectedCohort, setSelectedCohort] = useState({ id: "", configuration: { appliedFilters: { allCondition: 'AND', filters: [] }, selectedAttributes: [], name: "", } });
  const [selectedRecord, setSelectedRecord] = useState(undefined);

  const [attributes, setAttributes] = useState([])
  const [displayFullRefreshLoading, setDisplayFullRefreshLoading] =
    useState(false);
  const [showFullRefreshIndicator, setShowFullRefreshIndicator] =
    useState(false);
  const [selectedConnectionToDelete, setSelectedConnectionToDelete] =
    useState(undefined);

  const [externalId, setExternalId] = useState("");

  const [newConnectionDetails, setNewConnectionDetails] = useState(
    defaultConnectionDetails
  );

  const [selectedConnectionDetails, setSelectedConnectionDetails] =
    useState(undefined);

  const history = useHistory();

  const selectedConnectionConfiguration = useMemo(() => {
    try {
      return JSON.parse(selectedConnectionDetails.configuration);
    } catch (error) {
      console.log("Unable to set the configurations");
      console.log(error);
    }
  }, [selectedConnectionDetails]);

  function checkIfEditDisabled(record) {
    return (
      record.status === "PROCESSING" ||
      record.status === "SENT_TO_EMAILCHECKER" ||
      record.status === "IN_PROGRESS" ||
      record.status === "FAILED" ||
      record.status === "COMPLETED"
    );
  }

  useEffect(() => {
    getExternalIDCall();
    getCohortList();
    getAttributes()
  }, []);

  const getAttributes = async () => {
    const attrs = await getCVSDataEndpointAttributes()
    setAttributes(attrs.data)
  }

  const getCohortList = async () => {
    const cohortsData = await getS3EndPoints('CSV')
    setCohortList([...cohortsData.data])
  }

  async function getExternalIDCall() {
    try {
      const response = await getExternalID();
      if (response?.success) {
        setExternalId(response?.data);
        setNewConnectionDetails({
          ...newConnectionDetails,
          externalId: response?.data,
        });
      }
    } catch (error) {
      console.log("Unable to get the external id for the publisher");
      console.log(error);
    }
  }

  function renderActionButtons(record) {
    return (
      <Row style={{ justifyContent: "flex-end", alignItems: "center" }}>
        <Button
          onClick={() => {
            setShowNewConnection(true);
            setFormMode("DUPLICATE");
            setSelectedRecord({
              ...record,
              configuration: JSON.stringify({
                ...JSON.parse(record.configuration),
                s3Bucket: "",
              }),
            });
          }}
          style={{
            height: 35,
            color: "#8B8B8B",
            background: "transparent",
            border: "1px solid #8B8B8B",
          }}
          type="default"
        >
          <img
            style={{ marginRight: 8, cursor: "pointer" }}
            alt="duplicate"
            src={require("../../../assets/duplicate_icon.png")}
          />
          <label
            className="connection-tile-font"
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: "400",
              cursor: "pointer",
            }}
          >
            Duplicate
          </label>
        </Button>
        <Button
          className="infosum-edit-button"
          onClick={() => {
            if (record.status !== "PROCESSING") {
              setFormMode("EDIT");
              setSelectedRecord(record);
              setShowNewConnection(true);
            }
          }}
          style={{
            marginLeft: 8,
            height: 35,
            color: "#8B8B8B",
            background: "transparent",
            border: "1px solid #8B8B8B",
          }}
          type="default"
        >
          <img
            alt="duplicate"
            src={
              checkIfEditDisabled(record)
                ? require("../../../assets/edit_disabled.png")
                : require("../../../assets/edit_icon.png")
            }
            style={{ marginRight: 8, cursor: "pointer", height: 16, width: 16 }}
          />
          <label
            className="connection-tile-font"
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: "400",
              cursor: "pointer",
            }}
          >
            Edit
          </label>
        </Button>
        {displayStopOrDeleteButton(record)}
      </Row>
    );
  }

  function displayStopOrDeleteButton(record) {
    if (
      record.status === "PROCESSING" ||
      record.status === "SENT_TO_EMAILCHECKER"
    ) {
      return (
        <img
          alt="stop"
          onClick={() => {
            // stopConnectionAPICall(record.id);
          }}
          src={require("../../../assets/stop_icon.png")}
          style={{
            marginLeft: 16,
            marginRight: 16,
            cursor: "pointer",
            width: 16,
          }}
        />
      );
    } else {
      return (
        <img
          alt="delete"
          onClick={() => {
            setSelectedConnectionToDelete(record.id);
          }}
          src={require("../../../assets/remove_icon.png")}
          style={{
            marginLeft: 16,
            marginRight: 16,
            cursor: "pointer",
            width: 16,
          }}
        />
      );
    }
  }

  useEffect(() => {
    getS3EndpointsCall();
    setInterval(() => {
      getS3EndpointsCall(true);
    }, 60000);
  }, []);

  useEffect(() => {
    if (cohortList.length > 0 && selectedConnectionConfiguration?.selectedCohortId) {
      setSelectedCohort(cohortList.find(cl => cl.id === selectedConnectionConfiguration.selectedCohortId) ?? {})
    }
  }, [selectedConnectionDetails, cohortList])

  async function startFullRefresh() {
    try {
      setShowFullRefreshIndicator(true);
      setShowFullRefreshModal(false);
      setDisplayFullRefreshLoading(true);
      selectedConnectionDetails?.id &&
        (await dataEndpointFullRefresh(selectedConnectionDetails.id));
      getS3EndpointsCall();
    } catch (error) {
      console.log("Unable to do the full refresh at the moment");
      console.log(error);
    }
    setTimeout(() => {
      setDisplayFullRefreshLoading(false);
    }, 10000);
  }

  async function updateS3EndPointCall(reqBody, id) {
    setDisplayLoading(true);
    try {
      await updateS3DataEndPoint(reqBody, id, DATA_ENDPOINTS.AWS);
      setShowNewConnection(false);
      getS3EndpointsCall();
    } catch (error) {
      console.log("Unable to update the endpoint details at the moment");
      console.log(error);
    }
    setDisplayLoading(false);
  }

  async function newS3DataEndPointCall(reqBody) {
    setDisplayLoading(true);
    try {
      const { data } = await newS3DataEndPoint(reqBody);
      setSelectedConnectionDetails(data);
      getS3EndpointsCall();
    } catch (error) {
      console.log("Unable to create new data endpoint at the moment");
      console.log(error);
    }
    setDisplayLoading(false);
  }

  async function getS3EndpointsCall(dontChangeApp = false) {
    try {
      setDisplayLoading(true);
      const data = await getS3EndPoints();
      if (data.success) {
        setAWSConnections(data.data);
        !dontChangeApp && setSelectedConnectionDetails(data?.data?.[0]);
      }
    } catch (error) {
      console.log("Unable to get the S3 endpoints");
      console.log(error);
    }
    setDisplayLoading(false);
  }

  async function deleteAWSEndpointCall() {
    try {
      await deleteAWSEndpoint(selectedConnectionToDelete);
      setSelectedConnectionToDelete(undefined);
      getS3EndpointsCall();
    } catch (error) {
      console.log("Unable to delete the endpoint at th eommoent");
      console.log(error);
    }
  }

  function renderEmptySection() {
    if (!displayLoading && awsConnections?.length <= 0 && !showNewConnection) {
      return (
        <div
          style={{
            marginLeft: 90,
            marginRight: 90,
            minHeight: "50vh",
            background: "black",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img alt="logo_grey" src={require("../../../assets/logo_grey.png")} />
          <div
            style={{
              marginTop: 42,
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography.Text
              style={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "24px",
                color: "#fff",
              }}
            >
              Connect lockr with AWS
            </Typography.Text>
            <Button
              style={{ marginTop: 24, width: "217px" }}
              type="primary"
              className="btn-first-connection"
              onClick={() => {
                setFormMode("NEW");
                setNewConnectionDetails(defaultConnectionDetails);
                setShowNewConnection(true);
              }}
            >
              Add New Connection
            </Button>
          </div>
        </div>
      );
    }
  }

  function checkNewFormValidation() {
    let validation = true;
    if (
      newConnectionDetails?.name?.trim?.() === "" ||
      newConnectionDetails.s3Bucket?.trim?.() === "" ||
      newConnectionDetails?.fileName?.trim?.() === "" ||
      newConnectionDetails?.region?.trim?.() === "" ||
      (newConnectionDetails.accessType === "AWS IAM" &&
        (newConnectionDetails?.roleARN?.trim?.() === "" ||
          newConnectionDetails?.sessionName?.trim?.() === "")) ||
      (newConnectionDetails.accessType === "AWS Access Key" &&
        (!newConnectionDetails?.accessKey?.trim?.() ||
          !newConnectionDetails?.secretKey?.trim?.()))
    ) {
      validation = false;
    }
    return validation;
  }

  function onSubmitButtonClick() {
    const details = {
      ...newConnectionDetails,
      externalId:
        formMode === "EDIT" ? newConnectionDetails.externalId : externalId,
      selectedCohortId: selectedCohort?.id ?? undefined
    };

    if (formMode === "EDIT") {
      updateS3EndPointCall(details, selectedConnectionDetails.id);
      setFormMode("NEW");
      setSelectedRecord(undefined);
      setNewConnectionDetails(defaultConnectionDetails);
      return;
    }
    newS3DataEndPointCall(details);
    setShowNewConnection(false);
    setNewConnectionDetails(defaultConnectionDetails);
  }

  function renderNewDataEndpointForm() {
    return (
      <div
        style={{
          background: "black",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          paddingLeft: 20,
          paddingTop: 24,
          paddingBottom: 24,
          paddingRight: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TitleText
            value={newConnectionDetails?.name}
            onChange={(event) => {
              setNewConnectionDetails({
                ...newConnectionDetails,
                name: event.target.value,
              });
            }}
            placeholder="Connection Name"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 8,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{ width: "200px" }}
              type="primary"
              size="small"
              disabled={!checkNewFormValidation()}
              className="sign-in dataendpoint-submit-button"
              onClick={() => {
                onSubmitButtonClick();
              }}
            >
              {formMode === "EDIT" ? "Save" : "Submit"}
            </Button>
            <Button
              style={{ width: "200px" }}
              type="primary"
              size="small"
              className="sign-in dataendpoint-submit-button"
              onClick={() => {
                setShowNewConnection(false);
              }}
            >
              Cancel
            </Button>
            <div>
              <img
                alt="delete"
                onClick={() => {
                  setSelectedConnectionToDelete(selectedConnectionDetails.id);
                }}
                src={require("../../../assets/remove_icon.png")}
                style={{
                  marginLeft: 16,
                  marginRight: 16,
                  cursor: "pointer",
                  width: 18,
                  height: 20,
                  marginTop: -3,
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            paddingTop: 24,
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 24,
            border: "1px solid #353738",
            width: "100%",
            marginTop: 20,
          }}
        >
          <span
            style={{
              color: "white",
              fontSize: 14,
              fontWeight: "500",
              fontFamily: "Montserrat",
            }}
          >
            Connection Settings
          </span>
          <div
            style={{
              marginTop: 20,
            }}
          >
            <DataEndpointTitleInput
              title={"AWS Access Type"}
              infoText={
                "lockr recommends using AWS IAM roles for its enhanced security."
              }
              customInput={
                <DataEndpointAWSAccessDropDown
                  value={newConnectionDetails.accessType ?? "AWS IAM"}
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      accessType: event,
                    });
                  }}
                />
              }
            />
          </div>
          {(newConnectionDetails.accessType === "AWS IAM" ||
            !newConnectionDetails.accessType) && (
              <div style={{ marginTop: 20 }}>
                <DataEndpointTitleInput
                  title={"Role ARN"}
                  value={newConnectionDetails.roleARN}
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      roleARN: event.target.value,
                    });
                  }}
                />
              </div>
            )}
          {(newConnectionDetails.accessType === "AWS IAM" ||
            !newConnectionDetails.accessType) && (
              <div style={{ marginTop: 20 }}>
                <DataEndpointTitleInput
                  title={"External ID"}
                  value={
                    formMode === "EDIT"
                      ? newConnectionDetails.externalId
                      : externalId
                  }
                  disabled
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      externalId: event.target.value,
                    });
                  }}
                />
              </div>
            )}
          {(newConnectionDetails.accessType === "AWS IAM" ||
            !newConnectionDetails.accessType) && (
              <div style={{ marginTop: 20 }}>
                <DataEndpointTitleInput
                  title={"Session Name"}
                  value={newConnectionDetails.sessionName}
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      sessionName: event.target.value,
                    });
                  }}
                />
              </div>
            )}
          {newConnectionDetails.accessType === "AWS Access Key" && (
            <div style={{ marginTop: 20 }}>
              <DataEndpointTitleInput
                title={"Access Key"}
                value={newConnectionDetails.accessKey}
                onChange={(event) => {
                  setNewConnectionDetails({
                    ...newConnectionDetails,
                    accessKey: event.target.value,
                  });
                }}
              />
            </div>
          )}
          {newConnectionDetails.accessType === "AWS Access Key" && (
            <div style={{ marginTop: 20 }}>
              <DataEndpointTitleInput
                title={"Secret Key"}
                value={newConnectionDetails.secretKey}
                onChange={(event) => {
                  setNewConnectionDetails({
                    ...newConnectionDetails,
                    secretKey: event.target.value,
                  });
                }}
              />
            </div>
          )}
        </div>
        <div
          style={{
            paddingTop: 24,
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 24,
            border: "1px solid #353738",
            width: "100%",
            marginTop: 20,
          }}
        >
          <span
            style={{
              color: "white",
              fontSize: 14,
              fontWeight: "500",
              fontFamily: "Montserrat",
            }}
          >
            Bucket Settings
          </span>
          <div style={{ marginTop: 20 }}>
            <DataEndpointTitleInput
              title={"S3 Bucket"}
              value={newConnectionDetails.s3Bucket}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  s3Bucket: event.target.value,
                });
              }}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <DataEndpointTitleInput
              title={"Region"}
              value={newConnectionDetails.region}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  region: event.target.value,
                });
              }}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <DataEndpointTitleInput
              title={"File Name"}
              value={newConnectionDetails.fileName}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  fileName: event.target.value,
                });
              }}
            />
          </div>
        </div>
        <div
          style={{
            paddingTop: 24,
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 24,
            border: "1px solid #353738",
            width: "100%",
            marginTop: 20,
          }}
        >
          <span
            style={{
              color: "white",
              fontSize: 14,
              fontWeight: "500",
              fontFamily: "Montserrat",
            }}
          >
            Delivery Settings
          </span>
          <div style={{ marginTop: 20 }}>
            <DataEndpointTitleInput
              title={"Delivery Type"}
              customInput={
                <DataEndpointTypeDropDown
                  value={newConnectionDetails.type}
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      type: event,
                      frequency:
                        newConnectionDetails.frequency === "Hourly" &&
                          event === "Complete Dump"
                          ? "Daily"
                          : newConnectionDetails.frequency,
                    });
                  }}
                />
              }
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <DataEndpointTitleInput
              title={"Frequency"}
              customInput={
                <FrequencyDropDown
                  hideYearly
                  hideHourly={newConnectionDetails.type === "Complete Dump"}
                  value={newConnectionDetails.frequency}
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      frequency: event,
                    });
                  }}
                />
              }
            />
          </div>
        </div>
        <div
          style={{
            paddingTop: 24,
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 24,
            border: "1px solid #353738",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: 20,
          }}
        >
          <AppSelectionDropdown value={newConnectionDetails?.appIDs} onChange={appIDs => {
            setNewConnectionDetails(prevState => ({
              ...prevState,
              appIDs
            }))
          }}
            domainsValue={newConnectionDetails?.domains}
            onDomainChange={domains => {
              setNewConnectionDetails(prevState => ({
                ...prevState,
                domains
              }))
            }}
          />
        </div>
        <div
          style={{
            paddingTop: 24,
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 24,
            border: "1px solid #353738",
            width: "100%",
            marginTop: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                color: "white",
                fontSize: 14,
                fontWeight: "500",
                fontFamily: "Montserrat",
              }}
            >
              Cohorts
            </span>
            <span
              style={{
                marginTop: 16,
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: 12,
                color: "#8B8B8B",
              }}
            >
              Select an existing Cohort
            </span>

            <CommonDropdown placeholder="Select Cohort" value={selectedCohort?.id} onChange={(c) => setSelectedCohort(cohortList.find((cl) => cl.id === c))} options={cohortList.filter(c => c.configuration.appliedFilters.filters.length > 0).map((c) => { return { label: c.configuration.name, value: c.id } })} />
            <CustomConditionsBuilder attributes={attributes} editEnabled={false} key={""} selectedCohort={selectedCohort} />
          </div>
        </div>
        <div
          style={{
            paddingTop: 24,
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 24,
            border: "1px solid #353738",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: 20,
          }}
        >
          <span
            style={{
              color: "white",
              fontSize: 14,
              fontWeight: "500",
              fontFamily: "Montserrat",
            }}
          >
            Attributes
          </span>
          <span
            style={{
              marginTop: 16,
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: 12,
              color: "#8B8B8B",
            }}
          >
            Select the attributes to include for this connection with the
            endpoint.
          </span>

          <div
            style={{
              marginTop: 30,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.firstName}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  firstName: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              First Name
            </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.lastName}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  lastName: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              Last Name
            </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.rawEmail}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  rawEmail: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              Email
            </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.sha256}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  sha256: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              Email Hash
            </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.uid2Enabled}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  uid2Enabled: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              UID2 (latest)
            </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.uid2EnabledHistory}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  uid2EnabledHistory: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              UID2 (history)
            </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.id5Enabled}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  id5Enabled: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              ID5 (latest)
            </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.id5EnabledHistory}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  id5EnabledHistory: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              ID5 (history)
            </span>
          </div>

          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.liverampEnabled}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  liverampEnabled: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              ATS (latest)
            </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.liverampEnabledHistory}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  liverampEnabledHistory: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              ATS (history)
            </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.euidEnabled}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  euidEnabled: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              EUID (latest)
            </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.euidEnabledHistory}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  euidEnabledHistory: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              EUID (history)
            </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.firstPartyCookies}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  firstPartyCookies: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              First Party Cookies
            </span>
          </div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.successEvent}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  successEvent: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              Authentication Method
            </span>
          </div>

          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.consentString}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  consentString: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              TCF String
            </span>
          </div>

          <div
            style={{
              marginTop: 20,
              paddingLeft: 32,
            }}
          >
            <Checkbox
              checked={newConnectionDetails?.dateCreatedEnabled}
              onChange={(event) => {
                setNewConnectionDetails({
                  ...newConnectionDetails,
                  dateCreatedEnabled: event.target.checked,
                });
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                marginLeft: 12,
                fontSize: 16,
                fontWeight: "500",
              }}
            >
              Created At
            </span>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <Button
            style={{ width: "200px" }}
            type="primary"
            size="small"
            className="sign-in"
            disabled={!checkNewFormValidation()}
            onClick={() => {
              onSubmitButtonClick();
            }}
          >
            {formMode === "EDIT" ? "Save" : "Submit"}
          </Button>
        </div>
      </div>
    );
  }

  function renderMainSections() {
    if (awsConnections?.length || showNewConnection) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: 90,
            paddingRight: 90,
            position: "relative",
          }}
        >
          <div
            style={{
              maxWidth: 294,
              display: "flex",
              flex: 1,
              background: "#353738",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <div
              style={{
                paddingBottom: 60,
              }}
            >
              {showNewConnection && formMode !== "EDIT" && (
                <div
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    minHeight: 60,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    background: "black",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      cursor: "pointer",
                      left: 0,
                      height: "100%",
                      width: 4,
                      background: "#61CEF7",
                    }}
                  />
                  <span
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: 18,
                      fontWeight: "500",
                      cursor: "pointer",
                      color: "#61CEF7",
                    }}
                  >
                    {newConnectionDetails.name ?? "New Connection"}
                  </span>
                </div>
              )}
              {awsConnections?.map?.((item) => {
                return (
                  <div
                    onClick={() => {
                      setShowNewConnection(false);
                      setSelectedConnectionDetails(item);
                    }}
                    style={{
                      paddingLeft: 20,
                      paddingRight: 20,
                      minHeight: 60,
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                      background:
                        selectedConnectionDetails?.id === item?.id &&
                          (!showNewConnection ||
                            (showNewConnection && formMode === "EDIT"))
                          ? "black"
                          : "#353738",
                      width: "100%",
                    }}
                  >
                    {selectedConnectionDetails?.id === item?.id &&
                      (!showNewConnection ||
                        (showNewConnection && formMode === "EDIT")) && (
                        <div
                          style={{
                            position: "absolute",
                            left: 0,
                            height: "100%",
                            width: 4,
                            background: "#61CEF7",
                          }}
                        />
                      )}
                    <span
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: 18,
                        fontWeight: "500",
                        color:
                          selectedConnectionDetails?.id === item.id &&
                            !showNewConnection
                            ? "#61CEF7"
                            : "white",
                      }}
                    >
                      {JSON.parse(item.configuration)?.name ?? "Connection"}
                    </span>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                position: "absolute",
                bottom: 24,
                left: 24,
              }}
            >
              <Button
                style={{ width: "248px" }}
                type="primary"
                className="sign-in"
                onClick={() => {
                  setFormMode("NEW");
                  setNewConnectionDetails(
                    JSON.parse(JSON.stringify(defaultConnectionDetails))
                  );
                  setShowNewConnection(true);
                }}
              >
                Add New Connection
              </Button>
            </div>
          </div>
          {showNewConnection ? (
            renderNewDataEndpointForm()
          ) : (
            <div
              style={{
                background: "black",
                flex: 1,
                display: "flex",
                flexDirection: "column",
                paddingLeft: 20,
                paddingTop: 24,
                paddingBottom: 24,
                paddingRight: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: "white",
                    fontSize: 20,
                    fontFamily: "Montserrat",
                  }}
                >
                  {selectedConnectionConfiguration?.name ?? "Connection"}
                </span>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    gap: 8,
                  }}
                >
                  <Button
                    type={"primary"}
                    style={{ width: 200 }}
                    loading={false}
                    onClick={() => {
                      setFormMode("EDIT");
                      setShowNewConnection(true);
                      setNewConnectionDetails({
                        ...selectedConnectionConfiguration,
                      });
                    }}
                    className="sign-in dataendpoint-submit-button"
                  >
                    {"Edit"}
                  </Button>
                  <Button
                    type={"primary"}
                    style={{ width: 200 }}
                    loading={false}
                    onClick={() => {
                      setFormMode("DUPLICATE");
                      setShowNewConnection(true);
                      setNewConnectionDetails({
                        ...selectedConnectionConfiguration,
                        s3Bucket: "",
                        name: "",
                      });
                    }}
                    className="sign-in dataendpoint-submit-button"
                  >
                    {"Duplicate"}
                  </Button>
                  <img
                    alt="delete"
                    onClick={() => {
                      setSelectedConnectionToDelete(
                        selectedConnectionDetails.id
                      );
                    }}
                    src={require("../../../assets/remove_icon.png")}
                    style={{
                      marginLeft: 16,
                      marginRight: 16,
                      cursor: "pointer",
                      width: 18,
                      height: 20,
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  paddingTop: 24,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingBottom: 24,
                  border: "1px solid #353738",
                  width: "100%",
                  marginTop: 20,
                }}
              >
                <span
                  style={{
                    color: "white",
                    fontSize: 14,
                    fontWeight: "500",
                    fontFamily: "Montserrat",
                  }}
                >
                  Connection Settings
                </span>
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  <DataEndpointTitleValue
                    title={"AWS Access Type"}
                    infoText={
                      "lockr recommends using AWS IAM roles for its enhanced security."
                    }
                    value={
                      selectedConnectionConfiguration?.accessType ?? "AWS IAM"
                    }
                  />
                </div>
                {(selectedConnectionConfiguration?.accessType === "AWS IAM" ||
                  !selectedConnectionConfiguration?.accessType) && (
                    <div
                      style={{
                        marginTop: 20,
                      }}
                    >
                      <DataEndpointTitleValue
                        title={"Role ARN"}
                        value={selectedConnectionConfiguration?.roleARN ?? ""}
                      />
                    </div>
                  )}
                {(selectedConnectionConfiguration?.accessType === "AWS IAM" ||
                  !selectedConnectionConfiguration?.accessType) && (
                    <div
                      style={{
                        marginTop: 20,
                      }}
                    >
                      <DataEndpointTitleValue
                        title={"External ID"}
                        value={selectedConnectionConfiguration?.externalId ?? ""}
                      />
                    </div>
                  )}
                {(selectedConnectionConfiguration?.accessType === "AWS IAM" ||
                  !selectedConnectionConfiguration?.accessType) && (
                    <div
                      style={{
                        marginTop: 20,
                      }}
                    >
                      <DataEndpointTitleValue
                        title={"Session Name"}
                        value={selectedConnectionConfiguration?.sessionName ?? ""}
                      />
                    </div>
                  )}
                {selectedConnectionConfiguration?.accessType ===
                  "AWS Access Key" && (
                    <div
                      style={{
                        marginTop: 20,
                      }}
                    >
                      <DataEndpointTitleValue
                        title={"Access Key"}
                        value={selectedConnectionConfiguration?.accessKey ?? ""}
                      />
                    </div>
                  )}
                {selectedConnectionConfiguration?.accessType ===
                  "AWS Access Key" && (
                    <div
                      style={{
                        marginTop: 20,
                      }}
                    >
                      <DataEndpointTitleValue
                        title={"Secret Key"}
                        value={selectedConnectionConfiguration?.secretKey ?? ""}
                      />
                    </div>
                  )}
              </div>
              <div
                style={{
                  paddingTop: 24,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingBottom: 24,
                  border: "1px solid #353738",
                  width: "100%",
                  marginTop: 20,
                }}
              >
                <span
                  style={{
                    color: "white",
                    fontSize: 14,
                    fontWeight: "500",
                    fontFamily: "Montserrat",
                  }}
                >
                  Bucket Settings
                </span>
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  <DataEndpointTitleValue
                    title={"S3 Bucket"}
                    value={selectedConnectionConfiguration?.s3Bucket ?? ""}
                  />
                </div>
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  <DataEndpointTitleValue
                    title={"Region"}
                    value={selectedConnectionConfiguration?.region ?? ""}
                  />
                </div>
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  <DataEndpointTitleValue
                    title={"File Name"}
                    value={selectedConnectionConfiguration?.fileName ?? ""}
                  />
                </div>
              </div>
              <div
                style={{
                  paddingTop: 24,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingBottom: 24,
                  border: "1px solid #353738",
                  width: "100%",
                  marginTop: 20,
                }}
              >
                <span
                  style={{
                    color: "white",
                    fontSize: 14,
                    fontWeight: "500",
                    fontFamily: "Montserrat",
                  }}
                >
                  Delivery Settings
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    marginTop: 20,
                    gap: 32,
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <DataEndpointTitleValue
                      title={"Delivery Type"}
                      showDropdown={true}
                      value={
                        selectedConnectionConfiguration?.type ===
                          "Complete Dump"
                          ? "Complete"
                          : selectedConnectionConfiguration?.type ?? ""
                      }
                    />
                  </div>
                  {selectedConnectionConfiguration?.type !==
                    "Complete Dump" && (
                      <Button
                        style={{ width: "200px" }}
                        type="primary"
                        size="small"
                        className="data-endpoint-refresh-button"
                        onClick={() => {
                          setShowFullRefreshModal(true);
                        }}
                      >
                        Full Refresh
                      </Button>
                    )}
                  {false &&
                    (displayFullRefreshLoading ||
                      selectedConnectionConfiguration?.status ===
                      "PROCESSING" ||
                      selectedConnectionConfiguration?.status ===
                      "IN_PROGRESS") && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          height: 48,
                          gap: 8,
                          justifyContent: "center",
                        }}
                      >
                        <RotatingLines width="20" strokeColor="white" />
                        <label
                          style={{
                            color: "white",
                            marginTop: 12,
                            fontSize: 12,
                            fontWeight: "400",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Transferring Data
                        </label>
                      </div>
                    )}
                </div>
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  <DataEndpointTitleValue
                    title={"Delivery Frequency"}
                    showDropdown={true}
                    value={selectedConnectionConfiguration?.frequency ?? ""}
                  />
                </div>
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  <span
                    style={{
                      fontSize: 14,
                      color: "white",
                      fontWeight: "500",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Delivery Status
                  </span>
                  <div
                    style={{
                      display: "flex",
                      marginTop: 8,
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        height: 10,
                        width: 10,
                        borderRadius: 20,
                        background: mapSnowflakeStatusToColor(
                          selectedConnectionDetails?.status
                        ),
                      }}
                    />
                    <span
                      className="connection-tile-font"
                      style={{
                        marginLeft: 8,
                        color: "white",
                        fontSize: 18,
                        fontFamily: "Montserrat",
                      }}
                    >
                      {mapSnowflakStatusToUI(selectedConnectionDetails?.status)}
                    </span>
                  </div>
                </div>
              </div>

              <div
                style={{
                  paddingTop: 24,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingBottom: 24,
                  border: "1px solid #353738",
                  width: "100%",
                  marginTop: 20,
                }}
              >
                <AppSelectionDropdown disabled={true} value={selectedConnectionConfiguration?.appIDs} onChange={appIDs => {
                  setNewConnectionDetails(prevState => ({
                    ...prevState,
                    appIDs
                  }))
                }}
                  domainsValue={selectedConnectionConfiguration?.domains}
                  onDomainChange={domains => {
                    setNewConnectionDetails(prevState => ({
                      ...prevState,
                      domains,
                    }))
                  }}
                />
              </div>
              <div
                style={{
                  paddingTop: 24,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingBottom: 24,
                  border: "1px solid #353738",
                  width: "100%",
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      color: "white",
                      fontSize: 14,
                      fontWeight: "500",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Cohorts
                  </span>
                  <span
                    style={{
                      marginTop: 16,
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: 12,
                      color: "#8B8B8B",
                    }}
                  >
                    Select an existing Cohort
                  </span>

                  <CommonDropdown disabled placeholder="Select Cohort" value={selectedCohort?.id ?? ""} onChange={(c) => setSelectedCohort(cohortList.find((cl) => (cl?.id && cl?.id === c)))} options={cohortList.filter(c => c.configuration.appliedFilters.filters.length > 0).map((c) => { return { label: c.configuration.name, value: c.id } })} />
                  <CustomConditionsBuilder attributes={attributes} editEnabled={false} key={""} selectedCohort={selectedCohort} />
                </div>
              </div>
              <div
                style={{
                  paddingTop: 24,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingBottom: 24,
                  border: "1px solid #353738",
                  width: "100%",
                  marginTop: 20,
                }}
              >

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      color: "white",
                      fontSize: 14,
                      fontWeight: "500",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Attributes
                  </span>
                  <span
                    style={{
                      marginTop: 16,
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: 12,
                      color: "#8B8B8B",
                    }}
                  >
                    Select the attributes to include for this connection with
                    the endpoint.
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 32,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.firstName}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    First Name
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.lastName}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    Last Name
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.rawEmail}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    Email
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.sha256}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    Email Hash
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.uid2Enabled}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    UID2 (latest)
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={
                      selectedConnectionConfiguration?.uid2EnabledHistory
                    }
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    UID2 (history)
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.id5Enabled}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    ID5 (latest)
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.id5EnabledHistory}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    ID5 (history)
                  </span>
                </div>

                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.liverampEnabled}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    ATS (latest)
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={
                      selectedConnectionConfiguration?.liverampEnabledHistory
                    }
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    ATS (history)
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.euidEnabled}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    EUID (latest)
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={
                      selectedConnectionConfiguration?.euidEnabledHistory
                    }
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    EUID (history)
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.firstPartyCookies}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    First Party Cookies
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.successEvent}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    Authentication Method
                  </span>
                </div>

                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.consentString}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    TCF String
                  </span>
                </div>

                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={
                      selectedConnectionConfiguration?.dateCreatedEnabled
                    }
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    Created At
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
    return <></>;
  }

  return (
    <SideDrawer>
      <Row
        justify="space-between"
        align="middle"
        className="apiandservices-newapp-btn"
      >
        <Col sm={17} xs={24}>
          <Typography.Title className="apiandservices-heading">
            AWS Data Endpoint
          </Typography.Title>
          <div style={{ marginTop: 8 }} />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <label
              style={{
                fontSize: 14,
                fontFamily: "Montserrat",
                color: "#8B8B8B",
              }}
              className="dashboard-as-of-today"
            >
              Seamlessly deliver audience data to a S3 instance for downstream use-cases.
            </label>
          </div>
        </Col>
        <Col sm={7} xs={24} className="createAppButton">
          <Button
            style={{ width: "248px" }}
            type="primary"
            className="sign-in"
            onClick={() => {
              setFormMode("NEW");
              setNewConnectionDetails(defaultConnectionDetails);
              setShowNewConnection(true);
            }}
          >
            Add New Connection
          </Button>
        </Col>
      </Row>
      {renderEmptySection()}
      {/* {renderTable()} */}
      {renderMainSections()}
      {!!selectedConnectionToDelete && (
        <DeleteTypeModal
          visible={!!selectedConnectionToDelete}
          onCancel={() => {
            setSelectedConnectionToDelete(undefined);
          }}
          title="Delete Connection"
          message={`Are you sure you want to delete this AWS connection? Doing so will disable all future deliveries to this bucket.`}
          handleCancel={() => {
            setSelectedConnectionToDelete(undefined);
          }}
          handleOk={deleteAWSEndpointCall}
        />
      )}
      <FullRefreshNoticeModal
        display={showFullRefreshModal}
        onCancel={() => {
          setShowFullRefreshModal(false);
        }}
        onOk={() => {
          startFullRefresh();
        }}
      />

      <ReturnToConnectionHubModal
        display={showFullRefreshIndicator}
        onOk={() => {
          setShowFullRefreshIndicator(false);
          history.push("/console/connections");
        }}
      />
      {showFullRefreshIndicator && (
        <FullRefreshLoader
          onClose={() => {
            setShowFullRefreshIndicator(false);
          }}
          dataConfigurationId={selectedConnectionDetails?.id}
        />
      )}
    </SideDrawer>
  );
}
