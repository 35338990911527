import { Dropdown, Input, Menu, Select } from 'antd'
import { Option } from 'antd/lib/mentions'
import React, { useEffect, useRef, useState } from 'react'
import { getVerticalList } from '../Utils/ApiActions';
import { AiOutlineCheck } from "react-icons/ai";
import { AiOutlineDown } from 'react-icons/ai';
import { PhoneNumberInput } from './PhoneNumberInput';



function VerticalItem(props) {


    return <div className='verticle-dropdown-option' onClick={() => { props.onChange(props.verticle) }} style={{ color: props.value === props.verticle.name ? "#61CEF7" : "#fff", }}>
        <div style={{ display: 'inline-block', width: 32, height: 12 }}>
            {props.value === props.verticle.name &&
                <img src={require('../assets/tick.png')}></img>}
        </div>
        {props.verticle.name}

    </div>
}

const RegCommonInput = (props) => {

    let tabRef = useRef(null)
    const [dropdownVisible, setDropdownVisible] = useState(false)



    const [selectValue, setSelectValue] = useState('')    

    const menu = (
        <div className='verticle-dropdown-container' style={{ height: 180, width: 445, background: 'black', overflowY: 'scroll' }}>
            {props?.verticleOptions?.map(verticle => (
                <VerticalItem value={props.value} verticle={verticle} onChange={(value) => { props.onChange(value); }}></VerticalItem>
            ))}
        </div>
    );


    // if (props.isDropDown) {
    //     return (<Select value={props.vertical} dropdownClassName='verticle-selecter-dropdown' onFocus={props.onFocus && props.onFocus()} className='verticle-selecter' style={{ width: 445, height: 55.5, borderColor: '#8d8d8d' }}
    //         onChange={(event) => {
    //             console.log(event)
    //             props?.onChange(event)

    //         }} >

    //         {props.verticleOptions.map(vertical => (<Option value={vertical.name}><AiOutlineCheck></AiOutlineCheck>{vertical.name}</Option>))}



    //     </Select>)
    // }
    if (props.isDropDown) {                
        return (
            <Dropdown visible={dropdownVisible} ref={e => {
            }} className='verticle-dropdown' overlay={menu} trigger={['click']} >
                <div class="lockr-signup-control" style={{ ...props.lockrformstyle, ...{ display: 'flex', flexDirection: 'row', alignItems: 'center' } }}>
                    <AiOutlineDown style={{ position: 'absolute', left: 415 }} ></AiOutlineDown>
                    <Input
                        onClick={() => { setDropdownVisible(true); props.onFocus() }}
                        onFocusCapture={() => { setDropdownVisible(true); }}
                        onBlurCapture={() => {
                            setTimeout(() => {
                                setDropdownVisible(false)
                            }, 200)
                        }}
                        style={{ color: '#fff', borderColor: '#8B8B8B', borderRadius: 2, borderTopWidth: 0 }} onChange={(event) => {
                            props?.onChange(event)
                        }} placeholder={props.placeholder}
                        maxLength={props?.maxLength}
                        {...props}
                        readOnly
                    >

                    </Input>
                </div>
            </Dropdown>
            // <Select ref={e => {
            //     tabRef = e;
            // }} onSelect={value => { setSelectValue(value); console.log(value) }} value={selectValue}>
            //     <Option value='Hello'></Option>
            // </Select>
        )
    }
    else {
        return (
            <div class="lockr-signup-control" style={{ ...props.lockrformstyle, ...{ display: 'flex', flexDirection: 'row', alignItems: 'center' } }}>
                {props.isPhoneNumber ?
                    <PhoneNumberInput onBlur={props.onBlur} onFocus={props.onFocus} onChange={props?.onChange} value={props?.value} inputClass="step3-phone-input" style={{ color: '#fff', borderColor: '#8B8B8B', borderRadius: 2, border: '1px solid #8b8b8b', borderTopWidth: 0 }}></PhoneNumberInput> :
                    <Input onKeyDown={e => {
                        if (e.key === 'Tab' && props.captureTab) {

                            // console.log(tabRef)
                            tabRef?.current?.focus()
                        }
                    }} style={{ color: '#fff', borderColor: '#8B8B8B', borderRadius: 2 }} onChange={(event) => {
                        props?.onChange(event)
                    }} placeholder={props.placeholder}
                        maxLength={props?.maxLength}
                        {...props}>

                    </Input>}
            </div>
        )
    }
}

export default RegCommonInput