import React, { useState } from 'react';
import SideDrawer from '../../Components/SideDrawer';
import { Button, Col, Dropdown, Input, Row, Typography } from 'antd';
import FloatLabel from '../../Components/FloatLabel/FloatLabel';
import { AiOutlineDown } from 'react-icons/ai';
import { TitleText } from '../../Components/NewAppModal';
import RegCommonInput from '../../Components/RegCommonInput';
import { submitContactForm } from '../../Utils/ApiActions';

function ContactFormPicker({ value, onChange }) {
    const menu = (
        <div className='verticle-dropdown-container' style={{ height: 210, background: 'black', overflowY: 'scroll', marginTop: 6 }}>
            <div style={{ cursor: 'pointer', color: '#fff' }} className='frequency-dropdown-option' onClick={() => {
                onChange('Product Feedback')
            }}>
                Product Feedback
            </div>
            <div style={{ cursor: 'pointer', color: '#fff' }} className='frequency-dropdown-option' onClick={() => {
                onChange('Feature Request')
            }}>
                Feature Request
            </div>
            <div style={{ cursor: 'pointer', color: '#fff' }} className='frequency-dropdown-option' onClick={() => {
                onChange('Customer Support')
            }}>
                Customer Support
            </div>
            <div style={{ cursor: 'pointer', color: '#fff' }} className='frequency-dropdown-option' onClick={() => {
                onChange('API Support')
            }}>
                API Support
            </div>
            <div style={{ cursor: 'pointer', color: '#fff' }} className='frequency-dropdown-option' onClick={() => {
                onChange('Connections Hub')
            }}>
                Connections Hub
            </div>
            <div style={{ cursor: 'pointer', color: '#fff' }} className='frequency-dropdown-option' onClick={() => {
                onChange('Press Inquiries')
            }}>
                Press Inquiries
            </div>
        </div>
    )

    return (
        <div style={{ width: '450px' }}>
            <Dropdown overlay={menu} trigger={['click']} className='contact-form-dropdown'>
                <div className='lockr-signup-control' style={{ position: 'relative', height: 48, }}>
                    <AiOutlineDown style={{
                        position: 'absolute',
                        right: 16, top: 22,
                        color: 'white'
                    }} />
                    <div className='lockr-form-control' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Input readOnly value={value} style={{ color: '#fff', border: '0px' }} />
                    </div>
                </div>
            </Dropdown>
        </div>
    )

}

function ContactFormInput({ lockrFormStyle, onChange, placeholder }) {
    return (
        <div className='lockr-form-control' style={{ ...lockrFormStyle, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Input style={{ color: '#fff', border: '0px' }} onChange={onChange} placeholder={placeholder}>
            </Input>
        </div>
    )
}

function MessageFormInput({ onChange }) {
    return <div className='lockr-form-control' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Input.TextArea style={{ color: '#fff', border: '0px', minHeight: '210px', paddingTop: 24, paddingLeft: 16 }} onChange={onChange}></Input.TextArea>
    </div>
}

export function ContactForm(props) {

    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [selectedCase, setSelectedCase] = useState('Product Feedback');
    const [displayLoading, setDisplayLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    function checkValidation() {
        let validation = true;
        if (subject.trim() === '' || message.trim() === '' || selectedCase.trim() === '') {
            validation = false;
        }
        return validation
    }

    function renderSuccess() {
        return <div style={{ marginLeft: 90, marginRight: 90, display: 'flex', flexDirection: 'column' }}>            
            <label style={{ maxWidth: 600, marginTop: 16, color: 'white', fontSize: 14, fontFamily: 'Montserrat' }}>Thank you for contacting lockr. One of our colleagues will be in touch with you soon.</label>
            <label style={{ marginTop: 16, color: 'white', fontSize: 14, fontFamily: 'Montserrat' }}>Have a great day!</label>
        </div>
    }

    async function submitForm() {
        setDisplayLoading(true);
        try {
            const response = await submitContactForm(subject, message, selectedCase);
            if (response.success && response.data) {
                setIsSuccess(true);
                return;
            }
            setIsSuccess(false);
        } catch (error) {
            setIsSuccess(false);
        }
        setDisplayLoading(false);
    }


    return (
        <SideDrawer>
            <Row
                justify='space-between'
                align='middle'
                className='apiandservices-newapp-btn'
            >
                <Col sm={24}>
                    <Typography.Title className='support-heading'>We're here to help.</Typography.Title>
                </Col>
            </Row>
            {isSuccess && renderSuccess()}
            {!isSuccess && <div style={{
                paddingLeft: 90,
                paddingRight: 90
            }}>
                <FloatLabel value={subject} label={'Subject'}>
                    <ContactFormInput
                        onChange={event => {
                            setSubject(event.target.value);
                        }}
                    />
                </FloatLabel>
                <div style={{ marginTop: 24 }} />
                <FloatLabel value={selectedCase} label={'Topic'}>
                    <ContactFormPicker onChange={value => {
                        setSelectedCase(value)
                    }} value={selectedCase} />
                </FloatLabel>
                <div style={{ marginTop: 32 }} />
                <FloatLabel value={message} label={'Message'}>
                    <MessageFormInput
                        onChange={event => { setMessage(event.target.value) }}
                    />
                </FloatLabel>
                <div style={{ marginTop: 32, width: '220px' }}>
                    <Button
                        disabled={!checkValidation()}
                        block
                        size='large'
                        onClick={submitForm}
                        loading={displayLoading}
                        style={{ background: '#000' }}
                        className='sign-in'>
                        Send
                    </Button>
                </div>
            </div>}
        </SideDrawer>
    )
}