import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useQuery } from '../../Components/customHook/useQuery'
import { listAllApplications, syncSnowflake } from '../../Utils/ApiActions';

function SnowflakeIntegrationCallback() {

    const [isProcessingSuccess, setIsProcessingSuccess] = useState(false);
    const [selectedApp, setSelectedApp] = useState(null);
    const query = useQuery();
    const history = useHistory();
    const code = query.get('code');

    useEffect(()=>{
        fetchAppList();
    }, [])

    useEffect(()=>{
        if(selectedApp?.id){
            postValidation();
        }
    }, [selectedApp])

    function fetchAppList(){
        listAllApplications().then(applicationList=>{
            setSelectedApp(applicationList?.data?.[0]);            
        }).catch(error=>{

        })
    }

    function postValidation(){
        if (code) {
            //we have the code, let's try to connect with snowflake.
            syncSnowflake(code, selectedApp.id).then(response => {
                setIsProcessingSuccess(true);
                setTimeout(() => {
                    history.push('/console/snowflake-integration');
                }, 5000)
            }).catch(error => {
            })
        } else {
            history.push('/console/snowflake-integration');
        }
    }

    return (
        <div style={{ fontSize: 32, height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
            {isProcessingSuccess ? 'Input is pushed for processing, results will be stored in the snowflake account / lockr_email_verified_results' : 'Loading...'}
        </div>
    )
}

export default SnowflakeIntegrationCallback