
import React from "react"
import PhoneInput from "react-phone-input-2"

export function PhoneNumberInput(props) {
    // console.log(props)

    const { value, onChange } = props

    function handleChange(phone) {

        if (onChange) {

            onChange(phone)
        }
    }

    return (
        <>
            <PhoneInput
                buttonClass="flag-class"
                onBlur={() => { props.onBlur() }}
                onFocus={() => { props.onFocus() }}
                inputStyle={props.style}
                inputClass={props.inputClass}
                placeholder=""
                enableLongNumbers={11}
                country={'us'}
                value={value}
                onChange={phone => handleChange(phone)}
            />
        </>
    )
}