import { Button, Modal, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { TitleText } from './NewAppModal';

export function UserReviewModal({ onSave, visible, onCancel, fullName }) {

    const [reviewDetails, setReviewDetails] = useState({
        name: '',
        address: '',
        position: '',
        email: '',
        signature: '',
        entity: '',
        state: '',
    })

    const [displaySignatureError, setDisplaySignatureError] = useState(false);

    useEffect(() => {
        checkSignatureEqualsName();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reviewDetails])

    function checkValidation() {
        let validation = true;
        let _emailReg = new RegExp(/^[^@]+@[^@]{2,}\.[^@]{2,}$/)
        if (reviewDetails.name.trim() === '' || reviewDetails.position.trim() === '' || reviewDetails.signature.trim() === '') {
            validation = false;
        }
        if(reviewDetails.address.trim() === '' || reviewDetails.entity.trim() === '' || reviewDetails.state.trim() === ''){
            validation = false;
        }
        if (reviewDetails.email.trim() === '' || !_emailReg.test(reviewDetails.email)) {
            validation = false;
        }
        if (reviewDetails.signature.toLowerCase() !== reviewDetails.name.toLowerCase()) {
            validation = false;
        }
        return validation;
    }

    function checkSignatureEqualsName() {
        setDisplaySignatureError(reviewDetails.signature.toLowerCase() !== reviewDetails.name.toLowerCase());
        return reviewDetails.signature === reviewDetails.name;
    }

    return (
        <Modal
            visible={true}
            bodyStyle={{
                width: 690,
                backgroundColor: "#353738",
                paddingLeft: 60,
                paddingTop: 40,
                paddingRight: 0,
                paddingBottom: 60,
                overflowX: "hidden",
            }}
            closable={false}
            footer={null}
            onOk={() => {

            }}
        >
            <div style={{ position: 'relative' }}>
                <Typography.Title style={{
                    display: "inline-block",
                    fontFamily: "Montserrat",
                    color: "#fff",
                    fontWeight: 700,
                    fontSize: 34,
                    paddingBottom: 20,
                    paddingTop: 8,
                }}>Please review and sign</Typography.Title>
                <img
                    alt='cross'
                    onClick={onCancel}
                    src={require("../assets/modal-close.png")}
                    className="delete-x-button-review"
                />
            </div>
            <div style={{ marginTop: 32 }}>
                <TitleText
                    value={reviewDetails.name}
                    placeholder={' '}
                    marginLeft={127}
                    label={'Name'}
                    onChange={({ target: { value } }) => {
                        setDisplaySignatureError(false);
                        setReviewDetails({
                            ...reviewDetails, name: value
                        })
                    }}
                />
                <div style={{ marginTop: 24 }} />
                <TitleText
                    value={reviewDetails.address}
                    placeholder={' '}
                    marginLeft={30}
                    label={'Full Legal Address'}
                    onChange={({ target: { value } }) => setReviewDetails({
                        ...reviewDetails, address: value
                    })}
                />
                <div style={{ marginTop: 24 }} />
                <TitleText
                    value={reviewDetails.entity}
                    placeholder={'i.e. C Corp, S Corp, LLC, etc.'}
                    label={'Entity Type'}
                    marginLeft = {85}
                    onChange={({ target: { value } }) => setReviewDetails({
                        ...reviewDetails, entity: value
                    })}
                />
                <div style = {{marginTop: 24}}/>
                <TitleText
                    value={reviewDetails.state}
                    placeholder={'i.e. Delaware, Nevada, New York, California, etc.'}
                    label={'Formation State'}
                    marginLeft = {45}
                    onChange={({ target: { value } }) => setReviewDetails({
                        ...reviewDetails, state: value
                    })}
                />
                <div style={{ marginTop: 24 }} />
                <TitleText
                    value={reviewDetails.position}
                    placeholder={' '}
                    marginLeft={111}
                    label={'Position'}
                    onChange={({ target: { value } }) => setReviewDetails({
                        ...reviewDetails, position: value
                    })}
                />
                <div style={{ marginTop: 24 }} />
                <TitleText
                    value={reviewDetails.email}
                    placeholder={' '}
                    marginLeft={131}
                    label={'Email'}
                    onChange={({ target: { value } }) => setReviewDetails({
                        ...reviewDetails, email: value
                    })}
                />
            </div>
            <div style={{ marginTop: 48, width: '544px', background: 'black', position: 'relative', height: '120px' }}>
                <label className='review-sign-title'>Type your name to sign</label>
                <input
                    className='review-sign-input'
                    onChange={({ target: { value } }) => {
                        setReviewDetails({
                            ...reviewDetails, signature: value
                        });
                    }}
                />
                {displaySignatureError && <label style={{ lineHeight: '8px' }} className='errorText'>{'Please verify that your full name and signature match. If not, change them as needed.'}</label>}
            </div>
            <Row style={{ marginTop: 65 }}>
                <Button
                    type={'primary'}
                    disabled={!checkValidation()}
                    style={{ width: 200 }}
                    loading={false}
                    onClick={() => {
                        if (reviewDetails.name.toLowerCase() !== reviewDetails.signature.toLowerCase()) {
                            return;
                        }
                        onSave(reviewDetails);
                    }}
                    className={'sign-in'}
                >{"Agree & Sign"}</Button>
            </Row>
        </Modal>
    )
}