import { Col, message, Progress, Row, Spin, Typography } from 'antd'
import React, { useContext, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useQuery } from '../../Components/customHook/useQuery'
import FloatLabel from '../../Components/FloatLabel/FloatLabel'
import RegCommonButton from '../../Components/RegCommonButton'
import RegCommonInput from '../../Components/RegCommonInput'
import RegCommonTitle from '../../Components/RegCommonTitle'
import { userStore } from '../../Store/UserStore'
import { changePasswordForUser, resetUserPassword } from '../../Utils/ApiActions'
import { getDefaultPageByRole, USER_ROLE_ENUM } from '../../Utils/Helper'

const ResetPassword = (props) => {
    const [displayLoading, setDisplayLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [cPassowrd, setCPassword] = useState('')
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState('')
    const query = useQuery()
    const history = useHistory()
    const userContext = useContext(userStore);

    function focusOut() {
        let elements = document.getElementsByTagName("input");
        if (elements.length === 0) {
            return;
        }
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('lockr-form-control-focus');
        }
    }
    function getFocus() {
        let elements = document.getElementsByTagName("input");
        if (elements.length === 0) {
            return;
        }
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add('lockr-form-control-focus');
        }
    }

    function checkValidation() {
        // let _passwordRegx = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/)
        let _passwordRegx = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/)
        let _validate = true

        if (password.trim() === '' || !_passwordRegx.test(password) || password !== cPassowrd) {
            setError(true)
            setErrorText('Please set valid Password')
            _validate = false
        }
        return _validate
    }


    const handleSubmit = () => {
        if (checkValidation()) {
            if (query.get('userMode') === 'user') {
                //This is for the child user.
                const passwordToken = query.get('passwordToken') ?? '';
                setDisplayLoading(true);
                const _apiData = {
                    passwordToken, password
                }
                changePasswordForUser(_apiData).then(res => {
                    if(res.success){
                        setDisplayLoading(false);
                        let _authTokens = res?.data;
                        localStorage.setItem("authToken", _authTokens.access_token);
                        localStorage.setItem("refreshToken", _authTokens.refresh_token);
                        localStorage.setItem("publisherName", _authTokens?.publisherDetails?.businessName || "Publisher");
                        userContext.actions.setUserRole(_authTokens.isChild ? _authTokens.role : USER_ROLE_ENUM.SUPER_ADMIN);
                        props.history.replace(getDefaultPageByRole(_authTokens.role ?? USER_ROLE_ENUM.SUPER_ADMIN) ?? "/console/dashboard")
                    }else{
                        setDisplayLoading(false);
                        setError(true)
                        setErrorText(res?.error?.message || "Something went wrong")
                    }
                }).catch(error => {
                    setError(true)
                    setErrorText("Unable to set password due to: " + error.message);
                })
            } else {
                setDisplayLoading(true)
                let _apiData = {
                    id: query.get('id'),
                    password: password
                }
                // console.log(_apiData);
                resetUserPassword(_apiData).then(res => {
                    if (res.success) {
                        setDisplayLoading(false)
                        // message.success('Password Set Successfully')
                        localStorage.setItem("authToken", res?.data?.access_token);
                        localStorage.setItem("refreshToken", res?.data?.refresh_token);
                        userContext.actions.setUserRole(USER_ROLE_ENUM.SUPER_ADMIN);
                        history.push('/console/dashboard')
                    } else {
                        setDisplayLoading(false)
                        setError(true)
                        setErrorText(res?.error?.message || "Something went wrong")
                    }
                }).catch(err => {
                    setError(true)
                    setErrorText("Unable to set password due to: " + err.message);
                })
            }
        }
    }

    document.body.classList.add('lockr-bg-img');
    return (
        <Spin spinning={displayLoading}>
            <Progress className='reg-steps' percent={0} showInfo={false} />
            <div>
                <Row justify='start' className='reg-container'>
                    <Row style={{ width: '100%' }}>
                        <Col>
                            <img className="lockr-logo" src={require('../../assets/onboard_lock.42d74f50.png')} alt='lock-img' />
                        </Col>
                    </Row>
                    <RegCommonTitle title={query.get('name') ? 'Reset your password' + query.get('name') + '!' : 'Reset your password'} />
                    <Row style={{ width: '100%' }}>
                        <div style={{ marginTop: 16 }}></div>
                        {/* <Col sm={16} xs={24}>
                            <Typography.Text style={{ fontFamily: 'Montserrat, sans-serif', fontSize: 14 }}>Your email has been verified! Let's set up your password:</Typography.Text>
                        </Col> */}
                    </Row>
                    <Row className="lockr-join2" style={{ width: '100%', marginTop: 24 }}>
                        <Col sm={14} xs={24}>
                            <FloatLabel value={password} label={'New Password'}>
                                <RegCommonInput className={error ? 'errorInputText' : null} type='password' value={password}
                                    onChange={(event) => {
                                        setPassword(event.target.value)
                                        setError(false)
                                    }} onFocus={(event) => {
                                        getFocus();
                                    }} onBlur={(event) => {
                                        focusOut();
                                    }}
                                />
                            </FloatLabel>
                        </Col>
                        <Col sm={14} xs={24}>
                            <FloatLabel value={cPassowrd} label={'Confirm New Password'}>
                                <RegCommonInput className={error ? 'errorInputText' : null} type='password' value={cPassowrd}
                                    onChange={(event) => {
                                        setCPassword(event.target.value)
                                        setError(false)
                                    }} onFocus={(event) => {
                                        getFocus();
                                    }} onBlur={(event) => {
                                        focusOut();
                                    }}
                                />
                            </FloatLabel>
                        </Col>
                        {error && <Col sm={14} xs={24}>
                            <label className='errorText'>{errorText}</label>
                        </Col>}
                    </Row>
                    <Row className='password-info' style={{ width: '100%', marginTop: 10 }}>
                        <Col sm={24} xs={22}>
                            <Typography.Text style={{ fontFamily: 'Montserrat, sans-serif', fontSize: 12 }}>Passwords must have 6 characters, 1 uppercase character, 1 lowercase character, 1 number, 1 non-alphanumeric character</Typography.Text>
                        </Col>
                    </Row>
                    <RegCommonButton btntext={'Change my password'} onClick={handleSubmit} />
                </Row>
            </div>
        </Spin>
    )
}

export default withRouter(ResetPassword)