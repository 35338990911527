import { Button, Col, Row, Table, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CreateUserModal } from '../../Components/CreateUserModal';
import DeleteTypeModal from '../../Components/LockrDeleteModal';
import SideDrawer from '../../Components/SideDrawer';
import { fetchUserList as fetchUserListService, newUser, editUser as editUserAction, deleteUser, resendUserVerificationLink } from '../../Utils/ApiActions';
import { mapUserRoleToString, USER_ROLE_ENUM } from '../../Utils/Helper';

function InvitationStatus({ value, record, onResendInvite }) {
    const [invitationResent, setInvitationResent] = useState(false);

    function checkLinkExpired() {
        const currentMoment = moment().valueOf();
        return currentMoment > parseFloat(record.invitationEmailExpiry)
    }

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography.Text>{value ? 'Yes' : 'No'}</Typography.Text>
                <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 16 }}>
                    {checkLinkExpired() && !record.isVerified && <span className='invitation-expired-text'>Invitation Expired</span>}
                    {!value && !invitationResent &&
                        <Typography.Link onClick={() => {
                            onResendInvite(record.id);
                            setInvitationResent(true);
                        }}>Resend Invite</Typography.Link>
                    }
                    {!value && invitationResent && <Typography.Link onClick={() => {
                        setInvitationResent(false);
                    }}>Invitation sent</Typography.Link>}
                </div>

            </div>
        </>
    )
}

function Users(props) {

    const [userList, setUserList] = useState([]);
    const [showNewUserModal, setShowNewUserModal] = useState(false);
    const [displayLoading, setDisplayLoading] = useState(false);
    const [selectedIdToDelete, setSelectedIdToDelete] = useState(undefined);
    const [selectedUserToEdit, setSelectedUserToEdit] = useState(undefined);
    const [newUserError, setNewUserError] = useState(undefined);

    useEffect(() => {
        fetchUserList();
    }, [])

    async function removeUser(id) {
        setDisplayLoading(true);
        try {
            await deleteUser(id);
            setSelectedIdToDelete(undefined);
            fetchUserList();
        } catch (error) {
            console.log(`Unable to delete the user at the moment: ${error.message}`);
        }
        setDisplayLoading(false);
    }

    async function editUser(userDetails, id) {
        setDisplayLoading(true);
        try {
            await editUserAction(userDetails, id);
            setShowNewUserModal(false);
            setNewUserError(undefined);
            setSelectedUserToEdit(undefined);
            fetchUserList();
        } catch (error) {
            console.log(`Unable to edit the user at the moment: ${error.message}`)
        }
        setDisplayLoading(false);
    }

    async function createNewUser(userDetails) {
        setDisplayLoading(true);
        try {
            const { success, data, error } = await newUser(userDetails);
            if (success) {
                setNewUserError(undefined);
                setShowNewUserModal(false);
                fetchUserList();
            } else {
                setNewUserError(error?.message ?? 'Unable to create the user at the moment');
            }
        } catch (error) {
            console.log(`Unable to create new user at the moment: ${error.message}`);
        }
        setDisplayLoading(false);
    }

    async function fetchUserList() {
        setDisplayLoading(true);
        try {
            const userListResponse = await fetchUserListService();
            setUserList(userListResponse.data);
        } catch (error) {
            console.log(`Unable to add get the user list at the moment`);
            console.log(error);
        }
        setDisplayLoading(false);
    }

    function renderActionButtons(value, record) {
        if (record.role !== USER_ROLE_ENUM.SUPER_ADMIN) {
            return (
                <div>
                    <Button
                        className='connection-tile-font'
                        onClick={() => {
                            setShowNewUserModal(true);
                            setSelectedUserToEdit(record);
                        }}
                        style={{ height: 35, marginLeft: 8, color: '#8B8B8B', background: 'transparent', border: '1px solid #8B8B8B' }} type='default'>
                        <img
                            alt="duplicate"
                            src={require('../../assets/edit_icon.png')}
                            style={{ marginRight: 4, cursor: 'pointer' }}
                        />
                        <label
                            className='connection-tile-font'
                            style={{
                                fontFamily: 'Montserrat',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                cursor: 'pointer',
                            }}
                        >Edit</label>
                    </Button>
                    <img
                        src={require('../../assets/remove_icon.png')}
                        alt='delete'
                        onClick={() => {
                            setSelectedIdToDelete(record.id)
                        }}
                        style={{
                            marginLeft: 16,
                            height: 16,
                            cursor: 'pointer',
                            width: 16,
                        }} />
                </div>
            )
        }
        return <></>
    }

    async function resendUserInvite(userId) {
        try {
            await resendUserVerificationLink(userId);
            fetchUserList();
        } catch (error) {
            console.log(`Unable to send the user invite`);
            console.log(error);
        }
    }

    function renderUserList() {
        if (userList?.length > 0) {
            return (
                <div style={{ padding: '0px 90px' }}>
                    <Table
                        rowKey={'id'}
                        loading={displayLoading}
                        pagination={false}
                        dataSource={userList}
                        columns={[{
                            title: <span className='app-table-header'>First Name</span>,
                            key: "firstName",
                            dataIndex: "firstName"
                        }, {
                            title: <span className='app-table-header'>Last Name</span>,
                            key: "lastName",
                            dataIndex: "lastName"
                        }, {
                            title: <span className='app-table-header'>Email</span>,
                            key: "email",
                            dataIndex: "email"
                        }, {
                            title: <span className='app-table-header'>Role</span>,
                            key: "role",
                            dataIndex: "role",
                            render: value => <Typography.Text>{mapUserRoleToString(value)}</Typography.Text>
                        }, {
                            title: <span>Invitation Accepted?</span>,
                            key: 'isVerified',
                            dataIndex: 'isVerified',
                            render: (value, record) => <InvitationStatus onResendInvite={resendUserInvite} value={value} record={record} />
                        }, {
                            title: <span className='app-table-header'>Actions</span>,
                            render: renderActionButtons,
                            align: 'right'
                        }]}
                    />
                </div>
            )
        }
    }

    return (
        <SideDrawer>
            <Row
                justify='space-between'
                align='middle'
                className='apiandservices-newapp-btn'
            >
                <Col sm={18} xs={24}>
                    <Typography.Title className='apiandservices-heading'>
                        Users
                    </Typography.Title>
                    <div style={{ marginTop: 16, display: 'flex', flexDirection: 'column' }}>
                        <label className='dashboard-as-of-today' style={{ fontSize: 14, fontFamily: 'Montserrat', color: '#8B8B8B' }}>
                            Add users for to your account to manage the work.
                        </label>
                    </div>
                </Col>
                <Col sm={6} xs={24} className="createAppButton">
                    <Button
                        type='primary'
                        className='new-app-modal-btn'
                        onClick={() => {
                            setShowNewUserModal(true)
                        }}>Invite Users</Button>
                </Col>
            </Row>
            {renderUserList()}
            {showNewUserModal && <CreateUserModal
                display={showNewUserModal}
                defaultUserDetails={selectedUserToEdit}
                errorText={newUserError}
                onCancel={() => {
                    setShowNewUserModal(false);
                    setNewUserError(undefined);
                    setSelectedUserToEdit(undefined);
                }}
                removeError={() => {
                    setNewUserError(undefined);
                }}
                onSave={(userDetails) => {
                    if (selectedUserToEdit !== undefined) {
                        //this is edit request
                        editUser(userDetails, selectedUserToEdit.id);
                    } else {
                        //this is new user request
                        createNewUser(userDetails);
                    }
                }}
            />}
            {selectedIdToDelete !== undefined && <DeleteTypeModal
                visible={selectedIdToDelete !== undefined}
                handleOk={() => {
                    //ToDO: delete the user here.
                    removeUser(selectedIdToDelete);
                }}
                handleCancel={() => {
                    setSelectedIdToDelete(undefined);
                }}
                title={'Delete?'}
            />}
        </SideDrawer>
    )
}

export default Users;