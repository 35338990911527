/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from "react";
import SideDrawer from "../../../Components/SideDrawer";
import {
    Button,
    Checkbox,
    Col,
    Dropdown,
    Input,
    Modal,
    Row,
    Select,
    Table,
    Tooltip,
    Typography,
} from "antd";

import { deleteAWSEndpoint, downloadCSVEndpointData, getCSVEndpointTableData, getCVSDataEndpointAttributes, getS3EndPoints, newS3DataEndPoint, updateS3DataEndPoint } from "../../../Utils/ApiActions";

import deleteIcon from '../../../assets/remove_icon.png'

import { AiOutlineCheck, AiOutlineDelete, AiOutlineDown, AiOutlinePlus } from "react-icons/ai";

import { LockrInfoIcon } from "../../../Components/UIDCredentialsModal";
import { isUUID } from "../../../Utils/uuid.helper";
import { DATA_ENDPOINTS } from "../../../Utils/Helper";
import DeleteTypeModal from "../../../Components/LockrDeleteModal";

export const conditionDropdown = [{
    label: "Is",
    key: 'is'
}, {
    label: "Contains",
    key: 'contains'
},
{
    label: "Starts With",
    key: 'startsWith'
},
{
    label: "Ends With",
    key: 'endsWith'
}, {
    label: "Does not contain",
    key: "doesNotContain",
}, {
    label: "Is Null",
    key: 'isNull'
}]

export const uuidConditionDropdown = [{
    label: "Is",
    key: 'is'
}, {
    label: "Is Null",
    key: 'isNull'
}]

export const attributesNotToIncudeInDropdown = ["integrationTypeUID", "integrationTypeID5", "integrationTypeRAMPID", "integrationTypeEUID"]


export function DataEndpointTitleValue({
    title,
    value,
    showDropdown = false,
    isEdit = false,
    infoText,
}) {
    return (
        <div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 8,
                    alignItems: "center",
                    marginBottom: 4,
                }}
            >
                <span
                    style={{
                        fontWeight: "500",
                        fontSize: 14,
                        fontFamily: "Montserrat",
                        color: "white",
                    }}
                >
                    {title}
                </span>
                {infoText && (
                    <Tooltip
                        style={{
                            cursor: "pointer",
                        }}
                        title={infoText}
                    >
                        <div>
                            <LockrInfoIcon />
                        </div>
                    </Tooltip>
                )}
            </div>
            <div
                style={{
                    background: "#26282A",
                    minHeight: 50,
                    minWidth: 600,
                    maxWidth: 600,
                    display: "flex",
                    alignItems: "center",
                    marginTop: 4,
                    paddingLeft: 8,
                    position: "relative",
                }}
            >
                <span
                    style={{
                        fontWeight: "500",
                        minWidth: 550,
                        maxWidth: 550,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontSize: 14,
                        fontFamily: "Montserrat",
                        color: isEdit ? "white" : "#8B8B8B",
                    }}
                >
                    {value}
                </span>
                {showDropdown && (
                    <AiOutlineDown
                        style={{ color: isEdit ? "white" : "#8B8B8B", position: "absolute", right: 16 }}
                    />
                )}
            </div>
        </div>
    );
}






export function CSVDataEndPoint() {



    const [cohorts, setCohorts] = useState([]);
    const [previousCohort, setPreviousCohort] = useState({ configuration: { appliedFilters: { allCondition: 'AND', filters: [] }, selectedAttributes: [], name: "", } })
    const [selectedCohort, setSelectedCohort] = useState({ configuration: { appliedFilters: { allCondition: 'AND', filters: [] }, selectedAttributes: [], name: "", } });

    const [deleteModal, setDeleteModal] = useState(false)


    const [addNewCohort, setAddNewCohort] = useState(false)
    const [editCohort, setEditCohort] = useState(false)

    const [attributes, setAttributes] = useState([])
    const [tableData, setTableData] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const [pageSize, setPageSize] = useState(25)

    const getAttributes = async () => {
        const attrs = await getCVSDataEndpointAttributes()
        setAttributes(attrs.data)
    }
    const getCohortList = async () => {
        const cohortsData = await getS3EndPoints('CSV')
        setSelectedCohort({ ...cohortsData.data[0] })
        setPreviousCohort({ ...cohortsData.data[0] })
        setCohorts([...cohortsData.data])



        // setConditions(cohortsData.data[0]?.configuration?.appliedFilters ?? { allCondition: 'AND', filters: [] })
    }

    const updateSelectedCohortAttributes = (config) => {
        setSelectedCohort({ ...selectedCohort, configuration: { ...selectedCohort.configuration, selectedAttributes: config } })
    }

    const updateSelectedCohortConditions = (config) => {
        setSelectedCohort({ ...selectedCohort, configuration: { ...selectedCohort.configuration, appliedFilters: config } })
    }
    const updateSelectedCohortFilters = (config) => {
        setSelectedCohort({ ...selectedCohort, configuration: { ...selectedCohort.configuration, appliedFilters: config } })
    }




    useEffect(() => {
        getAttributes()
        getCohortList()
    }, [])

    useEffect(() => {
        if (!(editCohort || addNewCohort)) {
            getCSVTableData()
        }
    }, [pageNum, pageSize])
    useEffect(() => {
        if (pageNum === 1) {
            getCSVTableData()
        }
        else {
            setPageNum(1)
        }
    }, [selectedCohort])


    const getCSVTableData = async (force = false) => {
        const filterConditons = []
        selectedCohort?.configuration?.appliedFilters?.filters?.map((c) => {
            if (c.attr && c.conditionKey) {
                if (c.value === false) {
                    filterConditons.push(c)
                }
                else if (c.type === "uuid" && isUUID(c.value)) {
                    filterConditons.push(c)
                }
                else if (c.type !== "uuid" && c.value) {
                    filterConditons.push(c)
                }

            }
        })
        if (((addNewCohort || editCohort) || selectedCohort?.configuration?.selectedAttributes.length === 0) && !force) { return [] }
        const data = await getCSVEndpointTableData({ name: "test", appliedFilters: { allCondition: selectedCohort?.configuration?.appliedFilters.allCondition, filters: selectedCohort?.configuration?.appliedFilters?.filters }, selectedAttributes: selectedCohort?.configuration?.selectedAttributes }, pageNum, pageSize)
        if (data?.success === false) {
            return
        }
        setTableData(data.data)
        setTotalCount(data.count)

    }




    const onAddNewCohort = () => {
        setAddNewCohort(true)
        setSelectedCohort({ configuration: { appliedFilters: { allCondition: 'AND', filters: [] }, selectedAttributes: [], name: "", } })
        setPreviousCohort({ configuration: { appliedFilters: { allCondition: 'AND', filters: [] }, selectedAttributes: [], name: "", } })
    }

    const onCohortDelete = async () => {
        await deleteAWSEndpoint(selectedCohort?.id);
        getCohortList()
        setDeleteModal(false)
    }

    function renderEmptySection() {
        return (
            <div
                style={{
                    marginLeft: 90,
                    marginRight: 90,
                    minHeight: "50vh",
                    background: "black",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <img alt="logo_grey" src={require("../../../assets/logo_grey.png")} />
                <div
                    style={{
                        marginTop: 42,
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography.Text
                        style={{
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: "700",
                            fontSize: "24px",
                            color: "#fff",
                        }}
                    >
                        Add a new Cohort for CSV Export
                    </Typography.Text>
                    <Button
                        style={{ marginTop: 24, width: "217px" }}
                        type="primary"
                        className="btn-first-connection"
                        onClick={() => {
                            onAddNewCohort()
                        }}
                    >
                        Add New Cohort
                    </Button>
                </div>
            </div>
        );
    }

    const downloadData = async () => {
        const response = await downloadCSVEndpointData(selectedCohort.configuration)
        const blob = new Blob([response.data], { type: 'text/csv' });

        // Create a temporary link element to trigger the download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'data.csv';
        link.click();
    }

    const updateCohort = async () => {
        const updateCohortData = {
            name: selectedCohort?.configuration?.name,
            appliedFilters: selectedCohort?.configuration?.appliedFilters,
            selectedAttributes: selectedCohort?.configuration?.selectedAttributes
        }
        const updatedData = await updateS3DataEndPoint(updateCohortData, selectedCohort?.id, DATA_ENDPOINTS.CSV)
        if (updatedData?.success) {
            setEditCohort(false);
            setPreviousCohort(selectedCohort)
            getCSVTableData(true);
            return
        }
    }

    const createCohort = async () => {
        const updateCohortData = {
            name: selectedCohort?.configuration?.name,
            appliedFilters: selectedCohort?.configuration?.appliedFilters,
            selectedAttributes: selectedCohort?.configuration?.selectedAttributes
        }
        const updatedData = await newS3DataEndPoint(updateCohortData, DATA_ENDPOINTS.CSV)
        if (updatedData?.success) {
            setAddNewCohort(false)
            getCohortList()
            return
        }
    }

    function disableSaveButton() {
        if (JSON.stringify({ ...previousCohort }) === JSON.stringify({ ...selectedCohort })) {

            return true;
        }
        else if (!(selectedCohort.configuration.name || selectedCohort.configuration.name.trim(" "))) {

            return true
        }
        if (selectedCohort.configuration.selectedAttributes.length <= 0) {
            return true
        }
        return false
    }

    const renderAttributeList = () => {
        return <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
            {attributes?.map((a) => <div
                style={{
                    marginTop: 32,
                    paddingLeft: 32,
                }}
            >
                <Checkbox
                    checked={selectedCohort?.configuration?.selectedAttributes.includes(a.key)}
                    disabled={!(editCohort || addNewCohort)}
                    onChange={(e) => {
                        if (selectedCohort?.configuration?.selectedAttributes?.includes(a.key)) {
                            updateSelectedCohortAttributes(selectedCohort?.configuration?.selectedAttributes?.filter(sa => sa !== a.key))
                        }
                        else {
                            updateSelectedCohortAttributes([...selectedCohort?.configuration?.selectedAttributes, a.key])
                        }
                    }}
                />
                <span
                    style={{
                        color: "white",
                        fontFamily: "Montserrat",
                        marginLeft: 12,
                        fontSize: 16,
                        fontWeight: "500",
                    }}
                >
                    {a?.label ?? ''}
                </span>
            </div>)}

        </div>
    }



    function renderMainSections() {
        return <div
            style={{
                display: "flex",
                flexDirection: "row",
                paddingLeft: 90,
                paddingRight: 90,
                position: "relative",
            }}
        >
            <div
                style={{
                    maxWidth: 294,
                    display: "flex",
                    flex: 1,
                    background: "#353738",
                    flexDirection: "column",
                    position: "relative",
                    minWidth: 294,
                    justifyContent: "space-between"
                }}
            >
                <div
                    style={{
                        paddingBottom: 60,
                    }}
                >
                    {cohorts.map((c) => (<div
                        onClick={() => {
                            setSelectedCohort({ ...c })
                            setPreviousCohort({ ...c })
                        }}
                        style={{
                            paddingLeft: 20,
                            paddingRight: 20,
                            minHeight: 60,
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            position: "relative",
                            background:
                                selectedCohort?.id === c.id ?
                                    "black" : ""
                            ,
                            width: "100%",
                        }}
                    >

                        <div
                            style={{
                                position: "absolute",
                                left: 0,
                                height: "100%",
                                width: 4,
                                background: selectedCohort?.id === c.id ? "#61CEF7" : "",
                            }}
                        />

                        <span
                            style={{
                                fontFamily: "Montserrat",
                                fontSize: 18,
                                fontWeight: "500",
                                color:

                                    "white",
                            }}
                        >

                            {c?.configuration?.name}
                        </span>
                    </div>))}
                </div>
                <div
                    style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        paddingBottom: 20,
                    }}
                >
                    <Button
                        style={{ width: "248px" }}
                        type="primary"
                        className="sign-in"
                        onClick={() => {
                            onAddNewCohort()
                        }}
                    >
                        Add New Cohort
                    </Button>
                </div>
            </div>

            <div
                style={{
                    background: "black",
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: 20,
                    paddingTop: 24,
                    paddingBottom: 24,
                    paddingRight: 20,
                    maxWidth: "calc(100vw - 180px - 294px)"
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Row
                        xs={24}
                        style={{
                            width: "365px",
                            height: "40px",

                            position: "relative",
                        }}
                    >
                        {(editCohort || addNewCohort) ? <Input value={selectedCohort?.configuration?.name} onChange={(e) => setSelectedCohort({ ...selectedCohort, configuration: { ...selectedCohort?.configuration, name: e.target.value } })} className="form-input new-app-modal-input ant-input-40 connection-screen-input" placeholder="Add Cohort Name" style={{ color: 'white' }} /> : <div style={{ color: 'white', fontSize: 18, fontWeight: 'bold' }}>{selectedCohort?.configuration?.name}</div>}
                    </Row>
                    <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                        {(editCohort || addNewCohort) && <Button
                            style={{ width: "248px" }}
                            type="primary"
                            className="sign-in"
                            onClick={() => {
                                editCohort && setEditCohort(false)
                                addNewCohort && setAddNewCohort(false)
                                if (addNewCohort) { setSelectedCohort(Object.create(cohorts[0])); setAddNewCohort(false); setPreviousCohort({ ...cohorts[0] }) }
                                if (editCohort) { setSelectedCohort(previousCohort) }
                            }}
                        >
                            Cancel
                        </Button>}

                        {(editCohort || addNewCohort) && <Button
                            disabled={disableSaveButton()}
                            style={{ width: "248px" }}
                            type="primary"
                            className="sign-in"
                            onClick={() => {
                                addNewCohort ? createCohort() : updateCohort();
                            }}
                        >
                            Save
                        </Button>}
                        {!(editCohort || addNewCohort) && <><Button
                            style={{ width: "248px" }}
                            type="primary"
                            className="sign-in"
                            onClick={() => {
                                setEditCohort(true)
                            }}
                        >
                            Edit
                        </Button>

                            <img alt="delete" onClick={() => setDeleteModal(true)} src={deleteIcon} style={{ height: 32, width: 28, cursor: 'pointer' }}></img>
                        </>}
                    </div>
                </div>




                <div
                    style={{
                        paddingTop: 24,
                        paddingLeft: 16,
                        paddingRight: 16,
                        paddingBottom: 24,
                        border: "1px solid #353738",
                        width: "100%",
                        marginTop: 20,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <span
                            style={{
                                color: "white",
                                fontSize: 14,
                                fontWeight: "500",
                                fontFamily: "Montserrat",
                            }}
                        >
                            Attributes
                        </span>
                        <span
                            style={{
                                marginTop: 16,
                                fontFamily: "Montserrat",
                                fontWeight: "500",
                                fontSize: 12,
                                color: "#8B8B8B",
                            }}
                        >
                            Select the attributes to include in the generated CSV.
                        </span>
                        {renderAttributeList()}
                    </div>
                </div>

                <div className="csv-downloader-endpoint" style={{ color: 'white', marginTop: 20, }}>
                    {selectedCohort?.configuration?.appliedFilters?.filters.length > 0 && <Dropdown disabled={!(editCohort || addNewCohort)} trigger={["click"]} overlay={
                        <div style={{ background: 'black', color: 'white', width: 200, cursor: 'pointer' }}>
                            <div onClick={() => { updateSelectedCohortConditions({ ...selectedCohort?.configuration?.appliedFilters, allCondition: "AND" }) }} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: 8, padding: 8 }}><AiOutlineCheck color={selectedCohort?.configuration?.appliedFilters?.allCondition === 'AND' ? '#61CEF7' : 'transparent'} />All are true</div>
                            <div onClick={() => { updateSelectedCohortConditions({ ...selectedCohort?.configuration?.appliedFilters, allCondition: "OR" }) }} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: 8, padding: 8 }}><AiOutlineCheck color={selectedCohort?.configuration?.appliedFilters?.allCondition === 'OR' ? '#61CEF7' : 'transparent'} />Any one is true</div>
                        </div>}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 8, cursor: 'pointer' }}>{selectedCohort?.configuration?.appliedFilters?.allCondition === 'AND' ? "All are true" : "Any one is true"}
                            <AiOutlineDown
                                style={{
                                    cursor: "pointer",
                                    color: "white",

                                    right: -220,
                                    top: 18,
                                }}
                            />
                        </div>

                    </Dropdown>}

                    <div style={{ marginTop: 20 }} >

                        {selectedCohort?.configuration?.appliedFilters?.filters.map((filter, index) => (
                            <div style={{ display: 'flex', padding: 8, gap: 8, alignItems: 'center' }}>
                                {/* SELECT FOR ATTRIBUTE SELECT */}
                                <Dropdown disabled={!(editCohort || addNewCohort)} trigger={["click"]} overlay={<div style={{ background: 'black', padding: 8, width: 220 }}>
                                    {attributes.filter(a => !attributesNotToIncudeInDropdown.includes(a.key))?.map(cd => (
                                        <div onClick={() => {
                                            let tempFilters = []
                                            let tempCohorts = JSON.parse(JSON.stringify(selectedCohort))
                                            tempFilters = tempCohorts?.configuration?.appliedFilters?.filters
                                            tempFilters[index] = { ...tempFilters[index], attr: cd.key, type: cd.type }
                                            updateSelectedCohortFilters({ ...selectedCohort?.configuration?.appliedFilters, filters: tempFilters })
                                        }} style={{ color: 'white', padding: 4 }}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}> <AiOutlineCheck color={cd.key === filter.attr ? "#61CEF7" : "transparent"} />{cd.label}</div>
                                        </div>
                                    ))}
                                </div>}>
                                    <div style={{ position: 'relative' }}>
                                        <input style={{ width: 220, minWidth: 100, cursor: 'pointer' }} placeholder="Select Attribute" readOnly className="lockr-data-endpoint-input" value={attributes?.find(a => a.key === filter.attr)?.label}></input>
                                        <div style={{ position: 'absolute', top: 20, right: 10 }}>
                                            <AiOutlineDown />
                                        </div>
                                    </div>

                                </Dropdown>

                                {/* SELECT FOR CONDITION SELECT */}
                                <Dropdown disabled={!(editCohort || addNewCohort)} trigger={["click"]} overlay={
                                    <div style={{ background: 'black', padding: 8, width: 170 }}>
                                        {attributes.find(a => a.key === filter.attr)?.type === "uuid" ? uuidConditionDropdown.map(cd => (
                                            <div onClick={() => {
                                                let tempFilters = []
                                                let tempCohorts = JSON.parse(JSON.stringify(selectedCohort))
                                                tempFilters = tempCohorts?.configuration?.appliedFilters?.filters
                                                tempFilters[index] = { ...tempFilters[index], conditionKey: cd.key }
                                                updateSelectedCohortFilters({ ...selectedCohort?.configuration?.appliedFilters, filters: tempFilters })
                                            }} style={{ color: 'white', padding: 4 }}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}> <AiOutlineCheck color={cd.key === filter.conditionKey ? "#61CEF7" : "transparent"} />{cd.label}</div>
                                            </div>
                                        )) :
                                            conditionDropdown.map(cd => (
                                                <div onClick={() => {
                                                    let tempFilters = []
                                                    let tempCohorts = JSON.parse(JSON.stringify(selectedCohort))
                                                    tempFilters = tempCohorts?.configuration?.appliedFilters?.filters
                                                    tempFilters[index] = { ...tempFilters[index], conditionKey: cd.key }
                                                    updateSelectedCohortFilters({ ...selectedCohort?.configuration?.appliedFilters, filters: tempFilters })
                                                }} style={{ color: 'white', padding: 4 }}>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}> <AiOutlineCheck color={cd.key === filter.conditionKey ? "#61CEF7" : "transparent"} />{cd.label}</div>
                                                </div>
                                            ))}
                                    </div>
                                }>
                                    <div style={{ position: 'relative' }}>
                                        <input style={{ minWidth: 60, cursor: 'pointer' }} placeholder="Select Condition" readOnly className="lockr-data-endpoint-input" value={conditionDropdown.find(cd => cd.key === filter.conditionKey)?.label}></input>
                                        <div style={{ position: 'absolute', top: 20, right: 10 }}>
                                            <AiOutlineDown />
                                        </div>
                                    </div>

                                </Dropdown>

                                {/* If IS NULL ANOTHER DROPDOWN  ELSE AN INPUT FOR CONDITON */}

                                {filter.conditionKey && (filter.conditionKey === 'isNull' ?
                                    <Dropdown disabled={!(editCohort || addNewCohort)} trigger={["click"]} overlay={<div style={{ background: 'black', padding: 8, width: 170 }}>
                                        <div onClick={() => {
                                            let tempFilters = []
                                            let tempCohorts = JSON.parse(JSON.stringify(selectedCohort))
                                            tempFilters = tempCohorts?.configuration?.appliedFilters?.filters
                                            tempFilters[index].value = true
                                            updateSelectedCohortFilters({ ...selectedCohort?.configuration?.appliedFilters, filters: tempFilters })
                                        }} style={{ color: 'white', padding: 4 }}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}> <AiOutlineCheck color={filter.value ? "#61CEF7" : "transparent"} />{"True"}</div>
                                        </div>
                                        <div onClick={() => {
                                            let tempFilters = []
                                            let tempCohorts = JSON.parse(JSON.stringify(selectedCohort))
                                            tempFilters = tempCohorts?.configuration?.appliedFilters?.filters
                                            tempFilters[index].value = false
                                            updateSelectedCohortFilters({ ...selectedCohort?.configuration?.appliedFilters, filters: tempFilters })
                                        }} style={{ color: 'white', padding: 4 }}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}> <AiOutlineCheck color={(filter.value === false) ? "#61CEF7" : "transparent"} />{"False"}</div>
                                        </div>
                                    </div>}>
                                        <div style={{ position: 'relative' }}>
                                            <input value={filter.value === "" ? "" : ((filter.value === false) ? "False" : "True")} style={{ minWidth: 60, cursor: 'pointer' }} placeholder="Select Value" readOnly className="lockr-data-endpoint-input"></input>
                                            <div style={{ position: 'absolute', top: 20, right: 10 }}>
                                                <AiOutlineDown />
                                            </div>
                                        </div>

                                    </Dropdown> : <input disabled={!(editCohort || addNewCohort)} value={filter.value} onChange={(e) => {
                                        let tempFilters = []
                                        let tempCohorts = JSON.parse(JSON.stringify(selectedCohort))
                                        tempFilters = tempCohorts?.configuration?.appliedFilters?.filters
                                        tempFilters[index].value = e.target.value;
                                        updateSelectedCohortFilters({ ...selectedCohort?.configuration?.appliedFilters, filters: tempFilters })

                                    }} placeholder={conditionDropdown.find(c => c.key === filter.conditionKey)?.label} style={{ background: 'black', border: '1px solid #8B8B8B', marginTop: 4, padding: 8, height: 48 }} />
                                )}

                                {/* Delete Icon */}
                                <div>
                                    {(editCohort || addNewCohort) && < img src={deleteIcon} alt="delete" onClick={() => {
                                        if (editCohort || addNewCohort) {
                                            let tempFilters = []
                                            let tempCohorts = JSON.parse(JSON.stringify(selectedCohort))
                                            tempFilters = tempCohorts?.configuration?.appliedFilters?.filters
                                            tempFilters.splice(index, 1)
                                            updateSelectedCohortFilters({ ...selectedCohort?.configuration?.appliedFilters, filters: tempFilters })
                                        }
                                    }} cursor={'pointer'} style={{ height: 24, width: 21.6 }} />}
                                </div>
                            </div>
                        ))}
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16, marginTop: 16 }}>
                            {(editCohort || addNewCohort) ? <div onClick={() => {
                                if (addNewCohort || editCohort) {
                                    updateSelectedCohortFilters({
                                        ...selectedCohort?.configuration?.appliedFilters, filters: [...selectedCohort?.configuration?.appliedFilters?.filters, {
                                            attr: "",
                                            conditionKey: "",
                                            value: ""
                                        }]
                                    })
                                }
                            }} style={{ display: 'flex', alignItems: 'center', fontSize: 14, fontWeight: 'bold', padding: 4, gap: 8, cursor: 'pointer', width: 'max-content' }}> <AiOutlinePlus /> Add Filter </div> : <>&nbsp;</>}
                            {!(editCohort || addNewCohort) && <Button
                                className="dashboard-download-btn"
                                style={{ marginTop: 'unset' }}
                                onClick={() => {
                                    downloadData()
                                }}
                                type="primary"
                                shape="default"
                            >
                                <img
                                    alt={"download"}
                                    src={require("../../../assets/download-icon.png")}
                                    className="download-icon"
                                ></img>{" "}
                                Download
                            </Button>}
                        </div>
                    </div>
                    {renderCSVEndpointTable()}
                </div>
            </div>
        </div>
        return <></>;
    }



    function renderCSVEndpointTable() {
        return <div>
            <Table
                scroll={{ x: 'max-content' }}
                className="dashboard-tables"
                style={{ overflowWrap: "anywhere" }}
                rowKey={"id"}
                dataSource={(editCohort || addNewCohort) ? [] : tableData}
                columns={
                    [
                        ...selectedCohort?.configuration?.selectedAttributes
                    ]
                        .reduce((acc, s) => {
                            const attribute = attributes?.find(a => a.key === s);

                            // Check for integration type keys, and add the "Integration Type" column only once
                            if (
                                attribute?.key === 'integrationTypeUID' ||
                                attribute?.key === 'integrationTypeID5' ||
                                attribute?.key === 'integrationTypeRAMPID' ||
                                attribute?.key === 'integrationTypeEUID'

                            ) {
                                // Check if "Integration Type" is already added to the columns
                                if (!acc.some(col => col.key === 'integrationType')) {
                                    acc.push({
                                        title: "Integration Type",
                                        key: "integrationType",
                                        dataIndex: "integrationType",
                                        width: "max-content",
                                    });
                                    acc.push({
                                        title: "Token",
                                        key: "token",
                                        dataIndex: "token",
                                        width: "max-content",
                                    });
                                }
                            } else {
                                // Add normal column
                                acc.push({
                                    title: attribute?.label,
                                    key: attribute?.key,
                                    dataIndex: attribute?.key,
                                    width: "max-content",
                                });
                            }
                            return acc;
                        }, []) ?? []
                }
                pagination={(editCohort || addNewCohort) ? {} : {
                    current: pageNum,
                    defaultCurrent: pageNum,
                    defaultPageSize: pageSize,
                    total: totalCount,
                    onChange: (value) => setPageNum(value),
                }}
            >
            </Table>
        </div>
    }

    return (
        <div style={{ fontFamily: 'Montserrat' }}>
            <SideDrawer>
                <Row
                    justify="space-between"
                    align="middle"
                    className="apiandservices-newapp-btn"
                >
                    <Col sm={17} xs={24}>
                        <Typography.Title className="apiandservices-heading">
                            CSV Data Endpoint
                        </Typography.Title>
                        <div style={{ marginTop: 8 }} />
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <label
                                style={{
                                    fontSize: 14,
                                    fontFamily: "Montserrat",
                                    color: "#8B8B8B",
                                }}
                                className="dashboard-as-of-today"
                            >
                                Seamlessly export audience data to CSV for downstream use-cases.
                            </label>
                        </div>
                    </Col>
                    <Col sm={7} xs={24} className="createAppButton">
                        <Button
                            style={{ width: "248px" }}
                            type="primary"
                            className="sign-in"
                            onClick={() => {
                                onAddNewCohort();
                            }}
                        >
                            Add New Cohort
                        </Button>
                    </Col>
                </Row>
                {/* {renderEmptySection()} */}
                {/* {renderTable()} */}
                {(cohorts.length > 0 || addNewCohort) ? renderMainSections() : renderEmptySection()}
                {deleteModal && <DeleteTypeModal visible={deleteModal} handleOk={() => { onCohortDelete() }} handleCancel={() => setDeleteModal(false)} title="Delete Cohort" message={"Are you sure you want to delete this Cohort"} confirmText={"Please confirm by typing DELETE into the field below:"} />}
            </SideDrawer>
        </div>
    );
}
