import { Dropdown } from "antd";
import React from "react";
import { AiOutlineCheck, AiOutlineDown } from "react-icons/ai";

export function CommonDropdown({ disabled, options = [{ value: "", label: "" }], value, onChange, placeholder = "", width }) {

    const dropdownOverlay = () => {
        return <div style={{ background: 'black', color: 'white', width: width ?? 200, cursor: 'pointer', }}>
            {
                options.map((o) => <div onClick={() => { value === o.value ? onChange({}) : onChange(o.value) }} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: 8, padding: 8 }}><AiOutlineCheck color={o.value === value ? '#61CEF7' : 'transparent'} />{o.label}</div>)
            }
        </div>
    }

    return <Dropdown disabled={disabled} trigger={["click"]} overlay={dropdownOverlay()
    }>
        <div style={{ position: 'relative', width: width ?? 200 }}>
            <input style={{ minWidth: 60, cursor: 'pointer', width: width ?? 200 }} placeholder={placeholder} readOnly className="lockr-data-endpoint-input" value={options.find(o => o.value === value)?.label ?? ""}></input>
            <div style={{ position: 'absolute', top: 20, right: 10 }}>
                <AiOutlineDown color="#8B8B8B" />
            </div>
        </div>

    </Dropdown>
}