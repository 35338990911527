import React from 'react';
import PropTypes from 'prop-types';
import iconUnChecked from '../assets/checkbox0.svg';
import iconChecked from '../assets/checkbox1.svg';
import Text from 'antd/lib/typography/Text';

function RegCheckBox(props){
  return (
    <div style = {{display: "flex", justifyContent: "center", alignItems: "center",flexDirection: "row"}} onClick={(event)=>{
      if(props.onChange){
        props.onChange(!props.checked)
      }
    }}>
      <img src = {props.checked ? iconChecked : iconUnChecked}></img>
      <Text style = {{marginLeft: 8,fontFamily: 'Montserrat, sans-serif', fontSize: 16}}>{props.label || ""}</Text>
    </div>
  )
}

RegCheckBox.propTypes = {
  chekced: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
}

export default RegCheckBox;