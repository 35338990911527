/* eslint-disable react-hooks/exhaustive-deps */
import { ConfigProvider, DatePicker, Typography, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import locale from "antd/lib/date-picker/locale/en_US";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { AiOutlineDown } from "react-icons/ai";
import {
  DATE_OPTIONS,
  IdentityProviderNonDatePicker,
  UNIQUE_USER_CACHE_DURATION,
} from "./identityProviderNonDatePicker";
import { formatNumberWithCommas, getGraphColor } from "../../../Utils/Helper";
import { AppSelectionDropdown } from "./AppSelectionDropdown";
import { useDurationForAIMAnalyticsStore } from "../../../Store/durationForAIMAnalyticsStore";

export function LockrFromToDatePicker({
  startDate,
  onChangeStartDate,
  endDate,
  onChangeEndDate,
  addCustomDatePicker,
  storeForAIMAnalyticsDuration = false
}) {
  const dateFormat = "dddd MM/DD";

  const customFormat = (value) => `${value.format(dateFormat)}`;

  const [customPickerDateOption, setCustomPickerDateOption] = useState(DATE_OPTIONS.LAST_7_DAYS);

  const {setDurationForAIMAnalytics} = useDurationForAIMAnalyticsStore();

  const updateDurationForAIMAnalytics = (option) =>{
    if(storeForAIMAnalyticsDuration){
      setDurationForAIMAnalytics(option);
    }
  }

  useEffect(() => {
    if(customPickerDateOption === DATE_OPTIONS.CUSTOM){
      setDurationForAIMAnalytics(undefined);
    }
  }, [customPickerDateOption]);

  useEffect(() => {
    if(storeForAIMAnalyticsDuration){
      setDurationForAIMAnalytics(UNIQUE_USER_CACHE_DURATION.LAST_7_DAYS)
    }
  }, []);

  return (
    <div style={{
    }}>
      <ConfigProvider locale={locale}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}>
          {addCustomDatePicker && <IdentityProviderNonDatePicker
            setCustomPickerDateOption={setCustomPickerDateOption}
            dateOption={customPickerDateOption}
            onOptionSelected={(dateOption) => {
              switch (dateOption) {
                case DATE_OPTIONS.LAST_WEEK:
                  updateDurationForAIMAnalytics(UNIQUE_USER_CACHE_DURATION.LAST_WEEK);
                  onChangeStartDate(
                    moment().subtract(1, "week").startOf("week")
                  );
                  onChangeEndDate(moment().subtract(1, "week").endOf("week"));
                  break;
                case DATE_OPTIONS.LAST_QUARTER:
                  updateDurationForAIMAnalytics(UNIQUE_USER_CACHE_DURATION.LAST_QUARTER);
                  onChangeStartDate(
                    moment().subtract(1, "quarter").startOf("quarter")
                  );
                  onChangeEndDate(
                    moment().subtract(1, "quarter").endOf("quarter")
                  );
                  break;
                case DATE_OPTIONS.LAST_MONTH:
                  updateDurationForAIMAnalytics(UNIQUE_USER_CACHE_DURATION.LAST_MONTH);
                  onChangeStartDate(
                    moment().subtract(1, "month").startOf("month")
                  );
                  onChangeEndDate(
                    moment().subtract(1, "month").endOf("month")
                  );
                  break;
                case DATE_OPTIONS.THIS_MONTH:
                  updateDurationForAIMAnalytics(UNIQUE_USER_CACHE_DURATION.THIS_MONTH);
                  onChangeStartDate(moment().startOf("month"));
                  onChangeEndDate(moment().endOf("month"));
                  break;
                case DATE_OPTIONS.LAST_12_MONTHS:
                  updateDurationForAIMAnalytics(UNIQUE_USER_CACHE_DURATION.THIS_YEAR);
                  onChangeStartDate(moment().startOf("year"));
                  onChangeEndDate(moment());
                  break;
                case DATE_OPTIONS.LAST_24_HOURS:
                  updateDurationForAIMAnalytics(UNIQUE_USER_CACHE_DURATION.LAST_DAY);
                  onChangeStartDate(moment().startOf('day'));
                  onChangeEndDate(moment());
                  break;
                case DATE_OPTIONS.LAST_30_DAYS:
                  updateDurationForAIMAnalytics(UNIQUE_USER_CACHE_DURATION.LAST_30_DAYS);
                  onChangeStartDate(moment().subtract(1, "month"));
                  onChangeEndDate(moment());
                  break;
                case DATE_OPTIONS.LAST_7_DAYS:
                  updateDurationForAIMAnalytics(UNIQUE_USER_CACHE_DURATION.LAST_WEEK);
                  onChangeStartDate(moment().subtract(7, "days"));
                  onChangeEndDate(moment());
                  break;
                case DATE_OPTIONS.LAST_90_DAYS:
                  updateDurationForAIMAnalytics(UNIQUE_USER_CACHE_DURATION.LAST_90_DAYS);
                  onChangeStartDate(moment().subtract(3, "months"));
                  onChangeEndDate(moment());
                  break;
                default:
                  break;
              }
            }}
          />}
          <DatePicker
            disabledDate={(date) => moment(date) > moment()}
            value={moment(startDate)}
            onChange={(e) => {
              onChangeStartDate(moment(e));
              setCustomPickerDateOption?.(DATE_OPTIONS.CUSTOM);
            }}
            format={customFormat}
            className="dashboard-date-picker"
            dropdownClassName="calender-date-picker"
            popupStyle={{ background: "#171717" }}
            suffixIcon={<AiOutlineDown color="#fff"></AiOutlineDown>}
            allowClear={false}
          />
          <Typography.Text className="datepicker-btw-to">to</Typography.Text>
          <DatePicker
            disabledDate={(date) =>
              moment(date) > moment() || moment(startDate) > moment(date)
            }
            value={moment(endDate)}
            onChange={(e) => {
              onChangeEndDate(moment(e));
              setCustomPickerDateOption?.(DATE_OPTIONS.CUSTOM);
            }}
            format={customFormat}
            className="dashboard-date-picker"
            dropdownClassName="calender-date-picker"
            suffixIcon={<AiOutlineDown color="#fff"></AiOutlineDown>}
            allowClear={false}
          />
        </div>
      </ConfigProvider>
    </div>
  );
}

export function IdentityProviderStatsAuthStrip({
  ssoCount,
  utmCount,
  emailCount,
  onChangeStartDate,
  onChangeEndDate,
  startDate,
  endDate,
  allData = [],
  useDatePickers = false,
  supportBothDatePicker = false,
  setAuthDataAppIDs,
  authDataAppIDs
}) {
  const dateFormat = "dddd MM/DD";

  const customFormat = (value) => `${value.format(dateFormat)}`;

  const [displaySources, setDisplaySources] = useState(false);

  //Not using the date filters as updated to the new components
  function renderDateFilters() {
    return (
      <div>
        <LockrFromToDatePicker
            endDate={endDate}
            startDate={startDate}
            onChangeEndDate={onChangeEndDate}
            onChangeStartDate={onChangeStartDate}
            addCustomDatePicker = {supportBothDatePicker}
          />
      </div>
    );
  }

  function renderInformationModal() {
    if (displaySources && allData.length) {
      return (
        <div
          style={{
            position: "absolute",
            minWidth: "200px",
            background: "black",
            top: 115,
            left: "50%",
            right: "50%",
            zIndex: 300,
            padding: 16,
          }}
        >
          <span
            style={{
              fontFamily: "Montserrat",
              fontWeight: 600,
              fontSize: 16,
              color: "white",
            }}
          >
            Sources
          </span>
          <div
            style={{
              marginTop: 16,
            }}
          />
          {!!allData.length &&
            allData.map((keyItem, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 8,
                    gap: 8,
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      height: 12,
                      width: 12,
                      background: getGraphColor(index),
                      borderRadius: 20,
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{
                        color: "white",
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: 16,
                      }}
                    >
                      {keyItem.eventType ?? "Emails"}
                    </span>
                    <span
                      style={{
                        color: "white",
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                        fontSize: 16,
                      }}
                    >
                      {keyItem.count}
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
      );
    } else {
      return <></>;
    }
  }

  const totalCount = useMemo(() => {
    return (ssoCount ?? 0) + (emailCount ?? 0) + (utmCount ?? 0);
  }, [ssoCount, utmCount, emailCount]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              color: "white",
              fontSize: 24,
              fontWeight: "700",
              fontFamily: "Montserrat",
            }}
          >
            User Authentication
          </span>
          <span
            style={{
              color: "#8B8B8B",
              fontSize: 14,
              fontWeight: "400",
              fontFamily: "Montserrat",
            }}
          >
            Last updated {moment().format("hh:mm A")}
          </span>
        </div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <AppSelectionDropdown disabled={false} value={authDataAppIDs} onChange={appIDs => { setAuthDataAppIDs(appIDs) }} />
          {useDatePickers ? (
            renderDateFilters()
          ) : (
            <IdentityProviderNonDatePicker
              onOptionSelected={(dateOption) => {
                switch (dateOption) {
                  case DATE_OPTIONS.LAST_WEEK:                    
                    onChangeStartDate(
                      moment().subtract(1, "week").startOf("week")
                    );
                    onChangeEndDate(moment().subtract(1, "week").endOf("week"));
                    break;
                  case DATE_OPTIONS.LAST_QUARTER:                    
                    onChangeStartDate(
                      moment().subtract(1, "quarter").startOf("quarter")
                    );
                    onChangeEndDate(
                      moment().subtract(1, "quarter").endOf("quarter")
                    );
                    break;
                  case DATE_OPTIONS.LAST_MONTH:                    
                    onChangeStartDate(
                      moment().subtract(1, "month").startOf("month")
                    );
                    onChangeEndDate(
                      moment().subtract(1, "month").endOf("month")
                    );
                    break;
                  case DATE_OPTIONS.THIS_MONTH:                    
                    onChangeStartDate(moment().startOf("month"));
                    onChangeEndDate(moment().endOf("month"));
                    break;
                  case DATE_OPTIONS.LAST_12_MONTHS:                    
                    onChangeStartDate(moment().startOf("year"));
                    onChangeEndDate(moment());
                    break;
                  case DATE_OPTIONS.LAST_24_HOURS:                    
                    onChangeStartDate(moment().startOf('day'));
                    onChangeEndDate(moment());
                    break;
                  case DATE_OPTIONS.LAST_30_DAYS:                    
                    onChangeStartDate(moment().subtract(1, "month"));
                    onChangeEndDate(moment());
                    break;
                  case DATE_OPTIONS.LAST_7_DAYS:                    
                    onChangeStartDate(moment().subtract(7, "days"));
                    onChangeEndDate(moment());
                    break;
                  case DATE_OPTIONS.LAST_90_DAYS:                    
                    onChangeStartDate(moment().subtract(3, "months"));
                    onChangeEndDate(moment());
                    break;
                  default:
                    break;
                }
              }}
            />
          )}
        </div>
      </div>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          position: "relative",
          flexDirection: "row",
          gap: 10,
          paddingLeft: 10,
          paddingRight: 10,
          marginTop: 32,
          width: "100%",
          background: "#1D1D1D",
          height: "150px",
          boxShadow: "8px 8px 0px 0px #000",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            height: 127,
            borderRadius: 2,
            background: "black",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <span
            style={{
              color: "#61CEF7",
              fontSize: 40,
              fontWeight: "400",
              fontFamily: "Montserrat",
            }}
          >
            {formatNumberWithCommas(utmCount ?? 0)}
          </span>
          <span
            style={{
              color: "#8B8B8B",
              fontSize: 14,
              fontWeight: "400",
              marginTop: -4,
              fontFamily: "Montserrat",
            }}
          >
            UTM
          </span>
          <img
            alt="add_logo"
            style={{
              height: 24,
              zIndex: 200,
              width: 24,
              position: "absolute",
              right: -16,
            }}
            src={require("../../../assets/stats_add_logo.png")}
          />
          <Tooltip
            title={"The unique number of alternative IDs generated from UTM parameters."}
            overlayInnerStyle={{
              padding: 16,
            }}
          >
            <InfoCircleOutlined 
              style={{
                position: "absolute",
                top: 15,
                right: 15,
                zIndex: 200,
                color: "grey",
                fontSize: 16,
                fontWeight: "400",
              }}
            />
          </Tooltip>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            height: 127,
            borderRadius: 2,
            position: "relative",
            background: "black",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              color: "#61CEF7",
              fontSize: 40,
              fontWeight: "400",
              fontFamily: "Montserrat",
            }}
          >
            {formatNumberWithCommas((emailCount ?? 0) + (ssoCount ?? 0))}
          </span>
          <span
            style={{
              color: "#8B8B8B",
              fontSize: 14,
              fontWeight: "400",
              marginTop: -4,
              fontFamily: "Montserrat",
            }}
          >
            Emails
          </span>
          <img
            alt="add_logo"
            style={{
              height: 24,
              width: 24,
              zIndex: 200,
              position: "absolute",
              right: -16,
            }}
            src={require("../../../assets/stats_add_logo.png")}
          />
          <Tooltip
            title={"The unique number of alternative IDs generated from email fields, account creation or login events, or other AIM server calls (sans lockrMail registrations and/or UTM parameter detection)."}
            overlayInnerStyle={{
              padding: 16,
            }}
          >
            <InfoCircleOutlined 
              style={{
                position: "absolute",
                top: 15,
                right: 15,
                zIndex: 200,
                color: "grey",
                fontSize: 16,
                fontWeight: "400",
              }}
            />
          </Tooltip>
        </div>
        <div
          onMouseEnter={() => {
            setDisplaySources(true);
          }}
          onMouseLeave={() => {
            setDisplaySources(false);
          }}
          style={{
            display: "flex",
            flex: 1,
            height: 127,
            borderRadius: 2,
            background: "#26282A",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <span
            style={{
              color: "#50E8B1",
              fontSize: 40,
              fontWeight: "700",
              fontFamily: "Montserrat",
            }}
          >
            {formatNumberWithCommas(totalCount)}
          </span>
          <span
            style={{
              color: "#8B8B8B",
              fontSize: 14,
              fontWeight: "400",
              marginTop: -4,
              fontFamily: "Montserrat",
            }}
          >
            Total Authenticated Users
          </span>
          {renderInformationModal()}
        </div>
      </div>
    </>
  );
}
