import React, { useState } from 'react';
import { Button, Input, Modal, Typography } from 'antd';
import PropTypes from 'prop-types'

function DeleteTypeModal({ visible, loading, handleOk, handleCancel, title, message, confirmText }) {

    const [deleteText, setDeleteText] = useState('');

    return (
        <Modal
            width={600}
            bodyStyle={{
                width: 600,
                backgroundColor: "#353738",
                paddingLeft: 60,
                paddingTop: 40,
                paddingRight: 0,
                paddingBottom: 60,
                overflowX: "hidden",
            }}
            closable={false}
            footer={null}
            visible={visible}
            onOk={handleOk}
            onCancel={() => {
                setDeleteText('');
                handleCancel()
            }}
            className="delete-app-modal-forinput"
        >
            <div>
                <Typography.Title
                    style={{
                        display: "inline-block",
                        fontFamily: "Montserrat",
                        color: "#fff",
                        fontWeight: 700,
                        fontSize: 34,
                        paddingBottom: 20,
                        paddingTop: 8,
                    }}
                >
                    {title}
                </Typography.Title>
                <img
                    alt='cross'
                    onClick={handleCancel}
                    src={require("../assets/modal-close.png")}
                    className="delete-x-button-connection"
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {!!message && <Typography.Text className='lockr-delete-confirm-title' style={{ marginBottom: 48, marginRight: 60 }}>{message}</Typography.Text>}
                <Typography.Text className='lockr-delete-message-text
                '>
                    {confirmText ?? 'Please confirm by typing DELETE into the field below:'}
                </Typography.Text>
            </div>
            <Input
                value={deleteText}
                onChange={e => setDeleteText(e.target.value)}
                placeholder='DELETE'
                className="delete-app-modal-input"
            />
            <Button
                loading={loading}
                disabled={deleteText !== 'DELETE'}
                style={{ display: "block", marginTop: 40, width: 180, height: 50 }}
                type="primary"
                className='sign-in'
                onClick={handleOk}
            >Delete</Button>
        </Modal>
    )
};

DeleteTypeModal.propTypes = {
    visible: PropTypes.bool,
    handleOk: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool
}

export default DeleteTypeModal;
